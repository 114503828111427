// Debounce function to limit frequent DOM updates
function debounce(fn, delay = 100) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
}

const appUrl = window.location.origin;
const curUrl = window.location.href;

// Common meta data settings for Open Graph, Twitter, and website image
const commonImages = {
  og: [
    { property: 'og:image', content: "https://api.momitnow.com/images/landing/Website/MOM%20AI.webp" },
    { property: 'og:image:width', content: "1200" },
    { property: 'og:image:height', content: "630" },
  ],
  twitter: [
    { name: 'twitter:image', content: "https://api.momitnow.com/images/landing/Website/MOM%20AI.webp" },
    { name: 'twitter:image:width', content: "1200" },
    { name: 'twitter:image:height', content: "630" },
  ],
  website: [
    { name: 'image', content: "https://api.momitnow.com/images/landing/Website/MOM%20AI.webp" },
    { name: 'image:width', content: "1200" },
    { name: 'image:height', content: "630" },
  ]
};

// Function to add common images if they are missing
function addCommonImages(meta, imageType) {
  if (!meta.meta) {
    meta.meta = []; // Ensure meta.meta is initialized
  }
  if (!meta.meta.some(i => i.property === `${imageType}:image` || i.name === `${imageType}:image`)) {
    meta.meta.push(...commonImages[imageType]);
  }
}

// Function to prepare meta data for each page
function mappedMetaData(data) {
  const meta = { meta: [] };

  if (data.title) meta.title = data.title;
  if (data.description) {
    meta.meta.push({ name: 'description', content: data.description });
  }

  if (data.og) {
    meta.meta.push(...data.og);
    addCommonImages(meta, 'og');
  }

  if (data.twitter) {
    meta.meta.push(...data.twitter);
    addCommonImages(meta, 'twitter');
  }

  addCommonImages(meta, 'website');

  if (data.canonical) {
    meta.link = [{ rel: 'canonical', href: data.canonical }];
  }

  return meta;
}

// Generate schema markup
function generateSchemaMarkup() {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "MOM - AI-Powered Marketplace",
    "url": "https://momitnow.com",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://momitnow.com/user/home/search?query={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };
}

// Function to render the title as H1 tag on the page
function renderH1Tag(title) {
  if (!title) return; // Prevent rendering an empty H1
  if (!document.querySelector('h1')) { // Check if H1 already exists
    const h1 = document.createElement('h1');
    h1.textContent = title;
    document.body.insertAdjacentElement('afterbegin', h1); // Adds the H1 tag to the top of the body
  }
}

const pagesData = [
  {
    title: 'MOM | AI | Content | Order | Booking | Delivery',
    description: 'An AI-powered marketplace for generating AI content, managing orders, bookings, and deliveries, helping you scale your business effortlessly.',
    canonical: `${appUrl}/en/main`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/en/main` },
      { property: 'og:title', content: 'MOM | AI | Content | Order | Booking | Delivery' },
      { property: 'og:description', content: 'An AI-powered marketplace for generating AI content, managing orders, bookings, and deliveries, helping you scale your business effortlessly.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/en/main` },
      { name: 'twitter:title', content: 'MOM | AI | Content | Order | Booking | Delivery' },
      { name: 'twitter:description', content: 'An AI-powered marketplace for generating AI content, managing orders, bookings, and deliveries, helping you scale your business effortlessly.' },
    ]
  },

{
  title: 'MOM | AI | Konten | Pesanan | Pemesanan | Pengiriman',
  description: 'Pasar berbasis AI untuk menghasilkan konten, mengelola pesanan dan pengiriman, membantu Anda mengembangkan bisnis dengan mudah.',
  canonical: `${appUrl}/id/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/id/main` },
      { property: 'og:title', content: 'MOM | AI | Konten | Pesanan | Pemesanan | Pengiriman' },
      { property: 'og:description', content: 'Pasar berbasis AI untuk menghasilkan konten, mengelola pesanan dan pengiriman, membantu Anda mengembangkan bisnis dengan mudah.' }
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/id/main` },
      { name: 'twitter:title', content: 'MOM | AI | Konten | Pesanan | Pemesanan | Pengiriman' },
      { name: 'twitter:description', content: 'Pasar berbasis AI untuk menghasilkan konten, mengelola pesanan dan pengiriman, membantu Anda mengembangkan bisnis dengan mudah.' }
  ]
},

{
  title: 'MOM | AI | Kontent | Buyurtma | Rezervatsiya | Yetkazib berish',
  description: 'AI asosida bozor, sizga kontent yaratish va buyurtmalarni boshqarish imkonini beradi, biznesingizni osongina kengaytiradi.',
  canonical: `${appUrl}/uz/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/uz/main` },
      { property: 'og:title', content: 'MOM | AI | Kontent | Buyurtma | Rezervatsiya | Yetkazib berish' },
      { property: 'og:description', content: 'AI asosida bozor, sizga kontent yaratish va buyurtmalarni boshqarish imkonini beradi, biznesingizni osongina kengaytiradi.' }
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/uz/main` },
      { name: 'twitter:title', content: 'MOM | AI | Kontent | Buyurtma | Rezervatsiya | Yetkazib berish' },
      { name: 'twitter:description', content: 'AI asosida bozor, sizga kontent yaratish va buyurtmalarni boshqarish imkonini beradi, biznesingizni osongina kengaytiradi.' }
  ]
},

{
  title: 'MOM | KI | Inhalt | Bestellung | Buchung | Lieferung',
  description: 'Ein KI-gesteuertes Marktplatz, um Inhalte zu erstellen und Bestellungen sowie Lieferungen zu verwalten, um Ihr Geschäft zu skalieren.',
  canonical: `${appUrl}/de/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/de/main` },
      { property: 'og:title', content: 'MOM | KI | Inhalt | Bestellung | Buchung | Lieferung' },
      { property: 'og:description', content: 'Ein KI-gesteuertes Marktplatz, um Inhalte zu erstellen und Bestellungen sowie Lieferungen zu verwalten, um Ihr Geschäft zu skalieren.' }
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/de/main` },
      { name: 'twitter:title', content: 'MOM | KI | Inhalt | Bestellung | Buchung | Lieferung' },
      { name: 'twitter:description', content: 'Ein KI-gesteuertes Marktplatz, um Inhalte zu erstellen und Bestellungen sowie Lieferungen zu verwalten, um Ihr Geschäft zu skalieren.' }
  ]
},

{
  title: 'MOM | IA | Conteúdo | Pedido | Reserva | Entrega',
  description: 'Um mercado movido por IA para gerar conteúdo e gerenciar pedidos, reservas e entregas, ajudando você a escalar seu negócio.',
  canonical: `${appUrl}/pt/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/pt/main` },
      { property: 'og:title', content: 'MOM | IA | Conteúdo | Pedido | Reserva | Entrega' },
      { property: 'og:description', content: 'Um mercado movido por IA para gerar conteúdo e gerenciar pedidos, reservas e entregas, ajudando você a escalar seu negócio.' }
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/pt/main` },
      { name: 'twitter:title', content: 'MOM | IA | Conteúdo | Pedido | Reserva | Entrega' },
      { name: 'twitter:description', content: 'Um mercado movido por IA para gerar conteúdo e gerenciar pedidos, reservas e entregas, ajudando você a escalar seu negócio.' }
  ]
},

{
  title: 'MOM | AI | İçerik | Sipariş | Rezervasyon | Teslimat',
  description: 'Yapay zeka destekli bir pazar yeri; içerik oluşturun, siparişleri, rezervasyonları ve teslimatları yönetin, işinizi kolayca ölçeklendirin.',
  canonical: `${appUrl}/tr/main`,
  og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/tr/main` },
  { property: 'og:title', content: 'MOM | AI | İçerik | Sipariş | Rezervasyon | Teslimat' },
  { property: 'og:description', content: 'Yapay zeka destekli bir pazar yeri; içerik oluşturun, siparişleri, rezervasyonları ve teslimatları yönetin, işinizi kolayca ölçeklendirin.' }
  ],
  twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/tr/main` },
  { name: 'twitter:title', content: 'MOM | AI | İçerik | Sipariş | Rezervasyon | Teslimat' },
  { name: 'twitter:description', content: 'Yapay zeka destekli bir pazar yeri; içerik oluşturun, siparişleri, rezervasyonları ve teslimatları yönetin, işinizi kolayca ölçeklendirin.' }
  ]
  },

{
  title: 'MOM | IA | Contenido | Pedido | Reserva | Entrega',
  description: 'Un mercado impulsado por IA para generar contenido, gestionar pedidos, reservas y entregas, ayudándote a escalar tu negocio sin esfuerzo.',
  canonical: `${appUrl}/es/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/es/main` },
      { property: 'og:title', content: 'MOM | IA | Contenido | Pedido | Reserva | Entrega' },
      { property: 'og:description', content: 'Un mercado impulsado por IA para generar contenido, gestionar pedidos, reservas y entregas, ayudándote a escalar tu negocio sin esfuerzo.' }
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/es/main` },
      { name: 'twitter:title', content: 'MOM | IA | Contenido | Pedido | Reserva | Entrega' },
      { name: 'twitter:description', content: 'Un mercado impulsado por IA para generar contenido, gestionar pedidos, reservas y entregas, ayudándote a escalar tu negocio sin esfuerzo.' }
  ]
},

{
    title: 'MOM | এআই | কনটেন্ট | অর্ডার | বুকিং | ডেলিভারি',
    description: 'একটি এআই-সক্ষম বাজার, যেখানে আপনি সামগ্রীর উৎপাদন, অর্ডার, বুকিং এবং ডেলিভারি পরিচালনা করতে পারেন, আপনার ব্যবসা সহজে বৃদ্ধি করতে পারেন।',
    canonical: `${appUrl}/bn/main`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/bn/main` },
      { property: 'og:title', content: 'MOM | এআই | কনটেন্ট | অর্ডার | বুকিং | ডেলিভারি' },
      { property: 'og:description', content: 'একটি এআই-সক্ষম বাজার, যেখানে আপনি সামগ্রীর উৎপাদন, অর্ডার, বুকিং এবং ডেলিভারি পরিচালনা করতে পারেন, আপনার ব্যবসা সহজে বৃদ্ধি করতে পারেন।' }
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/bn/main` },
      { name: 'twitter:title', content: 'MOM | এআই | কনটেন্ট | অর্ডার | বুকিং | ডেলিভারি' },
      { name: 'twitter:description', content: 'একটি এআই-সক্ষম বাজার, যেখানে আপনি সামগ্রীর উৎপাদন, অর্ডার, বুকিং এবং ডেলিভারি পরিচালনা করতে পারেন, আপনার ব্যবসা সহজে বৃদ্ধি করতে পারেন।' }
    ]
  },
  
  {
    title: 'MOM | AI | محتوى | طلب | حجز | توصيل',
    description: 'سوق مدعوم بالذكاء الاصطناعي يمكّنك من إنشاء محتوى مدفوع بالذكاء الاصطناعي بسلاسة، وإدارة الطلبات، والحجوزات، والتوصيلات، كل ذلك أثناء توسيع نطاق عملك بسهولة.',
    canonical: `${appUrl}/ar/main`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/ar/main` },
      { property: 'og:title', content: 'MOM | AI | محتوى | طلب | حجز | توصيل' },
      { property: 'og:description', content: 'سوق مدعوم بالذكاء الاصطناعي يمكّنك من إنشاء محتوى مدفوع بالذكاء الاصطناعي بسلاسة، وإدارة الطلبات، والحجوزات، والتوصيلات، كل ذلك أثناء توسيع نطاق عملك بسهولة.' }
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/ar/main` },
      { name: 'twitter:title', content: 'MOM | AI | محتوى | طلب | حجز | توصيل' },
      { name: 'twitter:description', content: 'سوق مدعوم بالذكاء الاصطناعي يمكّنك من إنشاء محتوى مدفوع بالذكاء الاصطناعي بسلاسة، وإدارة الطلبات، والحجوزات، والتوصيلات، كل ذلك أثناء توسيع نطاق عملك بسهولة.' }
    ]
  },

  {
    title: 'MOM | AI | เนื้อหา | การสั่งซื้อ | การจอง | การจัดส่ง',
    description: 'ตลาดที่ขับเคลื่อนด้วย AI สำหรับสร้างเนื้อหา จัดการคำสั่งซื้อ การจอง และการจัดส่ง ช่วยให้คุณขยายธุรกิจได้อย่างง่ายดาย',
    canonical: `${appUrl}/th/main`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/th/main` },
      { property: 'og:title', content: 'MOM | AI | เนื้อหา | การสั่งซื้อ | การจอง | การจัดส่ง' },
      { property: 'og:description', content: 'ตลาดที่ขับเคลื่อนด้วย AI สำหรับสร้างเนื้อหา จัดการคำสั่งซื้อ การจอง และการจัดส่ง ช่วยให้คุณขยายธุรกิจได้อย่างง่ายดาย' }
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/th/main` },
      { name: 'twitter:title', content: 'MOM | AI | เนื้อหา | การสั่งซื้อ | การจอง | การจัดส่ง' },
      { name: 'twitter:description', content: 'ตลาดที่ขับเคลื่อนด้วย AI สำหรับสร้างเนื้อหา จัดการคำสั่งซื้อ การจอง และการจัดส่ง ช่วยให้คุณขยายธุรกิจได้อย่างง่ายดาย' }
    ]
},

{
  title: 'MOM | एआई | सामग्री | ऑर्डर | बुकिंग | डिलीवरी',
  description: 'एक एआई-संचालित मार्केटप्लेस जो सामग्री बनाने, ऑर्डर और डिलीवरी प्रबंधित करने में मदद करता है, आपके व्यवसाय को बढ़ाता है',
  canonical: `${appUrl}/hi/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/hi/main` },
      { property: 'og:title', content: 'MOM | एआई | सामग्री | ऑर्डर | बुकिंग | डिलीवरी' },
      { property: 'og:description', content: 'एक एआई-संचालित मार्केटप्लेस जो सामग्री बनाने, ऑर्डर और डिलीवरी प्रबंधित करने में मदद करता है, आपके व्यवसाय को बढ़ाता है' },
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/hi/main` },
      { name: 'twitter:title', content: 'MOM | एआई | सामग्री | ऑर्डर | बुकिंग | डिलीवरी' },
      { name: 'twitter:description', content: 'एक एआई-संचालित मार्केटप्लेस जो सामग्री बनाने, ऑर्डर और डिलीवरी प्रबंधित करने में मदद करता है, आपके व्यवसाय को बढ़ाता है' },
  ]
},

{
  title: 'MOM | ИИ | Контент | Заказ | Бронирование | Доставка',
  description: 'Искусственный интеллект на рынке, который позволяет генерировать контент и управлять заказами и доставками, помогает масштабировать бизнес.',
  canonical: `${appUrl}/ru/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/ru/main` },
      { property: 'og:title', content: 'MOM | ИИ | Контент | Заказ | Бронирование | Доставка' },
      { property: 'og:description', content: 'Искусственный интеллект на рынке, который позволяет генерировать контент и управлять заказами и доставками, помогает масштабировать бизнес.' },
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/ru/main` },
      { name: 'twitter:title', content: 'MOM | ИИ | Контент | Заказ | Бронирование | Доставка' },
      { name: 'twitter:description', content: 'Искусственный интеллект на рынке, который позволяет генерировать контент и управлять заказами и доставками, помогает масштабировать бизнес.' },
  ]
},

{
  title: 'மாம் | எஐ | உள்ளடக்கம் | கட்டளை | முன்பதிவு | விநியோகம்',
  description: 'உள்ளடக்கத்தை உருவாக்க, உத்திகள் மற்றும் டெலிவரிகளை நிர்வகிக்க AI அடிப்படையிலான சந்தை, உங்கள் வணிகத்தை எளிதாக வளர்க்க உதவுகிறது.',
  canonical: `${appUrl}/ta/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/ta/main` },
      { property: 'og:title', content: 'மாம் | எஐ | உள்ளடக்கம் | கட்டளை | முன்பதிவு | விநியோகம்' },
      { property: 'og:description', content: 'உள்ளடக்கத்தை உருவாக்க, உத்திகள் மற்றும் டெலிவரிகளை நிர்வகிக்க AI அடிப்படையிலான சந்தை, உங்கள் வணிகத்தை எளிதாக வளர்க்க உதவுகிறது.' },
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/ta/main` },
      { name: 'twitter:title', content: 'மாம் | எஐ | உள்ளடக்கம் | கட்டளை | முன்பதிவு | விநியோகம்' },
      { name: 'twitter:description', content: 'உள்ளடக்கத்தை உருவாக்க, உத்திகள் மற்றும் டெலிவரிகளை நிர்வகிக்க AI அடிப்படையிலான சந்தை, உங்கள் வணிகத்தை எளிதாக வளர்க்க உதவுகிறது.' },
  ]
},

{
  title: 'മോം | എഐ | ഉള്ളടക്കം | ഓർഡർ | ബുക്കിംഗ് | വിതരണം',
  description: 'എഐ ഉപയോഗിച്ചുള്ള ഒരു മാർക്കറ്റ്പ്ലേസ്, ഉള്ളടക്കം നിർമ്മിക്കുക, ഓർഡർ, ബുക്കിംഗ്, ഡെലിവറികൾ എന്നിവ കൈകാര്യം ചെയ്യുക, നിങ്ങളുടെ ബിസിനസ്സ് എളുപ്പത്തിൽ വർദ്ധിപ്പിക്കാൻ.',
  canonical: `${appUrl}/ml/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/ml/main` },
      { property: 'og:title', content: 'മോം | എഐ | ഉള്ളടക്കം | ഓർഡർ | ബുക്കിംഗ് | വിതരണം' },
      { property: 'og:description', content: 'എഐ ഉപയോഗിച്ചുള്ള ഒരു മാർക്കറ്റ്പ്ലേസ്, ഉള്ളടക്കം നിർമ്മിക്കുക, ഓർഡർ, ബുക്കിംഗ്, ഡെലിവറികൾ എന്നിവ കൈകാര്യം ചെയ്യുക, നിങ്ങളുടെ ബിസിനസ്സ് എളുപ്പത്തിൽ വർദ്ധിപ്പിക്കാൻ.' },
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/ml/main` },
      { name: 'twitter:title', content: 'മോം | എഐ | ഉള്ളടക്കം | ഓർഡർ | ബുക്കിംഗ് | വിതരണം' },
      { name: 'twitter:description', content: 'എഐ ഉപയോഗിച്ചുള്ള ഒരു മാർക്കറ്റ്പ്ലേസ്, ഉള്ളടക്കം നിർമ്മിക്കുക, ഓർഡർ, ബുക്കിംഗ്, ഡെലിവറികൾ എന്നിവ കൈകാര്യം ചെയ്യുക, നിങ്ങളുടെ ബിസിനസ്സ് എളുപ്പത്തിൽ വർദ്ധിപ്പിക്കാൻ.' },
  ]
},

{
  title: 'MOM | 人工智能 | 内容 | 订单 | 预订 | 交付',
  description: '一个人工智能驱动的市场，使您能够轻松生成 AI 驱动的内容，管理订单、预订和交付，同时轻松扩展您的业务。',
  canonical: `${appUrl}/zh/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/zh/main` },
      { property: 'og:title', content: 'MOM | 人工智能 | 内容 | 订单 | 预订 | 交付' },
      { property: 'og:description', content: '一个人工智能驱动的市场，使您能够轻松生成 AI 驱动的内容，管理订单、预订和交付，同时轻松扩展您的业务。' },
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/zh/main` },
      { name: 'twitter:title', content: 'MOM | 人工智能 | 内容 | 订单 | 预订 | 交付' },
      { name: 'twitter:description', content: '一个人工智能驱动的市场，使您能够轻松生成 AI 驱动的内容，管理订单、预订和交付，同时轻松扩展您的业务。' },
  ]
},

{
  title: 'موم | اے آئی | مواد | آرڈر | بکنگ | ترسیل',
  description: 'AI پاور مارکیٹ جو مواد تیار کرنے، آرڈرز، بکنگ، اور ڈیلیوری کا انتظام کرنے میں مدد دیتی ہے، آپ کے کاروبار کو بڑھاتی ہے۔',
  canonical: `${appUrl}/ur/main`,
  og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/ur/main` },
      { property: 'og:title', content: 'موم | اے آئی | مواد | آرڈر | بکنگ | ترسیل' },
      { property: 'og:description', content: 'AI پاور مارکیٹ جو مواد تیار کرنے، آرڈرز، بکنگ، اور ڈیلیوری کا انتظام کرنے میں مدد دیتی ہے، آپ کے کاروبار کو بڑھاتی ہے۔' },
  ],
  twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/ur/main` },
      { name: 'twitter:title', content: 'موم | اے آئی | مواد | آرڈر | بکنگ | ترسیل' },
      { name: 'twitter:description', content: 'AI پاور مارکیٹ جو مواد تیار کرنے، آرڈرز، بکنگ، اور ڈیلیوری کا انتظام کرنے میں مدد دیتی ہے، آپ کے کاروبار کو بڑھاتی ہے۔' },
  ]
},

{
    title: 'MOM | AI | Content | Order | Booking | Delivery ',
    description: 'An AI-powered marketplace for generating AI content, managing orders, bookings, and deliveries, helping you scale your business effortlessly.',
    canonical: `${appUrl}/en/main?rel=aiai.tools`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/en/main?rel=aiai.tools` },
      { property: 'og:title', content: 'MOM | AI | Content | Order | Booking | Delivery ' },
      { property: 'og:description', content: 'An AI-powered marketplace for generating AI content, managing orders, bookings, and deliveries, helping you scale your business effortlessly.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/en/main?rel=aiai.tools` },
      { name: 'twitter:title', content: 'MOM | AI | Content | Order | Booking | Delivery ' },
      { name: 'twitter:description', content: 'An AI-powered marketplace for generating AI content, managing orders, bookings, and deliveries, helping you scale your business effortlessly.' },
    ]
  },
  
  {
    title: 'Introduction to Turning Your Hobby into an Online Business - MOM ',
    description: 'Discover how to turn your hobby into a thriving online business with MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/introduction-to-turning-your-hobby-into-an-online-business/42`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/introduction-to-turning-your-hobby-into-an-online-business/42` },
      { property: 'og:title', content: 'Introduction to Turning Your Hobby into an Online Business - MOM ' },
      { property: 'og:description', content: 'Discover how to turn your hobby into a thriving online business with MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/introduction-to-turning-your-hobby-into-an-online-business/42` },
      { name: 'twitter:title', content: 'Introduction to Turning Your Hobby into an Online Business - MOM ' },
      { name: 'twitter:description', content: 'Discover how to turn your hobby into a thriving online business with MOM - Ultimate AI powered Marketplace.' },
    ]
  },
 
  {
    title: 'Understanding the Virtual Marketplace: An Overview - MOM ',
    description: 'Get an insightful overview of the virtual marketplace and its dynamics on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/understanding-the-virtual-marketplace-an-overview/43`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/understanding-the-virtual-marketplace-an-overview/43` },
      { property: 'og:title', content: 'Understanding the Virtual Marketplace: An Overview - MOM ' },
      { property: 'og:description', content: 'Get an insightful overview of the virtual marketplace and its dynamics on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/understanding-the-virtual-marketplace-an-overview/43` },
      { name: 'twitter:title', content: 'Understanding the Virtual Marketplace: An Overview - MOM ' },
      { name: 'twitter:description', content: 'Get an insightful overview of the virtual marketplace and its dynamics on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
 
  {
    title: 'Are AI Detectors Accurate? A Comprehensive Review - MOM ',
    description: 'Explore the accuracy of AI detectors in this comprehensive review on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/are-ai-detectors-accurate-a-comprehensive-review/44`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/are-ai-detectors-accurate-a-comprehensive-review/44` },
      { property: 'og:title', content: 'Are AI Detectors Accurate? A Comprehensive Review - MOM ' },
      { property: 'og:description', content: 'Explore the accuracy of AI detectors in this comprehensive review on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/are-ai-detectors-accurate-a-comprehensive-review/44` },
      { name: 'twitter:title', content: 'Are AI Detectors Accurate? A Comprehensive Review - MOM ' },
      { name: 'twitter:description', content: 'Explore the accuracy of AI detectors in this comprehensive review on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
 
  {
    title: 'Are AI Images Copyrighted? Guide on AI and Copyright Law - MOM ',
    description: 'Learn about the copyright implications of AI-generated images in this in-depth guide on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/are-ai-generated-images-copyrighted-an-in-depth-guide-on-ai-and-copyright-law/45`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/are-ai-generated-images-copyrighted-an-in-depth-guide-on-ai-and-copyright-law/45` },
      { property: 'og:title', content: 'Are AI Images Copyrighted? Guide on AI and Copyright Law - MOM ' },
      { property: 'og:description', content: 'Learn about the copyright implications of AI-generated images in this in-depth guide on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/are-ai-generated-images-copyrighted-an-in-depth-guide-on-ai-and-copyright-law/45` },
      { name: 'twitter:title', content: 'Are AI Images Copyrighted? Guide on AI and Copyright Law - MOM ' },
      { name: 'twitter:description', content: 'Learn about the copyright implications of AI-generated images in this in-depth guide on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Fact or Fiction: Debunking the Myth of AI Robots - MOM ',
    description: 'Separate fact from fiction as we debunk the myth of AI robots on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/fact-or-fiction-debunking-the-myth-of-ai-robots/46`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/fact-or-fiction-debunking-the-myth-of-ai-robots/46` },
      { property: 'og:title', content: 'Fact or Fiction: Debunking the Myth of AI Robots - MOM ' },
      { property: 'og:description', content: 'Separate fact from fiction as we debunk the myth of AI robots on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/fact-or-fiction-debunking-the-myth-of-ai-robots/46` },
      { name: 'twitter:title', content: 'Fact or Fiction: Debunking the Myth of AI Robots - MOM ' },
      { name: 'twitter:description', content: 'Separate fact from fiction as we debunk the myth of AI robots on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Are AI Stocks a Good Investment? An Analysis of the AI Industry - MOM ',
    description: 'Gain insights into the potential of AI stocks as investments with this analysis on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/are-ai-stocks-a-good-investment-an-analysis-of-the-ai-industry/47`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/are-ai-stocks-a-good-investment-an-analysis-of-the-ai-industry/47` },
      { property: 'og:title', content: 'Are AI Stocks a Good Investment? An Analysis of the AI Industry - MOM ' },
      { property: 'og:description', content: 'Gain insights into the potential of AI stocks as investments with this analysis on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/are-ai-stocks-a-good-investment-an-analysis-of-the-ai-industry/47` },
      { name: 'twitter:title', content: 'Are AI Stocks a Good Investment? An Analysis of the AI Industry - MOM ' },
      { name: 'twitter:description', content: 'Gain insights into the potential of AI stocks as investments with this analysis on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Is Your AI App Safe? A Look into Security in AI Applications - MOM ',
    description: 'Explore the security aspects of AI applications and ensure the safety of your AI app on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/is-your-ai-app-safe-a-look-into-security-in-ai-applications/48`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/is-your-ai-app-safe-a-look-into-security-in-ai-applications/48` },
      { property: 'og:title', content: 'Is Your AI App Safe? A Look into Security in AI Applications - MOM ' },
      { property: 'og:description', content: 'Explore the security aspects of AI applications and ensure the safety of your AI app on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/is-your-ai-app-safe-a-look-into-security-in-ai-applications/48` },
      { name: 'twitter:title', content: 'Is Your AI App Safe? A Look into Security in AI Applications - MOM ' },
      { name: 'twitter:description', content: 'Explore the security aspects of AI applications and ensure the safety of your AI app on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Do AI Content Detectors Work? Accuracy and Reliability - MOM ',
    description: 'Assess the accuracy and reliability of AI content detectors on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/do-ai-content-detectors-hit-the-mark-measuring-accuracy-and-reliability/49`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/do-ai-content-detectors-hit-the-mark-measuring-accuracy-and-reliability/49` },
      { property: 'og:title', content: 'Do AI Content Detectors Work? Accuracy and Reliability - MOM ' },
      { property: 'og:description', content: 'Assess the accuracy and reliability of AI content detectors on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/do-ai-content-detectors-hit-the-mark-measuring-accuracy-and-reliability/49` },
      { name: 'twitter:title', content: 'Do AI Content Detectors Work? Accuracy and Reliability - MOM ' },
      { name: 'twitter:description', content: 'Assess the accuracy and reliability of AI content detectors on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: "Reddit's Trust in AI Checkers - Accuracy and Opinions - MOM",
    description: "Analyze discussions and opinions on AI accuracy and Reddit's trust in AI checkers on MOM - Ultimate AI powered Marketplace.",
    canonical: `${appUrl}/blog/does-reddit-trust-ai-checkers-analyzing-discussions-and-opinions-on-ai-accuracy/50`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/does-reddit-trust-ai-checkers-analyzing-discussions-and-opinions-on-ai-accuracy/50` },
      { property: 'og:title', content: "Reddit's Trust in AI Checkers - Accuracy and Opinions - MOM" },
      { property: 'og:description', content: "Analyze discussions and opinions on AI accuracy and Reddit's trust in AI checkers on MOM - Ultimate AI powered Marketplace." },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/does-reddit-trust-ai-checkers-analyzing-discussions-and-opinions-on-ai-accuracy/50` },
      { name: 'twitter:title', content: "Reddit's Trust in AI Checkers - Accuracy and Opinions - MOM" },
      { name: 'twitter:description', content: "Analyze discussions and opinions on AI accuracy and Reddit's trust in AI checkers on MOM - Ultimate AI powered Marketplace." },
    ]
  },
  {
    title: 'Can AI Write Code, Build Websites, and Edit Videos? - MOM ',
    description: 'Explore the capabilities of AI in writing code, building websites, and editing videos on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/the-future-is-here-can-ai-write-code-build-websites-and-edit-videos/51`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/the-future-is-here-can-ai-write-code-build-websites-and-edit-videos/51` },
      { property: 'og:title', content: 'Can AI Write Code, Build Websites, and Edit Videos? - MOM ' },
      { property: 'og:description', content: 'Explore the capabilities of AI in writing code, building websites, and editing videos on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/the-future-is-here-can-ai-write-code-build-websites-and-edit-videos/51` },
      { name: 'twitter:title', content: 'Can AI Write Code, Build Websites, and Edit Videos? - MOM ' },
      { name: 'twitter:description', content: 'Explore the capabilities of AI in writing code, building websites, and editing videos on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Can AI Cure Cancer or Predict Stocks? Exploring AI Limits - MOM ',
    description: 'Delve into the limits of AI technology in curing cancer, predicting stocks, and writing books on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/can-ai-cure-cancer-predict-stocks-or-write-books-exploring-the-limits-of-ai/53`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/can-ai-cure-cancer-predict-stocks-or-write-books-exploring-the-limits-of-ai/53` },
      { property: 'og:title', content: 'Can AI Cure Cancer or Predict Stocks? Exploring AI Limits - MOM ' },
      { property: 'og:description', content: 'Delve into the limits of AI technology in curing cancer, predicting stocks, and writing books on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/can-ai-cure-cancer-predict-stocks-or-write-books-exploring-the-limits-of-ai/53` },
      { name: 'twitter:title', content: 'Can AI Cure Cancer or Predict Stocks? Exploring AI Limits - MOM ' },
      { name: 'twitter:description', content: 'Delve into the limits of AI technology in curing cancer, predicting stocks, and writing books on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Are AI Trading Bots Legal and Effective? - MOM ',
    description: 'Discover the legality and effectiveness of AI trading bots in the evolving world of finance on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/the-evolving-world-of-ai-trading-bots-are-they-legal-and-do-they-work/52`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/the-evolving-world-of-ai-trading-bots-are-they-legal-and-do-they-work/52` },
      { property: 'og:title', content: 'Are AI Trading Bots Legal and Effective? - MOM ' },
      { property: 'og:description', content: 'Discover the legality and effectiveness of AI trading bots in the evolving world of finance on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name:'twitter:url', content: `${appUrl}/blog/the-evolving-world-of-ai-trading-bots-are-they-legal-and-do-they-work/52` },
      { name: 'twitter:title', content: 'Are AI Trading Bots Legal and Effective? - MOM ' },
      { name: 'twitter:description', content: 'Discover the legality and effectiveness of AI trading bots in the evolving world of finance on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Reasons Why It\'s Important to Work with a Payments Partner - MOM ',
    description: 'Explore the benefits and importance of working with a payments partner in your online business on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/-reasons-why-its-important-to-work-with-a-payments-partner/8`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/-reasons-why-its-important-to-work-with-a-payments-partner/8` },
      { property: 'og:title', content: 'Reasons Why It\'s Important to Work with a Payments Partner - MOM ' },
      { property: 'og:description', content: 'Explore the benefits and importance of working with a payments partner in your online business on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/-reasons-why-its-important-to-work-with-a-payments-partner/8` },
      { name: 'twitter:title', content: 'Reasons Why It\'s Important to Work with a Payments Partner - MOM ' },
      { name: 'twitter:description', content: 'Explore the benefits and importance of working with a payments partner in your online business on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'How to Start a Restaurant - MOM ',
    description: 'Discover the essentials of starting your own restaurant and learn how MOM - Ultimate AI powered Marketplace can help streamline the process.',
    canonical: `${appUrl}/blog/how-to-start-a-restaurant/9`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-start-a-restaurant/9` },
      { property: 'og:title', content: 'How to Start a Restaurant - MOM ' },
      { property: 'og:description', content: 'Discover the essentials of starting your own restaurant and learn how MOM - Ultimate AI powered Marketplace can help streamline the process.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-start-a-restaurant/9` },
      { name: 'twitter:title', content: 'How to Start a Restaurant - MOM ' },
      { name: 'twitter:description', content: 'Discover the essentials of starting your own restaurant and learn how MOM - Ultimate AI powered Marketplace can help streamline the process.' },
    ]
  },
  {
    title: 'How to Start an Online Fashion Store - MOM ',
    description: 'Step into the world of fashion e-commerce and learn how to start your own online fashion store with MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/how-to-start-an-online-fashion-store/11`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-start-an-online-fashion-store/11` },
      { property: 'og:title', content: 'How to Start an Online Fashion Store - MOM ' },
      { property: 'og:description', content: 'Step into the world of fashion e-commerce and learn how to start your own online fashion store with MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-start-an-online-fashion-store/11` },
      { name: 'twitter:title', content: 'How to Start an Online Fashion Store - MOM ' },
      { name: 'twitter:description', content: 'Step into the world of fashion e-commerce and learn how to start your own online fashion store with MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'How to Sell Liquor Online - MOM ',
    description: 'Uncover the legalities and best practices for selling liquor online with guidance from MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/how-to-sell-liquor-online/12`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-liquor-online/12` },
      { property: 'og:title', content: 'How to Sell Liquor Online - MOM ' },
      { property: 'og:description', content: 'Uncover the legalities and best practices for selling liquor online with guidance from MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-liquor-online/12` },
      { name: 'twitter:title', content: 'How to Sell Liquor Online - MOM ' },
      { name: 'twitter:description', content: 'Uncover the legalities and best practices for selling liquor online with guidance from MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Why Practice Yoga - MOM ',
    description: 'Explore the countless benefits of practicing yoga and how it can enhance your lifestyle, with insights from MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/why-practice-yoga/14`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/why-practice-yoga/14` },
      { property: 'og:title', content: 'Why Practice Yoga - MOM ' },
      { property: 'og:description', content: 'Explore the countless benefits of practicing yoga and how it can enhance your lifestyle, with insights from MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/why-practice-yoga/14` },
      { name: 'twitter:title', content: 'Why Practice Yoga - MOM ' },
      { name: 'twitter:description', content: 'Explore the countless benefits of practicing yoga and how it can enhance your lifestyle, with insights from MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Setting Up Your Digital Store - MOM ',
    description: 'Get step-by-step instructions on how to set up your digital store quickly and efficiently on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/setting-up-your-digital-store/15`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/setting-up-your-digital-store/15` },
      { property: 'og:title', content: 'Setting Up Your Digital Store - MOM ' },
      { property: 'og:description', content: 'Get step-by-step instructions on how to set up your digital store quickly and efficiently on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/setting-up-your-digital-store/15` },
      { name: 'twitter:title', content: 'Setting Up Your Digital Store - MOM ' },
      { name: 'twitter:description', content: 'Get step-by-step instructions on how to set up your digital store quickly and efficiently on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Become a Provider on MOM - Guide to Using Online Marketplaces ',
    description: 'Maximize your sales potential by harnessing the power of online marketplaces with MOM - Ultimate AI powered Marketplace. Become a top provider with our guide.',
    canonical: `${appUrl}/blog/harnessing-the-power-of-online-marketplaces-a-guide-to-becoming-a-provider-on-mom-app/16`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/harnessing-the-power-of-online-marketplaces-a-guide-to-becoming-a-provider-on-mom-app/16` },
      { property: 'og:title', content: 'Become a Provider on MOM - Guide to Using Online Marketplaces ' },
      { property: 'og:description', content: 'Maximize your sales potential by harnessing the power of online marketplaces with MOM - Ultimate AI powered Marketplace. Become a top provider with our guide.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/harnessing-the-power-of-online-marketplaces-a-guide-to-becoming-a-provider-on-mom-app/16` },
      { name: 'twitter:title', content: 'Become a Provider on MOM - Guide to Using Online Marketplaces ' },
      { name: 'twitter:description', content: 'Maximize your sales potential by harnessing the power of online marketplaces with MOM - Ultimate AI powered Marketplace. Become a top provider with our guide.' },
    ]
  },
  {
    title: 'How to Sell Antiques Online The Right Way With MOM ',
    description: 'Discover the right way to sell antiques online and connect with collectors worldwide through MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/how-to-sell-antiques-online-the-right-way-with-mom/17`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-antiques-online-the-right-way-with-mom/17` },
      { property: 'og:title', content: 'How to Sell Antiques Online The Right Way With MOM ' },
      { property: 'og:description', content: 'Discover the right way to sell antiques online and connect with collectors worldwide through MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-antiques-online-the-right-way-with-mom/17` },
      { name: 'twitter:title', content: 'How to Sell Antiques Online The Right Way With MOM ' },
      { name: 'twitter:description', content: 'Discover the right way to sell antiques online and connect with collectors worldwide through MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'The Sweet Success: How to Sell Cookies Online Successfully with MOM ',
    description: 'Learn the recipe for success in selling cookies online with MOM. Sweeten your business with tips from this comprehensive guide.',
    canonical: `${appUrl}/blog/the-sweet-success-how-to-sell-cookies-online-successfully-with-mom/18`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/the-sweet-success-how-to-sell-cookies-online-successfully-with-mom/18` },
      { property: 'og:title', content: 'The Sweet Success: How to Sell Cookies Online Successfully with MOM ' },
      { property: 'og:description', content: 'Learn the recipe for success in selling cookies online with MOM. Sweeten your business with tips from this comprehensive guide.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/the-sweet-success-how-to-sell-cookies-online-successfully-with-mom/18` },
      { name: 'twitter:title', content: 'The Sweet Success: How to Sell Cookies Online Successfully with MOM ' },
      { name: 'twitter:description', content: 'Learn the recipe for success in selling cookies online with MOM. Sweeten your business with tips from this comprehensive guide.' },
    ]
  },
  {
    title: 'How to Start a Candle Making Business: A Comprehensive Guide - MOM ',
    description: 'Illuminate the path to starting a candle making business with this comprehensive guide from MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/how-to-start-a-candle-making-business-a-comprehensive-guide/19`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-start-a-candle-making-business-a-comprehensive-guide/19` },
      { property: 'og:title', content: 'How to Start a Candle Making Business: A Comprehensive Guide - MOM ' },
      { property: 'og:description', content: 'Illuminate the path to starting a candle making business with this comprehensive guide from MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-start-a-candle-making-business-a-comprehensive-guide/19` },
      { name: 'twitter:title', content: 'How to Start a Candle Making Business: A Comprehensive Guide - MOM ' },
      { name: 'twitter:description', content: 'Illuminate the path to starting a candle making business with this comprehensive guide from MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'A Comprehensive Guide: How to Sell Furniture Online - MOM ',
    description: 'Transform your furniture business by taking it online. This comprehensive guide by MOM - Ultimate AI powered Marketplace tells you how.',
    canonical: `${appUrl}/blog/a-comprehensive-guide-how-to-sell-furniture-online/20`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/a-comprehensive-guide-how-to-sell-furniture-online/20` },
      { property: 'og:title', content: 'A Comprehensive Guide: How to Sell Furniture Online - MOM ' },
      { property: 'og:description', content: 'Transform your furniture business by taking it online. This comprehensive guide by MOM - Ultimate AI powered Marketplace tells you how.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/a-comprehensive-guide-how-to-sell-furniture-online/20` },
      { name: 'twitter:title', content: 'A Comprehensive Guide: How to Sell Furniture Online - MOM ' },
      { name: 'twitter:description', content: 'Transform your furniture business by taking it online. This comprehensive guide by MOM - Ultimate AI powered Marketplace tells you how.' },
    ]
  },
  {
    title: 'How to Sell Courses Online: A Comprehensive Guide - MOM ',
    description: 'Share your knowledge with the world. This comprehensive guide by MOM - Ultimate AI powered Marketplace shows you how to sell courses online effectively.',
    canonical: `${appUrl}/blog/how-to-sell-courses-online-a-comprehensive-guide/21`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-courses-online-a-comprehensive-guide/21` },
      { property: 'og:title', content: 'How to Sell Courses Online: A Comprehensive Guide - MOM ' },
      { property: 'og:description', content: 'Share your knowledge with the world. This comprehensive guide by MOM - Ultimate AI powered Marketplace shows you how to sell courses online effectively.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-courses-online-a-comprehensive-guide/21` },
      { name: 'twitter:title', content: 'How to Sell Courses Online: A Comprehensive Guide - MOM ' },
      { name: 'twitter:description', content: 'Share your knowledge with the world. This comprehensive guide by MOM - Ultimate AI powered Marketplace shows you how to sell courses online effectively.' },
    ]
  },
  {
    title: 'Simple Steps to Start a Successful Laundry Business - MOM ',
    description: 'Get your laundry business plan ready. These simple steps by MOM - Ultimate AI powered Marketplace will help kick-start your successful venture.',
    canonical: `${appUrl}/blog/simple-steps-to-start-a-successful-laundry-business/22`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/simple-steps-to-start-a-successful-laundry-business/22` },
      { property: 'og:title', content: 'Simple Steps to Start a Successful Laundry Business - MOM ' },
      { property: 'og:description', content: 'Get your laundry business plan ready. These simple steps by MOM - Ultimate AI powered Marketplace will help kick-start your successful venture.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/simple-steps-to-start-a-successful-laundry-business/22` },
      { name: 'twitter:title', content: 'Simple Steps to Start a Successful Laundry Business - MOM ' },
      { name: 'twitter:description', content: 'Get your laundry business plan ready. These simple steps by MOM - Ultimate AI powered Marketplace will help kick-start your successful venture.' },
    ]
  },
  {
    title: 'MOM : The Ultimate E-commerce Website Builder for Small Businesses',
    description: 'Discover why MOM - Ultimate AI powered Marketplace is the ultimate e-commerce website builder for small businesses. Build your online store today!',
    canonical: `${appUrl}/blog/mom-app-the-ultimate-e-commerce-website-builder-for-small-businesses/23`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/mom-app-the-ultimate-e-commerce-website-builder-for-small-businesses/23` },
      { property: 'og:title', content: 'MOM : The Ultimate E-commerce Website Builder for Small Businesses' },
      { property: 'og:description', content: 'Discover why MOM - Ultimate AI powered Marketplace is the ultimate e-commerce website builder for small businesses. Build your online store today!' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/mom-app-the-ultimate-e-commerce-website-builder-for-small-businesses/23` },
      { name: 'twitter:title', content: 'MOM : The Ultimate E-commerce Website Builder for Small Businesses' },
      { name: 'twitter:description', content: 'Discover why MOM - Ultimate AI powered Marketplace is the ultimate e-commerce website builder for small businesses. Build your online store today!' },
    ]
  },
  {
    title: 'MOM - E-commerce Builder for Small Businesses ',
    description: 'Future-proof your small business with MOM - Ultimate AI powered Marketplace\'s e-commerce website builder.',
    canonical: `${appUrl}/blog/embrace-the-future-e-commerce-website-builder-for-small-businesses-in-/24`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/embrace-the-future-e-commerce-website-builder-for-small-businesses-in-/24` },
      { property: 'og:title', content: 'MOM - E-commerce Builder for Small Businesses ' },
      { property: 'og:description', content: 'Future-proof your small business with MOM - Ultimate AI powered Marketplace\'s e-commerce website builder.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/embrace-the-future-e-commerce-website-builder-for-small-businesses-in-/24` },
      { name: 'twitter:title', content: 'MOM - E-commerce Builder for Small Businesses ' },
      { name: 'twitter:description', content: 'Future-proof your small business with MOM - Ultimate AI powered Marketplace\'s e-commerce website builder.' },
    ]
  },
  {
    title: 'Sell Jewelry Online - A Guide for MOM Marketplace ',
    description: 'Conquer the online jewelry market with this comprehensive guide from MOM - Ultimate AI powered Marketplace, designed for the trends.',
    canonical: `${appUrl}/blog/how-to-sell-jewelry-online-a-comprehensive-guide-for-the--online-marketplace/25`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-jewelry-online-a-comprehensive-guide-for-the--online-marketplace/25` },
      { property: 'og:title', content: 'Sell Jewelry Online - A Guide for MOM Marketplace ' },
      { property: 'og:description', content: 'Conquer the online jewelry market with this comprehensive guide from MOM - Ultimate AI powered Marketplace, designed for the trends.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-jewelry-online-a-comprehensive-guide-for-the--online-marketplace/25` },
      { name: 'twitter:title', content: 'Sell Jewelry Online - A Guide for MOM Marketplace ' },
      { name: 'twitter:description', content: 'Conquer the online jewelry market with this comprehensive guide from MOM - Ultimate AI powered Marketplace, designed for the trends.' },
    ]
  },
  {
    title: 'How to Sell Crafts Online: Quick Start Guide - MOM ',
    description: 'Turn your creativity into profit. This quick start guide by MOM - Ultimate AI powered Marketplace shows you how to sell crafts online with ease.',
    canonical: `${appUrl}/blog/how-to-sell-crafts-online--quick-start-guide/26`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-crafts-online--quick-start-guide/26` },
      { property: 'og:title', content: 'How to Sell Crafts Online: Quick Start Guide - MOM ' },
      { property: 'og:description', content: 'Turn your creativity into profit. This quick start guide by MOM - Ultimate AI powered Marketplace shows you how to sell crafts online with ease.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-crafts-online--quick-start-guide/26` },
      { name: 'twitter:title', content: 'How to Sell Crafts Online: Quick Start Guide - MOM ' },
      { name: 'twitter:description', content: 'Turn your creativity into profit. This quick start guide by MOM - Ultimate AI powered Marketplace shows you how to sell crafts online with ease.' },
    ]
  },
  {
    title: 'How to Sell Wine Online: Step-by-Step Guide - MOM ',
    description: 'Uncork the secret to selling wine online with this step-by-step guide from MOM - Ultimate AI powered Marketplace. Start pouring profits today!',
    canonical: `${appUrl}/blog/how-to-sell-wine-online--step-by-step-guide/27`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-wine-online--step-by-step-guide/27` },
      { property: 'og:title', content: 'How to Sell Wine Online: Step-by-Step Guide - MOM ' },
      { property: 'og:description', content: 'Uncork the secret to selling wine online with this step-by-step guide from MOM - Ultimate AI powered Marketplace. Start pouring profits today!' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-wine-online--step-by-step-guide/27` },
      { name: 'twitter:title', content: 'How to Sell Wine Online: Step-by-Step Guide - MOM ' },
      { name: 'twitter:description', content: 'Uncork the secret to selling wine online with this step-by-step guide from MOM - Ultimate AI powered Marketplace. Start pouring profits today!' },
    ]
  },
  {
    title: 'How to Sell Purses Online in [Number] Simple Steps - MOM ',
    description: 'Selling purses online made easy! Follow our guide to have your online purse store up and running with MOM.',
    canonical: `${appUrl}/blog/how-to-sell-purses-online-in--simple-steps/29`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-purses-online-in--simple-steps/29` },
      { property: 'og:title', content: 'How to Sell Purses Online in [Number] Simple Steps - MOM ' },
      { property: 'og:description', content: 'Selling purses online made easy! Follow our guide to have your online purse store up and running with MOM.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-purses-online-in--simple-steps/29` },
      { name: 'twitter:title', content: 'How to Sell Purses Online in [Number] Simple Steps - MOM ' },
      { name: 'twitter:description', content: 'Selling purses online made easy! Follow our guide to have your online purse store up and running with MOM.' },
    ]
  },
  {
    title: 'How to Sell Merchandise Online: Step-by-Step Guide - MOM ',
    description: 'Master the art of online merchandise sales with our step-by-step guide. Get started with MOM - Ultimate AI powered Marketplace today!',
    canonical: `${appUrl}/blog/how-to-sell-merchandise-online--step-by-step-guide-for-/30`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-merchandise-online--step-by-step-guide-for-/30` },
      { property: 'og:title', content: 'How to Sell Merchandise Online: Step-by-Step Guide - MOM ' },
      { property: 'og:description', content: 'Master the art of online merchandise sales with our step-by-step guide. Get started with MOM - Ultimate AI powered Marketplace today!' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-merchandise-online--step-by-step-guide-for-/30` },
      { name: 'twitter:title', content: 'How to Sell Merchandise Online: Step-by-Step Guide - MOM ' },
      { name: 'twitter:description', content: 'Master the art of online merchandise sales with our step-by-step guide. Get started with MOM - Ultimate AI powered Marketplace today!' },
    ]
  },
  {
    title: 'How to Sell Food Online: Guide for Beginners - MOM ',
    description: 'Discover a practical approach to selling food online with this beginner-friendly guide by MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/how-to-sell-food-online--guide-for-beginners/31`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-food-online--guide-for-beginners/31` },
      { property: 'og:title', content: 'How to Sell Food Online: Guide for Beginners - MOM ' },
      { property: 'og:description', content: 'Discover a practical approach to selling food online with this beginner-friendly guide by MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-food-online--guide-for-beginners/31` },
      { name: 'twitter:title', content: 'How to Sell Food Online: Guide for Beginners - MOM ' },
      { name: 'twitter:description', content: 'Discover a practical approach to selling food online with this beginner-friendly guide by MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'How to Sell Makeup Online: Steps to Make It Easy for You - MOM ',
    description: 'Enter the beauty industry by selling makeup online. MOM - Ultimate AI powered Marketplace explains simple steps to start easily.',
    canonical: `${appUrl}/blog/how-to-sell-makeup-online---steps-to-make-it-easy-for-you/32`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-makeup-online---steps-to-make-it-easy-for-you/32` },
      { property: 'og:title', content: 'How to Sell Makeup Online: Steps to Make It Easy for You - MOM ' },
      { property: 'og:description', content: 'Enter the beauty industry by selling makeup online. MOM - Ultimate AI powered Marketplace explains simple steps to start easily.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-makeup-online---steps-to-make-it-easy-for-you/32` },
      { name: 'twitter:title', content: 'How to Sell Makeup Online: Steps to Make It Easy for You - MOM ' },
      { name: 'twitter:description', content: 'Enter the beauty industry by selling makeup online. MOM - Ultimate AI powered Marketplace explains simple steps to start easily.' },
    ]
  },
  {
    title: 'The Ultimate Guide on How to Sell Electronics Online - MOM ',
    description: 'Navigate the lucrative world of selling electronics online with this ultimate guide from MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/the-ultimate-guide-on-how-to-sell-electronics-online/33`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/the-ultimate-guide-on-how-to-sell-electronics-online/33` },
      { property: 'og:title', content: 'The Ultimate Guide on How to Sell Electronics Online - MOM ' },
      { property: 'og:description', content: 'Navigate the lucrative world of selling electronics online with this ultimate guide from MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/the-ultimate-guide-on-how-to-sell-electronics-online/33` },
      { name: 'twitter:title', content: 'The Ultimate Guide on How to Sell Electronics Online - MOM ' },
      { name: 'twitter:description', content: 'Navigate the lucrative world of selling electronics online with this ultimate guide from MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'How to Sell Plants Online - MOM ',
    description: 'Green thumb? Turn your plant passion into profit by selling plants online with these tips from MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/how-to-sell-plants-online/34`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-plants-online/34` },
      { property: 'og:title', content: 'How to Sell Plants Online - MOM ' },
      { property: 'og:description', content: 'Green thumb? Turn your plant passion into profit by selling plants online with these tips from MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-plants-online/34` },
      { name: 'twitter:title', content: 'How to Sell Plants Online - MOM ' },
      { name: 'twitter:description', content: 'Green thumb? Turn your plant passion into profit by selling plants online with these tips from MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'How to Sell Shoes Online - MOM ',
    description: 'Step up your online business game by selling shoes online. This guide by MOM - Ultimate AI powered Marketplace shows you how to do it in [Year].',
    canonical: `${appUrl}/blog/how-to-sell-shoes-online-in-/35`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-shoes-online-in-/35` },
      { property: 'og:title', content: 'How to Sell Shoes Online - MOM ' },
      { property: 'og:description', content: 'Step up your online business game by selling shoes online. This guide by MOM - Ultimate AI powered Marketplace shows you how to do it in [Year].' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-shoes-online-in-/35` },
      { name: 'twitter:title', content: 'How to Sell Shoes Online - MOM ' },
      { name: 'twitter:description', content: 'Step up your online business game by selling shoes online. This guide by MOM - Ultimate AI powered Marketplace shows you how to do it in [Year].' },
    ]
  },
  {
    title: 'Sell Coffee Online - Step-by-Step Guide with MOM',
    description: 'Jumpstart your journey of selling coffee online with this step guide from MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/how-to-sell-coffee-online-in---step-by-step-guide/36`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-coffee-online-in---step-by-step-guide/36` },
      { property: 'og:title', content: 'Sell Coffee Online - Step-by-Step Guide with MOM' },
      { property: 'og:description', content: 'Jumpstart your journey of selling coffee online with this step guide from MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-coffee-online-in---step-by-step-guide/36` },
      { name: 'twitter:title', content: 'Sell Coffee Online - Step-by-Step Guide with MOM' },
      { name: 'twitter:description', content: 'Jumpstart your journey of selling coffee online with this step guide from MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'How to Start an Online Store: No Code Required - MOM ',
    description: 'Launching an online store has never been easier. Follow this guide by MOM - Ultimate AI powered Marketplace to create your no-code e-commerce site in [Year].',
    canonical: `${appUrl}/blog/how-to-start-an-online-store-in---no-code-required/37`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-start-an-online-store-in---no-code-required/37` },
      { property: 'og:title', content: 'How to Start an Online Store: No Code Required - MOM ' },
      { property: 'og:description', content: 'Launching an online store has never been easier. Follow this guide by MOM - Ultimate AI powered Marketplace to create your no-code e-commerce site in [Year].' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-start-an-online-store-in---no-code-required/37` },
      { name: 'twitter:title', content: 'How to Start an Online Store: No Code Required - MOM ' },
      { name: 'twitter:description', content: 'Launching an online store has never been easier. Follow this guide by MOM - Ultimate AI powered Marketplace to create your no-code e-commerce site in [Year].' },
    ]
  },
  {
    title: 'How to Sell Books Online: A Detailed Guide - MOM ',
    description: 'Reach a global audience of readers by selling books online. This guide by MOM - Ultimate AI powered Marketplace covers all details for [Year].',
    canonical: `${appUrl}/blog/how-to-sell-books-online--a-detailed-guide-for-/39`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-sell-books-online--a-detailed-guide-for-/39` },
      { property: 'og:title', content: 'How to Sell Books Online: A Detailed Guide - MOM ' },
      { property: 'og:description', content: 'Reach a global audience of readers by selling books online. This guide by MOM - Ultimate AI powered Marketplace covers all details for [Year].' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-sell-books-online--a-detailed-guide-for-/39` },
      { name: 'twitter:title', content: 'How to Sell Books Online: A Detailed Guide - MOM ' },
      { name: 'twitter:description', content: 'Reach a global audience of readers by selling books online. This guide by MOM - Ultimate AI powered Marketplace covers all details for [Year].' },
    ]
  },
  
  {
    title: 'Top Women\'s Accessories to Sell Online - MOM',
    description: 'Discover trending women\'s accessories in the online market. This MOM guide helps you choose the best items to sell.',
    canonical: `${appUrl}/blog/top-womens-accessories-to-sell-online/40`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/top-womens-accessories-to-sell-online/40` },
      { property: 'og:title', content: 'Top Women\'s Accessories to Sell Online - MOM' },
      { property: 'og:description', content: 'Discover trending women\'s accessories in the online market. This MOM guide helps you choose the best items to sell.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/top-womens-accessories-to-sell-online/40` },
      { name: 'twitter:title', content: 'Top Women\'s Accessories to Sell Online - MOM' },
      { name: 'twitter:description', content: 'Discover trending women\'s accessories in the online market. This MOM guide helps you choose the best items to sell.' },
    ]
  },
  
  {
    title: 'A Comprehensive Guide to Men\'s Accessories for Online Selling - MOM ',
    description: 'Learn about the most in-demand men\'s accessories for online selling with this comprehensive guide from MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/blog/a-comprehensive-guide-to-mens-accessories-for-online-selling/41`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/a-comprehensive-guide-to-mens-accessories-for-online-selling/41` },
      { property: 'og:title', content: 'A Comprehensive Guide to Men\'s Accessories for Online Selling - MOM ' },
      { property: 'og:description', content: 'Learn about the most in-demand men\'s accessories for online selling with this comprehensive guide from MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/a-comprehensive-guide-to-mens-accessories-for-online-selling/41` },
      { name: 'twitter:title', content: 'A Comprehensive Guide to Men\'s Accessories for Online Selling - MOM ' },
      { name: 'twitter:description', content: 'Learn about the most in-demand men\'s accessories for online selling with this comprehensive guide from MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Privacy Policy - MOM - Ultimate AI powered Marketplace',
    description: 'Read the MOM - Ultimate AI powered Marketplace privacy policy to understand how we respect and protect your personal information.',
    canonical: `${appUrl}/privacy-policy`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/privacy-policy` },
      { property: 'og:title', content: 'Privacy Policy - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Read the MOM - Ultimate AI powered Marketplace privacy policy to understand how we respect and protect your personal information.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/privacy-policy` },
      { name: 'twitter:title', content: 'Privacy Policy - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Read the MOM - Ultimate AI powered Marketplace privacy policy to understand how we respect and protect your personal information.' },
    ]
  },
  {
    title: 'View Your Orders - MOM - Ultimate AI powered Marketplace',
    description: 'Easily track and manage your orders on the MOM - Ultimate AI powered Marketplace user portal.',
    canonical: `${appUrl}/user/order`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/order` },
      { property: 'og:title', content: 'View Your Orders - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Easily track and manage your orders on the MOM - Ultimate AI powered Marketplace user portal.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/order` },
      { name: 'twitter:title', content: 'View Your Orders - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Easily track and manage your orders on the MOM - Ultimate AI powered Marketplace user portal.' },
    ]
  },
  {
    title: 'Your Services - MOM - Ultimate AI powered Marketplace',
    description: 'Access all the services you are using and manage your subscriptions on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/user/services`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/services` },
      { property: 'og:title', content: 'Your Services - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Access all the services you are using and manage your subscriptions on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/services` },
      { name: 'twitter:title', content: 'Your Services - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Access all the services you are using and manage your subscriptions on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Request a Service - MOM - Ultimate AI powered Marketplace',
    description: 'Need assistance or a new service? Place requests quickly through the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/user/service/request/`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/service/request/` },
      { property: 'og:title', content: 'Request a Service - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Need assistance or a new service? Place requests quickly through the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/service/request/` },
      { name: 'twitter:title', content: 'Request a Service - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Need assistance or a new service? Place requests quickly through the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Store Order Management - MOM - Ultimate AI powered Marketplace',
    description: 'Review your store orders and manage them effectively with the MOM - Ultimate AI powered Marketplace dashboard.',
    canonical: `${appUrl}/user/store/order/`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/store/order/` },
      { property: 'og:title', content: 'Store Order Management - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Review your store orders and manage them effectively with the MOM - Ultimate AI powered Marketplace dashboard.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/store/order/` },
      { name: 'twitter:title', content: 'Store Order Management - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Review your store orders and manage them effectively with the MOM - Ultimate AI powered Marketplace dashboard.' },
    ]
  },
  {
    title: 'Service Agent Management - MOM - Ultimate AI powered Marketplace',
    description: 'Providers can view and manage service requests assigned to them on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/service/agent/view/`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/service/agent/view/` },
      { property: 'og:title', content: 'Service Agent Management - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Providers can view and manage service requests assigned to them on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/service/agent/view/` },
      { name: 'twitter:title', content: 'Service Agent Management - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Providers can view and manage service requests assigned to them on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Shop Dashboard - MOM - Ultimate AI powered Marketplace',
    description: 'Access your personal shop dashboard to oversee performance and update inventory on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/dashboard`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/dashboard` },
      { property: 'og:title', content: 'Shop Dashboard - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Access your personal shop dashboard to oversee performance and update inventory on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/dashboard` },
      { name: 'twitter:title', content: 'Shop Dashboard - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Access your personal shop dashboard to oversee performance and update inventory on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Customer Requests - MOM - Ultimate AI powered Marketplace',
    description: 'View and respond to customer service requests and inquiries in the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/requests`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/requests` },
      { property: 'og:title', content: 'Customer Requests - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'View and respond to customer service requests and inquiries in the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/requests` },
      { name: 'twitter:title', content: 'Customer Requests - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'View and respond to customer service requests and inquiries in the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Provider Login - MOM - Ultimate AI powered Marketplace',
    description: 'Service providers can log in to their MOM - Ultimate AI powered Marketplace profile to access and manage their offerings.',
    canonical: `${appUrl}/provider/login`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/login` },
      { property: 'og:title', content: 'Provider Login - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Service providers can log in to their MOM - Ultimate AI powered Marketplace profile to access and manage their offerings.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/login` },
      { name: 'twitter:title', content: 'Provider Login - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Service providers can log in to their MOM - Ultimate AI powered Marketplace profile to access and manage their offerings.' },
    ]
  },
  {
    title: 'Provider Registration - MOM - Ultimate AI powered Marketplace',
    description: 'Sign up to offer your services on MOM - Ultimate AI powered Marketplace and connect with customers online.',
    canonical: `${appUrl}/provider/registration`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/registration` },
      { property: 'og:title', content: 'Provider Registration - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Sign up to offer your services on MOM - Ultimate AI powered Marketplace and connect with customers online.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/registration` },
      { name: 'twitter:title', content: 'Provider Registration - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Sign up to offer your services on MOM - Ultimate AI powered Marketplace and connect with customers online.' },
    ]
  },
  {
    title: 'Reset Your Password - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Providers can reset their forgotten passwords to regain access to their MOM - Ultimate AI powered Marketplace accounts.',
    canonical: `${appUrl}/provider/forget-password`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/forget-password` },
      { property: 'og:title', content: 'Reset Your Password - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Providers can reset their forgotten passwords to regain access to their MOM - Ultimate AI powered Marketplace accounts.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/forget-password` },
      { name: 'twitter:title', content: 'Reset Your Password - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Providers can reset their forgotten passwords to regain access to their MOM - Ultimate AI powered Marketplace accounts.' },
    ]
  },
  
  {
    title: 'Your Profile - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Update your profile details and manage your service provider account on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/profile`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/profile` },
      { property: 'og:title', content: 'Your Profile - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Update your profile details and manage your service provider account on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/profile` },
      { name: 'twitter:title', content: 'Your Profile - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Update your profile details and manage your service provider account on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  
  {
    title: 'Your Wallet - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Monitor your earnings, view transaction history, and manage your funds through your wallet on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/wallet`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/wallet` },
      { property: 'og:title', content: 'Your Wallet - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Monitor your earnings, view transaction history, and manage your funds through your wallet on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/wallet` },
      { name: 'twitter:title', content: 'Your Wallet - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Monitor your earnings, view transaction history, and manage your funds through your wallet on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Analytics - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Gain insights into your service performance with detailed analytics and reporting tools on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/analytics`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/analytics` },
      { property: 'og:title', content: 'Analytics - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Gain insights into your service performance with detailed analytics and reporting tools on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/analytics` },
      { name: 'twitter:title', content: 'Analytics - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Gain insights into your service performance with detailed analytics and reporting tools on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Your Agents - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Manage your team of agents, assign tasks, and track performance on the MOM - Ultimate AI powered Marketplace provider portal.',
    canonical: `${appUrl}/provider/agents`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/agents` },
      { property: 'og:title', content: 'Your Agents - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Manage your team of agents, assign tasks, and track performance on the MOM - Ultimate AI powered Marketplace provider portal.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/agents` },
      { name: 'twitter:title', content: 'Your Agents - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Manage your team of agents, assign tasks, and track performance on the MOM - Ultimate AI powered Marketplace provider portal.' },
    ]
  },
  
  {
    title: 'Manage Orders - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Review and manage customer orders and service requests quickly and efficiently on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/order`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/order` },
      { property: 'og:title', content: 'Manage Orders - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Review and manage customer orders and service requests quickly and efficiently on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/order` },
      { name: 'twitter:title', content: 'Manage Orders - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Review and manage customer orders and service requests quickly and efficiently on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  
  {
    title: 'Your Services - Provider | MOM - AI Marketplace',
    description: 'Manage and explore your services on the MOM - AI Marketplace.',
    canonical: `${appUrl}/provider/services`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/services` },
      { property: 'og:title', content: 'Your Services - Provider | MOM - AI Marketplace' },
      { property: 'og:description', content: 'Manage and explore your services on the MOM - AI Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' }, // Change to 'summary' if no large image
      { name: 'twitter:url', content: `${appUrl}/provider/services` },
      { name: 'twitter:title', content: 'Your Services - Provider | MOM - AI Marketplace' },
      { name: 'twitter:description', content: 'Manage and explore your services on the MOM - AI Marketplace.' },
    ]
  },
  
  {
    title: 'My Documents - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Securely upload, manage, and store important documents pertaining to your provider profile on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/my-documents`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/my-documents` },
      { property: 'og:title', content: 'My Documents - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Securely upload, manage, and store important documents pertaining to your provider profile on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/my-documents` },
      { name: 'twitter:title', content: 'My Documents - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Securely upload, manage, and store important documents pertaining to your provider profile on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'My Services - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Access a summary of your active services, update your offerings, and manage bookings on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/my-services`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/my-services` },
      { property: 'og:title', content: 'My Services - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Access a summary of your active services, update your offerings, and manage bookings on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/my-services` },
      { name: 'twitter:title', content: 'My Services - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Access a summary of your active services, update your offerings, and manage bookings on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  
  {
    title: 'Agent Service Requests - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Assign and oversee service requests for your agents on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/agent/requests`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/agent/requests` },
      { property: 'og:title', content: 'Agent Service Requests - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Assign and oversee service requests for your agents on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/agent/requests` },
      { name: 'twitter:title', content: 'Agent Service Requests - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Assign and oversee service requests for your agents on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  
  {
    title: 'Customer Reviews - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Read customer reviews and feedback to enhance your services on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/reviews`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/reviews` },
      { property: 'og:title', content: 'Customer Reviews - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Read customer reviews and feedback to enhance your services on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/reviews` },
      { name: 'twitter:title', content: 'Customer Reviews - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Read customer reviews and feedback to enhance your services on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  
  {
    title: 'HRM Departments - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Manage your organizational structure by setting up departments within your provider profile on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/hrm-departments`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/hrm-departments` },
      { property: 'og:title', content: 'HRM Departments - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Manage your organizational structure by setting up departments within your provider profile on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/hrm-departments` },
      { name: 'twitter:title', content: 'HRM Departments - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Manage your organizational structure by setting up departments within your provider profile on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  
  {
    title: 'HRM Designations - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Define and manage employee roles and designations within your organization on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/hrm-designation`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/hrm-designation` },
      { property: 'og:title', content: 'HRM Designations - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Define and manage employee roles and designations within your organization on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/hrm-designation` },
      { name: 'twitter:title', content: 'HRM Designations - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Define and manage employee roles and designations within your organization on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  
  {
    title: 'HRM Attendance - Provider | MOM - AI Marketplace',
    description: 'Track team attendance and working hours on the MOM - AI Marketplace.',
    canonical: `${appUrl}/provider/hrm-attendance`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/hrm-attendance` },
      { property: 'og:title', content: 'HRM Attendance - Provider | MOM - AI Marketplace' },
      { property: 'og:description', content: 'Track team attendance and working hours on the MOM - AI Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' }, // Change to 'summary' if no large image
      { name: 'twitter:url', content: `${appUrl}/provider/hrm-attendance` },
      { name: 'twitter:title', content: 'HRM Attendance - Provider | MOM - AI Marketplace' },
      { name: 'twitter:description', content: 'Track team attendance and working hours on the MOM - AI Marketplace.' },
    ]
  },
    
  {
    title: 'HRM Shift Scheduling - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Organize and manage shift schedules for your employees on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/hrm-shift`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/hrm-shift` },
      { property: 'og:title', content: 'HRM Shift Scheduling - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Organize and manage shift schedules for your employees on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/hrm-shift` },
      { name: 'twitter:title', content: 'HRM Shift Scheduling - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Organize and manage shift schedules for your employees on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  
  {
    title: 'Online Store - MOM - Ultimate AI powered Marketplace',
    description: 'Shop for products and services offered by our trusted providers on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/store`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/store` },
      { property: 'og:title', content: 'Online Store - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Shop for products and services offered by our trusted providers on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/store` },
      { name: 'twitter:title', content: 'Online Store - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Shop for products and services offered by our trusted providers on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  
  {
    title: 'HRM Leave Management - Provider - MOM - Ultimate AI powered Marketplace',
    description: 'Manage leave requests and policies for your staff efficiently on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/provider/hrm-leaves`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/hrm-leaves` },
      { property: 'og:title', content: 'HRM Leave Management - Provider - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Manage leave requests and policies for your staff efficiently on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider/hrm-leaves` },
      { name: 'twitter:title', content: 'HRM Leave Management - Provider - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Manage leave requests and policies for your staff efficiently on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Services by Type - MOM - Ultimate AI powered Marketplace',
    description: 'Explore different types of services available on the MOM - Ultimate AI powered Marketplace to find exactly what you need.',
    canonical: `${appUrl}/type`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/type` },
      { property: 'og:title', content: 'Services by Type - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Explore different types of services available on the MOM - Ultimate AI powered Marketplace to find exactly what you need.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/type` },
      { name: 'twitter:title', content: 'Services by Type - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Explore different types of services available on the MOM - Ultimate AI powered Marketplace to find exactly what you need.' },
    ]
  },
  
  {
    title: 'Provider Photo Gallery | MOM - Ultimate AI Marketplace',
    description: 'Showcase your work with a professional photo gallery on the MOM - Ultimate AI Marketplace.',
    canonical: `${appUrl}/provider/photos`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider/photos` },
      { property: 'og:title', content: 'Provider Photo Gallery | MOM - Ultimate AI Marketplace' },
      { property: 'og:description', content: 'Showcase your work with a professional photo gallery on the MOM - Ultimate AI Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' }, // Change to 'summary' if no large image
      { name: 'twitter:url', content: `${appUrl}/provider/photos` },
      { name: 'twitter:title', content: 'Provider Photo Gallery | MOM - Ultimate AI Marketplace' },
      { name: 'twitter:description', content: 'Showcase your work with a professional photo gallery on the MOM - Ultimate AI Marketplace.' },
    ]
  },
  
  {
    title: 'Login - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Access your shopping account to view orders and continue shopping on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/login`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/login` },
      { property: 'og:title', content: 'Login - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Access your shopping account to view orders and continue shopping on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/login` },
      { name: 'twitter:title', content: 'Login - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Access your shopping account to view orders and continue shopping on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Registration - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Create a new shopping account to start placing orders and accessing exclusive deals on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/registration`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/registration` },
      { property: 'og:title', content: 'Registration - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Create a new shopping account to start placing orders and accessing exclusive deals on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/registration` },
      { name: 'twitter:title', content: 'Registration - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Create a new shopping account to start placing orders and accessing exclusive deals on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Forgot Password - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Reset your forgotten password to regain access to your shopping account on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/forget-password`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/forget-password` },
      { property: 'og:title', content: 'Forgot Password - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Reset your forgotten password to regain access to your shopping account on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/forget-password` },
      { name: 'twitter:title', content: 'Forgot Password - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Reset your forgotten password to regain access to your shopping account on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Change Password - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Secure your shopping account by updating your password on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/change-password`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/change-password` },
      { property: 'og:title', content: 'Change Password - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Secure your shopping account by updating your password on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/change-password` },
      { name: 'twitter:title', content: 'Change Password - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Secure your shopping account by updating your password on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Shop Analytics - MOM - Ultimate AI powered Marketplace',
    description: 'Gain insights into your shopping behavior and preferences with analytics on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/analytics`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/analytics` },
      { property: 'og:title', content: 'Shop Analytics - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Gain insights into your shopping behavior and preferences with analytics on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/analytics` },
      { name: 'twitter:title', content: 'Shop Analytics - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Gain insights into your shopping behavior and preferences with analytics on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Shop Dispatcher - MOM - Ultimate AI powered Marketplace',
    description: 'Oversee your delivery operations and dispatch orders efficiently on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/dispatcher`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/dispatcher` },
      { property: 'og:title', content: 'Shop Dispatcher - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Oversee your delivery operations and dispatch orders efficiently on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/dispatcher` },
      { name: 'twitter:title', content: 'Shop Dispatcher - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Oversee your delivery operations and dispatch orders efficiently on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'My Documents - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Store and access your important shopping documents in one place on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/my-documents`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/my-documents` },
      { property: 'og:title', content: 'My Documents - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Store and access your important shopping documents in one place on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/my-documents` },
      { name: 'twitter:title', content: 'My Documents - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Store and access your important shopping documents in one place on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Bank Details - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Manage your banking information to ensure secure transactions on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/bank-details`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/bank-details` },
      { property: 'og:title', content: 'Bank Details - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Manage your banking information to ensure secure transactions on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/bank-details` },
      { name: 'twitter:title', content: 'Bank Details - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Manage your banking information to ensure secure transactions on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Profile - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Edit and update your personal and shopping profile details on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/profile`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/profile` },
      { property: 'og:title', content: 'Profile - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Edit and update your personal and shopping profile details on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/profile` },
      { name: 'twitter:title', content: 'Profile - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Edit and update your personal and shopping profile details on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Promocodes - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Discover and apply promotional codes to get discounts and special offers on your purchases at the MOM - Ultimate AI powered Marketplace shop.',
    canonical: `${appUrl}/shop/promocodes`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/promocodes` },
      { property: 'og:title', content: 'Promocodes - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Discover and apply promotional codes to get discounts and special offers on your purchases at the MOM - Ultimate AI powered Marketplace shop.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/promocodes` },
      { name: 'twitter:title', content: 'Promocodes - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Discover and apply promotional codes to get discounts and special offers on your purchases at the MOM - Ultimate AI powered Marketplace shop.' },
    ]
  },
  {
    title: 'Shop by Category - MOM - Ultimate AI powered Marketplace',
    description: 'Explore products organized by categories to find exactly what you need in the MOM - Ultimate AI powered Marketplace shop.',
    canonical: `${appUrl}/shop/category`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/category` },
      { property: 'og:title', content: 'Shop by Category - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Explore products organized by categories to find exactly what you need in the MOM - Ultimate AI powered Marketplace shop.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/category` },
      { name: 'twitter:title', content: 'Shop by Category - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Explore products organized by categories to find exactly what you need in the MOM - Ultimate AI powered Marketplace shop.' },
    ]
  },
  {
    title: 'Product Variations - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Choose from a variety of product options such as size, color, and more on the MOM - Ultimate AI powered Marketplace shop.',
    canonical: `${appUrl}/shop/variations`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/variations` },
      { property: 'og:title', content: 'Product Variations - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Choose from a variety of product options such as size, color, and more on the MOM - Ultimate AI powered Marketplace shop.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/variations` },
      { name: 'twitter:title', content: 'Product Variations - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Choose from a variety of product options such as size, color, and more on the MOM - Ultimate AI powered Marketplace shop.' },
    ]
  },
  {
    title: 'Agents - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Connect with our qualified agents to assist you with your shopping experience on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/agents`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/agents` },
      { property: 'og:title', content: 'Agents - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Connect with our qualified agents to assist you with your shopping experience on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/agents` },
      { name: 'twitter:title', content: 'Agents - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Connect with our qualified agents to assist you with your shopping experience on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Agent Dashboard - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Access your agent dashboard to manage tasks, orders, and customer interactions on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/agents-dashboard`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/agents-dashboard` },
      { property: 'og:title', content: 'Agent Dashboard - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Access your agent dashboard to manage tasks, orders, and customer interactions on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/agents-dashboard` },
      { name: 'twitter:title', content: 'Agent Dashboard - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Access your agent dashboard to manage tasks, orders, and customer interactions on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Delivery Settings - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Customize your delivery preferences and settings for optimal convenience on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/delivery-settings`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/delivery-settings` },
      { property: 'og:title', content: 'Delivery Settings - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Customize your delivery preferences and settings for optimal convenience on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/delivery-settings` },
      { name: 'twitter:title', content: 'Delivery Settings - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Customize your delivery preferences and settings for optimal convenience on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Notifications - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Stay up-to-date with the latest alerts, updates, and promotional offers from the MOM - Ultimate AI powered Marketplace shop.',
    canonical: `${appUrl}/shop/notifications`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/notifications` },
      { property: 'og:title', content: 'Notifications - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Stay up-to-date with the latest alerts, updates, and promotional offers from the MOM - Ultimate AI powered Marketplace shop.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/notifications` },
      { name: 'twitter:title', content: 'Notifications - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Stay up-to-date with the latest alerts, updates, and promotional offers from the MOM - Ultimate AI powered Marketplace shop.' },
    ]
  },
  {
    title: 'Product Reviews - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Read and write reviews for products to share your experience with the MOM - Ultimate AI powered Marketplace community.',
    canonical: `${appUrl}/shop/reviews`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/reviews` },
      { property: 'og:title', content: 'Product Reviews - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Read and write reviews for products to share your experience with the MOM - Ultimate AI powered Marketplace community.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/reviews` },
      { name: 'twitter:title', content: 'Product Reviews - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Read and write reviews for products to share your experience with the MOM - Ultimate AI powered Marketplace community.' },
    ]
  },
  {
    title: 'Messaging - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Communicate directly with sellers, agents, and customer service using the MOM - Ultimate AI powered Marketplace messaging system.',
    canonical: `${appUrl}/shop/messaging`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/messaging` },
      { property: 'og:title', content: 'Messaging - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Communicate directly with sellers, agents, and customer service using the MOM - Ultimate AI powered Marketplace messaging system.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/messaging` },
      { name: 'twitter:title', content: 'Messaging - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Communicate directly with sellers, agents, and customer service using the MOM - Ultimate AI powered Marketplace messaging system.' },
    ]
  },
  {
    title: 'Booking - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Book services and schedule appointments with ease using the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/booking`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/booking` },
      { property: 'og:title', content: 'Booking - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Book services and schedule appointments with ease using the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/booking` },
      { name: 'twitter:title', content: 'Booking - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Book services and schedule appointments with ease using the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Shop Add-ons - MOM - Ultimate AI powered Marketplace',
    description: 'Enhance your shopping experience with additional features and tools available on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/addons`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/addons` },
      { property: 'og:title', content: 'Shop Add-ons - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Enhance your shopping experience with additional features and tools available on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/addons` },
      { name: 'twitter:title', content: 'Shop Add-ons - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Enhance your shopping experience with additional features and tools available on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'User Reviews - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Check user reviews to make informed decisions before purchasing products on the MOM - Ultimate AI powered Marketplace shop.',
    canonical: `${appUrl}/shop/user-reviews`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/user-reviews` },
      { property: 'og:title', content: 'User Reviews - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Check user reviews to make informed decisions before purchasing products on the MOM - Ultimate AI powered Marketplace shop.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/user-reviews` },
      { name: 'twitter:title', content: 'User Reviews - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Check user reviews to make informed decisions before purchasing products on the MOM - Ultimate AI powered Marketplace shop.' },
    ]
  },
  {
    title: 'Shop Overview - MOM - Ultimate AI powered Marketplace',
    description: 'Get a comprehensive overview of the products, services, and features offered in the MOM - Ultimate AI powered Marketplace shop.',
    canonical: `${appUrl}/shop/overviews`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/overviews` },
      { property: 'og:title', content: 'Shop Overview - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Get a comprehensive overview of the products, services, and features offered in the MOM - Ultimate AI powered Marketplace shop.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/overviews` },
      { name: 'twitter:title', content: 'Shop Overview - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Get a comprehensive overview of the products, services, and features offered in the MOM - Ultimate AI powered Marketplace shop.' },
    ]
  },
  {
    title: 'Shop Photos - MOM - Ultimate AI powered Marketplace',
    description: 'Browse through our gallery of product photos to visualize what youre shopping for on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/photos`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/photos` },
      { property: 'og:title', content: 'Shop Photos - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Browse through our gallery of product photos to visualize what youre shopping for on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/photos` },
      { name: 'twitter:title', content: 'Shop Photos - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Browse through our gallery of product photos to visualize what youre shopping for on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Shop Items - MOM - Ultimate AI powered Marketplace',
    description: 'Explore our diverse range of items and find everything you need in one place on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/items`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/items` },
      { property: 'og:title', content: 'Shop Items - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Explore our diverse range of items and find everything you need in one place on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/items` },
      { name: 'twitter:title', content: 'Shop Items - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Explore our diverse range of items and find everything you need in one place on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Your Wallet - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Manage your store credit, gift cards, and transactions easily with your personal wallet on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/wallet`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/wallet` },
      { property: 'og:title', content: 'Your Wallet - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Manage your store credit, gift cards, and transactions easily with your personal wallet on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/wallet` },
      { name: 'twitter:title', content: 'Your Wallet - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Manage your store credit, gift cards, and transactions easily with your personal wallet on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Order History - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Review your past orders and keep track of your purchases with the order history feature on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/order-history`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/order-history` },
      { property: 'og:title', content: 'Order History - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Review your past orders and keep track of your purchases with the order history feature on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/order-history` },
      { name: 'twitter:title', content: 'Order History - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Review your past orders and keep track of your purchases with the order history feature on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Order Statement - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Get detailed statements of your orders to monitor spending and order statuses on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/order-statement`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/order-statement` },
      { property: 'og:title', content: 'Order Statement - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Get detailed statements of your orders to monitor spending and order statuses on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/order-statement` },
      { name: 'twitter:title', content: 'Order Statement - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Get detailed statements of your orders to monitor spending and order statuses on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Shop Statement - MOM - Ultimate AI powered Marketplace',
    description: 'Access financial statements for your shop to keep an overview of your sales and earnings on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/shop-statement`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/shop-statement` },
      { property: 'og:title', content: 'Shop Statement - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Access financial statements for your shop to keep an overview of your sales and earnings on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/shop-statement` },
      { name: 'twitter:title', content: 'Shop Statement - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Access financial statements for your shop to keep an overview of your sales and earnings on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'HRM Departments - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Organize and manage your human resources by departments within your shop on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/hrm-departments`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/hrm-departments` },
      { property: 'og:title', content: 'HRM Departments - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Organize and manage your human resources by departments within your shop on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/hrm-departments` },
      { name: 'twitter:title', content: 'HRM Departments - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Organize and manage your human resources by departments within your shop on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'HRM Designations - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Define roles and responsibilities by assigning designations to your team members on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/hrm-designation`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/hrm-designation` },
      { property: 'og:title', content: 'HRM Designations - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Define roles and responsibilities by assigning designations to your team members on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/hrm-designation` },
      { name: 'twitter:title', content: 'HRM Designations - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Define roles and responsibilities by assigning designations to your team members on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'HRM Attendance - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Monitor and record the attendance of your employees to manage staffing needs efficiently on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/hrm-attendance`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/hrm-attendance` },
      { property: 'og:title', content: 'HRM Attendance - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Monitor and record the attendance of your employees to manage staffing needs efficiently on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/hrm-attendance` },
      { name: 'twitter:title', content: 'HRM Attendance - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Monitor and record the attendance of your employees to manage staffing needs efficiently on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'HRM Holidays - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Manage holidays and leave days for your workforce to ensure smooth operation of your shop on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/hrm-holidays`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/hrm-holidays` },
      { property: 'og:title', content: 'HRM Holidays - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Manage holidays and leave days for your workforce to ensure smooth operation of your shop on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/hrm-holidays` },
      { name: 'twitter:title', content: 'HRM Holidays - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Manage holidays and leave days for your workforce to ensure smooth operation of your shop on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'HRM Shift Scheduling - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Plan and manage your employees\' shift schedules to ensure your shop operates efficiently on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/hrm-shift`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/hrm-shift` },
      { property: 'og:title', content: 'HRM Shift Scheduling - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Plan and manage your employees\' shift schedules to ensure your shop operates efficiently on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/hrm-shift` },
      { name: 'twitter:title', content: 'HRM Shift Scheduling - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Plan and manage your employees\' shift schedules to ensure your shop operates efficiently on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'HRM Employees - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Manage your employee profiles and keep track of your workforce details with HRM on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/hrm-employees`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/hrm-employees` },
      { property: 'og:title', content: 'HRM Employees - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Manage your employee profiles and keep track of your workforce details with HRM on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/hrm-employees` },
      { name: 'twitter:title', content: 'HRM Employees - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Manage your employee profiles and keep track of your workforce details with HRM on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'HRM Leave Management - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Handle leave requests and track employee absence for smoother personnel management on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/hrm-leaves`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/hrm-leaves` },
      { property: 'og:title', content: 'HRM Leave Management - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Handle leave requests and track employee absence for smoother personnel management on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/hrm-leaves` },
      { name: 'twitter:title', content: 'HRM Leave Management - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Handle leave requests and track employee absence for smoother personnel management on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'HRM Leave Types - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Set up and customize leave types to cater to your shop\'s policy on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/hrm-leave-types`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/hrm-leave-types` },
      { property: 'og:title', content: 'HRM Leave Types - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Set up and customize leave types to cater to your shop\'s policy on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/hrm-leave-types` },
      { name: 'twitter:title', content: 'HRM Leave Types - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Set up and customize leave types to cater to your shop\'s policy on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Payment Configuration - Shop - MOM - Ultimate AI powered Marketplace',
    description: 'Configure your payment gateways and settings to facilitate smooth transactions on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/payment-config`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/payment-config` },
      { property: 'og:title', content: 'Payment Configuration - Shop - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Configure your payment gateways and settings to facilitate smooth transactions on the MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop/payment-config` },
      { name: 'twitter:title', content: 'Payment Configuration - Shop - MOM - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Configure your payment gateways and settings to facilitate smooth transactions on the MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Shop Policies - MOM - Ultimate AI powered Marketplace',
    description: 'Stay informed about our shop policies including returns, shipping, and privacy on the MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/shop/policies`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop/policies` },
      { property: 'og:title', content: 'Shop Policies - MOM - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Stay informed about our shop policies including returns, shipping, and privacy on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/shop/policies` },
  { name: 'twitter:title', content: 'Shop Policies - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Stay informed about our shop policies including returns, shipping, and privacy on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'User Login - MOM - Ultimate AI powered Marketplace',
description: 'Log in to access your personal user account, track orders, and enjoy a personalized shopping experience on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/login`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/login` },
  { property: 'og:title', content: 'User Login - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Log in to access your personal user account, track orders, and enjoy a personalized shopping experience on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/login` },
  { name: 'twitter:title', content: 'User Login - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Log in to access your personal user account, track orders, and enjoy a personalized shopping experience on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'Forgot Password - User - MOM - Ultimate AI powered Marketplace',
description: 'Easily reset your password if you have forgotten it to regain access to your account on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/forget-password`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/forget-password` },
  { property: 'og:title', content: 'Forgot Password - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Easily reset your password if you have forgotten it to regain access to your account on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/forget-password` },
  { name: 'twitter:title', content: 'Forgot Password - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Easily reset your password if you have forgotten it to regain access to your account on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'User Registration - MOM - Ultimate AI powered Marketplace',
description: 'Create a new user account to start shopping, track orders, and get exclusive offers on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/registration`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/registration` },
  { property: 'og:title', content: 'User Registration - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Create a new user account to start shopping, track orders, and get exclusive offers on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/registration` },
  { name: 'twitter:title', content: 'User Registration - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Create a new user account to start shopping, track orders, and get exclusive offers on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'User Support - MOM - Ultimate AI powered Marketplace',
description: 'Get help with any issues or questions you may have by reaching out to our user support team on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/support`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/support` },
  { property: 'og:title', content: 'User Support - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Get help with any issues or questions you may have by reaching out to our user support team on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/support` },
  { name: 'twitter:title', content: 'User Support - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Get help with any issues or questions you may have by reaching out to our user support team on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'Home - User - MOM - Ultimate AI powered Marketplace',
description: 'Welcome to your home on the MOM - Ultimate AI powered Marketplace - start browsing, shopping, and managing your account here.',
canonical: `${appUrl}/user/home`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/home` },
  { property: 'og:title', content: 'Home - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Welcome to your home on the MOM - Ultimate AI powered Marketplace - start browsing, shopping, and managing your account here.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/home` },
  { name: 'twitter:title', content: 'Home - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Welcome to your home on the MOM - Ultimate AI powered Marketplace - start browsing, shopping, and managing your account here.' },
]
},

{
  title: "MOM AI - Your Gateway to AI-Powered Solutions",
  description: "Unlock your business's potential with MOM AI: AI Chat, Image Generator, Coder, Voiceover, Transcribe, Writer, and more!",
  canonical: `${appUrl}/en/user`,
  og: [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${appUrl}/en/user` },
    { property: "og:title", content: "MOM AI - Your Gateway to AI-Powered Solutions" },
    { property: "og:description", content: "Unlock the potential of your business with MOM AI, featuring AI Chat, AI Image Generator, AI Coder, AI Voiceover, AI Transcribe, AI Writer, and more." }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/en/user` },
    { name: "twitter:title", content: "MOM AI - Your Gateway to AI-Powered Solutions" },
    { name: "twitter:description", content: "Unlock the potential of your business with MOM AI, featuring AI Chat, AI Image Generator, AI Coder, AI Voiceover, AI Transcribe, AI Writer, and more." }
  ]
},
// English (/user?ref=producthunt)
{
  title: "MOM AI - Your Gateway to AI-Powered Solutions",
  description: "Unlock the potential of your business with MOM AI, featuring AI Chat, AI Image Generator, AI Coder, AI Voiceover, AI Transcribe, AI Writer, and more.",
  canonical: `${appUrl}/user?ref=producthunt`,
  og: [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${appUrl}/user?ref=producthunt` },
    { property: "og:title", content: "MOM AI - Your Gateway to AI-Powered Solutions" },
    { property: "og:description", content: "Unlock the potential of your business with MOM AI, featuring AI Chat, AI Image Generator, AI Coder, AI Voiceover, AI Transcribe, AI Writer, and more." }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/user?ref=producthunt` },
    { name: "twitter:title", content: "MOM AI - Your Gateway to AI-Powered Solutions" },
    { name: "twitter:description", content: "Unlock the potential of your business with MOM AI, featuring AI Chat, AI Image Generator, AI Coder, AI Voiceover, AI Transcribe, AI Writer, and more." }
  ]
},

// Portuguese (/pt/user)
{
  title: "MOM AI - Sua Porta de Entrada para Soluções com IA",
  description: "Desbloqueie o potencial do seu negócio com o MOM AI: Chat AI, Gerador de Imagens, Coder, Voiceover, Transcrição, Escritor e mais!",
  canonical: `${appUrl}/pt/user`,
  og: [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${appUrl}/pt/user` },
    { property: "og:title", content: "MOM AI - Sua Porta de Entrada para Soluções com IA" },
    { property: "og:description", content: "Desbloqueie o potencial do seu negócio com o MOM AI: Chat AI, Gerador de Imagens, Coder, Voiceover, Transcrição, Escritor e mais!" }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/pt/user` },
    { name: "twitter:title", content: "MOM AI - Sua Porta de Entrada para Soluções com IA" },
    { name: "twitter:description", content: "Desbloqueie o potencial do seu negócio com o MOM AI: Chat AI, Gerador de Imagens, Coder, Voiceover, Transcrição, Escritor e mais!" }
  ]
},

// German (/de/user)
{
  title: "MOM AI - Ihr Zugang zu KI-basierten Lösungen",
  description: "Entfalten Sie das Potenzial Ihres Unternehmens mit MOM AI: AI-Chat, Bildgenerator, Programmierer, Voiceover, Transkription, Autor und mehr!",
  canonical: `${appUrl}/de/user`,
  og: [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${appUrl}/de/user` },
    { property: "og:title", content: "MOM AI - Ihr Zugang zu KI-basierten Lösungen" },
    { property: "og:description", content: "Entfalten Sie das Potenzial Ihres Unternehmens mit MOM AI: AI-Chat, Bildgenerator, Programmierer, Voiceover, Transkription, Autor und mehr!" }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/de/user` },
    { name: "twitter:title", content: "MOM AI - Ihr Zugang zu KI-basierten Lösungen" },
    { name: "twitter:description", content: "Entfalten Sie das Potenzial Ihres Unternehmens mit MOM AI: AI-Chat, Bildgenerator, Programmierer, Voiceover, Transkription, Autor und mehr!" }
  ]
},

// Uzbek (/uz/user)
{
  title: "MOM AI - Sunʼiy Intellektga Asoslangan Yechimlarga Kirish Yoʻlingiz",
  description: "MOM AI bilan biznesingizning imkoniyatlarini oching: AI Chat, Rasm Generatori, Kodlayuvchi, Ovozni Ovozga Olish, Transkriptsiya, Yozuvchi va boshqalar!",
  canonical: `${appUrl}/uz/user`,
  og: [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${appUrl}/uz/user` },
    { property: "og:title", content: "MOM AI - Sunʼiy Intellektga Asoslangan Yechimlarga Kirish Yoʻlingiz" },
    { property: "og:description", content: "MOM AI bilan biznesingizning imkoniyatlarini oching: AI Chat, Rasm Generatori, Kodlayuvchi, Ovozni Ovozga Olish, Transkriptsiya, Yozuvchi va boshqalar!" }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/uz/user` },
    { name: "twitter:title", content: "MOM AI - Sunʼiy Intellektga Asoslangan Yechimlarga Kirish Yoʻlingiz" },
    { name: "twitter:description", content: "MOM AI bilan biznesingizning imkoniyatlarini oching: AI Chat, Rasm Generatori, Kodlayuvchi, Ovozni Ovozga Olish, Transkriptsiya, Yozuvchi va boshqalar!" }
  ]
},

// Spanish (/es/user)
{
  title: "MOM AI - Tu Puerta de Entrada a Soluciones Impulsadas por IA",
  description: "Desbloquea el potencial de tu negocio con MOM AI: Chat AI, Generador de Imágenes, Programador, Voiceover, Transcripción, Escritor y más!",
  canonical: `${appUrl}/es/user`,
  og: [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${appUrl}/es/user` },
    { property: "og:title", content: "MOM AI - Tu Puerta de Entrada a Soluciones Impulsadas por IA" },
    { property: "og:description", content: "Desbloquea el potencial de tu negocio con MOM AI: Chat AI, Generador de Imágenes, Programador, Voiceover, Transcripción, Escritor y más!" }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/es/user` },
    { name: "twitter:title", content: "MOM AI - Tu Puerta de Entrada a Soluciones Impulsadas por IA" },
    { name: "twitter:description", content: "Desbloquea el potencial de tu negocio con MOM AI: Chat AI, Generador de Imágenes, Programador, Voiceover, Transcripción, Escritor y más!" }
  ]
},

{
  title: "MOM AI - เกตเวย์ของคุณสู่โซลูชันที่ขับเคลื่อนด้วย AI",
  description: "ปลดล็อกศักยภาพของธุรกิจของคุณด้วย MOM AI โดยมี AI Chat, AI Image Generator, AI Coder, AI Voiceover, AI Transcribe, AI Writer และอื่นๆ.",
  canonical: `${appUrl}/th/user`,
  og: [
    { property: "og:type", content: "เว็บไซต์" },
    { property: "og:url", content: `${appUrl}/th/user` },
    { property: "og:title", content: "MOM AI - เกตเวย์ของคุณสู่โซลูชันที่ขับเคลื่อนด้วย AI" },
    { property: "og:description", content: "ปลดล็อกศักยภาพของธุรกิจของคุณด้วย MOM AI โดยมี AI Chat, AI Image Generator, AI Coder, AI Voiceover, AI Transcribe, AI Writer และอื่นๆ." }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/th/user` },
    { name: "twitter:title", content: "MOM AI - เกตเวย์ของคุณสู่โซลูชันที่ขับเคลื่อนด้วย AI" },
    { name: "twitter:description", content: "ปลดล็อกศักยภาพของธุรกิจของคุณด้วย MOM AI โดยมี AI Chat, AI Image Generator, AI Coder, AI Voiceover, AI Transcribe, AI Writer และอื่นๆ." }
  ]
},

{
  title: "MOM AI - بوابتك إلى الحلول المدعومة بالذكاء الاصطناعي",
  description: "افتح إمكانيات عملك مع MOM AI: دردشة AI، مولد الصور، مبرمج، التعليق الصوتي، النسخ، كاتب والمزيد!",
  canonical: `${appUrl}/ar/user`,
  og: [
    { property: "og:type", content: "موقع" },
    { property: "og:url", content: `${appUrl}/ar/user` },
    { property: "og:title", content: "MOM AI - بوابتك إلى الحلول المدعومة بالذكاء الاصطناعي" },
    { property: "og:description", content: "افتح إمكانيات عملك مع MOM AI: دردشة AI، مولد الصور، مبرمج، التعليق الصوتي، النسخ، كاتب والمزيد!" }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/ar/user` },
    { name: "twitter:title", content: "MOM AI - بوابتك إلى الحلول المدعومة بالذكاء الاصطناعي" },
    { name: "twitter:description", content: "افتح إمكانيات عملك مع MOM AI: دردشة AI، مولد الصور، مبرمج، التعليق الصوتي، النسخ، كاتب والمزيد!" }
  ]
},

{
  title: "MOM AI - 您通向人工智能解决方案的门户",
  description: "通过MOM AI释放您的业务潜力，包含AI聊天、AI图像生成器、AI编码器、AI配音、AI转录、AI写作等功能。",
  canonical: `${appUrl}/zh/user`,
  og: [
    { property: "og:type", content: "网站" },
    { property: "og:url", content: `${appUrl}/zh/user` },
    { property: "og:title", content: "MOM AI - 您通向人工智能解决方案的门户" },
    { property: "og:description", content: "通过MOM AI释放您的业务潜力，包含AI聊天、AI图像生成器、AI编码器、AI配音、AI转录、AI写作等功能。" }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/zh/user` },
    { name: "twitter:title", content: "MOM AI - 您通向人工智能解决方案的门户" },
    { name: "twitter:description", content: "通过MOM AI释放您的业务潜力，包含AI聊天、AI图像生成器、AI编码器、AI配音、AI转录、AI写作等功能。" }
  ]
},

{
  title: "MOM AI - आपके लिए AI-समर्थित समाधानों का द्वार",
  description: "MOM AI के साथ अपने व्यवसाय की संभावनाओं को अनलॉक करें, जिसमें AI चैट, AI इमेज जेनरेटर, AI कोडर, AI वॉयसओवर, AI ट्रांसक्राइब, AI लेखक और भी बहुत कुछ शामिल हैं।",
  canonical: `${appUrl}/hi/user`,
  og: [
    { property: "og:type", content: "वेबसाइट" },
    { property: "og:url", content: `${appUrl}/hi/user` },
    { property: "og:title", content: "MOM AI - आपके लिए AI-समर्थित समाधानों का द्वार" },
    { property: "og:description", content: "MOM AI के साथ अपने व्यवसाय की संभावनाओं को अनलॉक करें, जिसमें AI चैट, AI इमेज जेनरेटर, AI कोडर, AI वॉयसओवर, AI ट्रांसक्राइब, AI लेखक और भी बहुत कुछ शामिल हैं।" }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/hi/user` },
    { name: "twitter:title", content: "MOM AI - आपके लिए AI-समर्थित समाधानों का द्वार" },
    { name: "twitter:description", content: "MOM AI के साथ अपने व्यवसाय की संभावनाओं को अनलॉक करें, जिसमें AI चैट, AI इमेज जेनरेटर, AI कोडर, AI वॉयसओवर, AI ट्रांसक्राइब, AI लेखक और भी बहुत कुछ शामिल हैं।" }
  ]
},

{
  title: "MOM AI - AI സാങ്കേതികവിദ്യകളുടെ വഴി",
  description: "MOM AI ഉപയോഗിച്ച് നിങ്ങളുടെ ബിസിനസിന്റെ ശേഷി തുറക്കുക, AI ചാറ്റ്, AI ഇമേജ് ജനറേറ്റർ, AI കോഡർ, AI വോയിസ്ഓവർ, AI ട്രാൻസ്ക്രൈബ്, AI റൈറ്റർ എന്നിവ ഉൾപ്പെടുന്നു.",
  canonical: `${appUrl}/ml/user`,
  og: [
    { property: "og:type", content: "വെബ്‌സൈറ്റ്" },
    { property: "og:url", content: `${appUrl}/ml/user` },
    { property: "og:title", content: "MOM AI - എഐ സാങ്കേതികവിദ്യകളുടെ വഴി" },
    { property: "og:description", content: "MOM AI ഉപയോഗിച്ച് നിങ്ങളുടെ ബിസിനസിന്റെ ശേഷി തുറക്കുക, AI ചാറ്റ്, AI ഇമേജ് ജനറേറ്റർ, AI കോഡർ, AI വോയിസ്ഓവർ, AI ട്രാൻസ്ക്രൈബ്, AI റൈറ്റർ എന്നിവ ഉൾപ്പെടുന്നു." }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/ml/user` },
    { name: "twitter:title", content: "MOM AI - എഐ സാങ്കേതികവിദ്യകളുടെ വഴി" },
    { name: "twitter:description", content: "MOM AI ഉപയോഗിച്ച് നിങ്ങളുടെ ബിസിനസിന്റെ ശേഷി തുറക്കുക, AI ചാറ്റ്, AI ഇമേജ് ജനറേറ്റർ, AI കോഡർ, AI വോയിസ്ഓവർ, AI ട്രാൻസ്ക്രൈബ്, AI റൈറ്റർ എന്നിവ ഉൾപ്പെടുന്നു." }
  ]
},

{
  title: "MOM AI - آپ کے لیے AI طاقتور حلوں کا دروازہ",
  description: "MOM AI کے ساتھ اپنے کاروبار کی صلاحیت کو کھولیں، جس میں AI چیٹ، AI امیج جنریٹر، AI کوڈر، AI وائس اوور، AI ٹرانسکرائب، AI رائٹر، اور مزید شامل ہیں۔",
  canonical: `${appUrl}/ur/user`,
  og: [
    { property: "og:type", content: "ویب سائٹ" },
    { property: "og:url", content: `${appUrl}/ur/user` },
    { property: "og:title", content: "MOM AI - آپ کے لیے AI طاقتور حلوں کا دروازہ" },
    { property: "og:description", content: "MOM AI کے ساتھ اپنے کاروبار کی صلاحیت کو کھولیں، جس میں AI چیٹ، AI امیج جنریٹر، AI کوڈر، AI وائس اوور، AI ٹرانسکرائب، AI رائٹر، اور مزید شامل ہیں۔" }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/ur/user` },
    { name: "twitter:title", content: "MOM AI - آپ کے لیے AI طاقتور حلوں کا دروازہ" },
    { name: "twitter:description", content: "MOM AI کے ساتھ اپنے کاروبار کی صلاحیت کو کھولیں، جس میں AI چیٹ، AI امیج جنریٹر، AI کوڈر، AI وائس اوور، AI ٹرانسکرائب، AI رائٹر، اور مزید شامل ہیں۔" }
  ]
},

{
  title: "MOM AI - Ваш путь к решениям на основе ИИ",
  description: "Откройте потенциал вашего бизнеса с MOM AI, включая AI-чат, генератор изображений AI, AI-кодер, AI-озвучивание, AI-транскрипцию, AI-писателя и многое другое.",
  canonical: `${appUrl}/ru/user`,
  og: [
    { property: "og:type", content: "веб-сайт" },
    { property: "og:url", content: `${appUrl}/ru/user` },
    { property: "og:title", content: "MOM AI - Ваш путь к решениям на основе ИИ" },
    { property: "og:description", content: "Откройте потенциал вашего бизнеса с MOM AI, включая AI-чат, генератор изображений AI, AI-кодер, AI-озвучивание, AI-транскрипцию, AI-писателя и многое другое." }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/ru/user` },
    { name: "twitter:title", content: "MOM AI - Ваш путь к решениям на основе ИИ" },
    { name: "twitter:description", content: "Откройте потенциал вашего бизнеса с MOM AI, включая AI-чат, генератор изображений AI, AI-кодер, AI-озвучивание, AI-транскрипцию, AI-писателя и многое другое." }
  ]
},

{
  title: "MOM AI - AI சக்தியூட்டப்பட்ட தீர்வுகளுக்கு உங்கள் கதவை",
  description: "MOM AI-யுடன் உங்கள் வணிகத்தின் திறனை திறக்கவும், இதில் AI உரையாடல், AI பட உருவாக்கி, AI குறியீட்டு, AI ஒலிவாங்கி, AI உரை, AI எழுத்தாளர் மற்றும் பல அடங்கும்.",
  canonical: `${appUrl}/ta/user`,
  og: [
    { property: "og:type", content: "வெப் சேவையகம்" },
    { property: "og:url", content: `${appUrl}/ta/user` },
    { property: "og:title", content: "MOM AI - AI சக்தியூட்டப்பட்ட தீர்வுகளுக்கு உங்கள் கதவை" },
    { property: "og:description", content: "MOM AI-யுடன் உங்கள் வணிகத்தின் திறனை திறக்கவும், இதில் AI உரையாடல், AI பட உருவாக்கி, AI குறியீட்டு, AI ஒலிவாங்கி, AI உரை, AI எழுத்தாளர் மற்றும் பல அடங்கும்." }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/ta/user` },
    { name: "twitter:title", content: "MOM AI - AI சக்தியூட்டப்பட்ட தீர்வுகளுக்கு உங்கள் கதவை" },
    { name: "twitter:description", content: "MOM AI-யுடன் உங்கள் வணிகத்தின் திறனை திறக்கவும், இதில் AI உரையாடல், AI பட உருவாக்கி, AI குறியீட்டு, AI ஒலிவாங்கி, AI உரை, AI எழுத்தாளர் மற்றும் பல அடங்கும்." }
  ]
},

{
  title: "MOM AI - AI-সক্ষম সমাধানের জন্য আপনার গেটওয়ে",
  description: "এমওএম এআই-এর সাথে আপনার ব্যবসার সম্ভাবনা উন্মুক্ত করুন: এআই চ্যাট, ইমেজ জেনারেটর, কোডার, ভয়েসওভার, ট্রান্সক্রাইব, লেখক এবং আরও অনেক কিছু!",
  canonical: `${appUrl}/bn/user`,
  og: [
    { property: "og:type", content: "ওয়েবসাইট" },
    { property: "og:url", content: `${appUrl}/bn/user` },
    { property: "og:title", content: "MOM AI - AI-সক্ষম সমাধানের জন্য আপনার গেটওয়ে" },
    { property: "og:description", content: "এমওএম এআই-এর সাথে আপনার ব্যবসার সম্ভাবনা উন্মুক্ত করুন: এআই চ্যাট, ইমেজ জেনারেটর, কোডার, ভয়েসওভার, ট্রান্সক্রাইব, লেখক এবং আরও অনেক কিছু!" }
  ],
  twitter: [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: `${appUrl}/bn/user` },
    { name: "twitter:title", content: "MOM AI - AI-সক্ষম সমাধানের জন্য আপনার গেটওয়ে" },
    { name: "twitter:description", content: "এমওএম এআই-এর সাথে আপনার ব্যবসার সম্ভাবনা উন্মুক্ত করুন: এআই চ্যাট, ইমেজ জেনারেটর, কোডার, ভয়েসওভার, ট্রান্সক্রাইব, লেখক এবং আরও অনেক কিছু!" }
  ]
},

{
title: 'Dashboard - User - MOM - Ultimate AI powered Marketplace',
description: 'View your user dashboard to track your recent activity, orders, and personal recommendations on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/dashboard`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/dashboard` },
  { property: 'og:title', content: 'Dashboard - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'View your user dashboard to track your recent activity, orders, and personal recommendations on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/dashboard` },
  { name: 'twitter:title', content: 'Dashboard - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'View your user dashboard to track your recent activity, orders, and personal recommendations on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'Transport Options - User - MOM - Ultimate AI powered Marketplace',
description: 'Choose your preferred transport options for your orders on the MOM - Ultimate AI powered Marketplace for a seamless delivery experience.',
canonical: `${appUrl}/user/transport`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/transport` },
  { property: 'og:title', content: 'Transport Options - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Choose your preferred transport options for your orders on the MOM - Ultimate AI powered Marketplace for a seamless delivery experience.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/transport` },
  { name: 'twitter:title', content: 'Transport Options - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Choose your preferred transport options for your orders on the MOM - Ultimate AI powered Marketplace for a seamless delivery experience.' },
]
},

{
title: 'Delivery Settings - User - MOM - Ultimate AI powered Marketplace',
description: 'Manage your delivery address details and preferences to ensure accurate and timely delivery of your purchases on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/delivery`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/delivery` },
  { property: 'og:title', content: 'Delivery Settings - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Manage your delivery address details and preferences to ensure accurate and timely delivery of your purchases on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/delivery` },
  { name: 'twitter:title', content: 'Delivery Settings - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Manage your delivery address details and preferences to ensure accurate and timely delivery of your purchases on the MOM - Ultimate AI powered Marketplace.' },
]
},

{
title: 'Profile - User - MOM - Ultimate AI powered Marketplace',
description: 'Edit and update your personal profile information to keep your MOM - Ultimate AI powered Marketplace account up-to-date.',
canonical: `${appUrl}/user/profile`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/profile` },
  { property: 'og:title', content: 'Profile - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Edit and update your personal profile information to keep your MOM - Ultimate AI powered Marketplace account up-to-date.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/profile` },
  { name: 'twitter:title', content: 'Profile - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Edit and update your personal profile information to keep your MOM - Ultimate AI powered Marketplace account up-to-date.' },
]
},
{
title: 'Wallet - User - MOM - Ultimate AI powered Marketplace',
description: 'Monitor and manage your wallet balance, add credit, and view transaction history on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/wallet`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/wallet` },
  { property: 'og:title', content: 'Wallet - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Monitor and manage your wallet balance, add credit, and view transaction history on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/wallet` },
  { name: 'twitter:title', content: 'Wallet - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Monitor and manage your wallet balance, add credit, and view transaction history on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'Service Checkout - User - MOM - Ultimate AI powered Marketplace',
description: 'Complete your service requests by checking out with our secure payment gateway on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/service/checkout`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/service/checkout` },
  { property: 'og:title', content: 'Service Checkout - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Complete your service requests by checking out with our secure payment gateway on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/service/checkout` },
  { name: 'twitter:title', content: 'Service Checkout - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Complete your service requests by checking out with our secure payment gateway on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'Messaging - User - MOM - Ultimate AI powered Marketplace',
description: 'Communicate with sellers, support, and other users through a secure messaging platform on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/messaging`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/messaging` },
  { property: 'og:title', content: 'Messaging - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Communicate with sellers, support, and other users through a secure messaging platform on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/messaging` },
  { name: 'twitter:title', content: 'Messaging - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Communicate with sellers, support, and other users through a secure messaging platform on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'AI Chat - User - MOM - Ultimate AI powered Marketplace',
description: 'Get instant assistance and support by chatting with our AI-powered virtual assistant on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/ai/chat`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/ai/chat` },
  { property: 'og:title', content: 'AI Chat - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Get instant assistance and support by chatting with our AI-powered virtual assistant on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/ai/chat` },
  { name: 'twitter:title', content: 'AI Chat - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Get instant assistance and support by chatting with our AI-powered virtual assistant on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'AI Image Generation - User - MOM - Ultimate AI powered Marketplace',
description: 'Create and explore AI-generated images tailored to your preferences on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/ai/images`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/ai/images` },
  { property: 'og:title', content: 'AI Image Generation - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Create and explore AI-generated images tailored to your preferences on the MOM - Ultimate AI powered Marketplace.' },
],
twitter: [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:url', content: `${appUrl}/user/ai/images` },
  { name: 'twitter:title', content: 'AI Image Generation - User - MOM - Ultimate AI powered Marketplace' },
  { name: 'twitter:description', content: 'Create and explore AI-generated images tailored to your preferences on the MOM - Ultimate AI powered Marketplace.' },
]
},
{
title: 'Saved AI Images - User - MOM - Ultimate AI powered Marketplace',
description: 'Access your collection of saved AI-generated images on the MOM - Ultimate AI powered Marketplace.',
canonical: `${appUrl}/user/ai/images/saved`,
og: [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: `${appUrl}/user/ai/images/saved` },
  { property: 'og:title', content: 'Saved AI Images - User - MOM - Ultimate AI powered Marketplace' },
  { property: 'og:description', content: 'Access your collection of saved AI-generated images on the MOM - Ultimate AI powered Marketplace.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/user/ai/images/saved` },
    { name: 'twitter:title', content: 'Saved AI Images - User - MOM - Ultimate AI powered Marketplace' },
    { name: 'twitter:description', content: 'Access your collection of saved AI-generated images on the MOM - Ultimate AI powered Marketplace.' },
  ]
},
{
  title: 'AI Code Assistance - User - MOM - Ultimate AI powered Marketplace',
  description: 'Leverage AI to generate code snippets and solve programming challenges on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/code`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/code` },
    { property: 'og:title', content: 'AI Code Assistance - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Leverage AI to generate code snippets and solve programming challenges on the MOM - Ultimate AI powered Marketplace.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/user/ai/code` },
    { name: 'twitter:title', content: 'AI Code Assistance - User - MOM - Ultimate AI powered Marketplace' },
    { name: 'twitter:description', content: 'Leverage AI to generate code snippets and solve programming challenges on the MOM - Ultimate AI powered Marketplace.' },
  ]
},

{
  title: 'Text-to-Speech - User - MOM - Ultimate AI powered Marketplace',
  description: 'Convert your text into natural-sounding speech using advanced AI text-to-speech technology on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/text-to-speech`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/text-to-speech` },
    { property: 'og:title', content: 'Text-to-Speech - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Convert your text into natural-sounding speech using advanced AI text-to-speech technology on the MOM - Ultimate AI powered Marketplace.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/user/ai/text-to-speech` },
    { name: 'twitter:title', content: 'Text-to-Speech - User - MOM - Ultimate AI powered Marketplace' },
    { name: 'twitter:description', content: 'Convert your text into natural-sounding speech using advanced AI text-to-speech technology on the MOM - Ultimate AI powered Marketplace.' },
  ]
},
{
  title: 'Speech-to-Text - User - MOM - Ultimate AI powered Marketplace',
  description: 'Transcribe your audio into text with high accuracy using AI speech-to-text on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/speech-to-text`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/speech-to-text` },
    { property: 'og:title', content: 'Speech-to-Text - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Transcribe your audio into text with high accuracy using AI speech-to-text on the MOM - Ultimate AI powered Marketplace.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/user/ai/speech-to-text` },
    { name: 'twitter:title', content: 'Speech-to-Text - User - MOM - Ultimate AI powered Marketplace' },
    { name: 'twitter:description', content: 'Transcribe your audio into text with high accuracy using AI speech-to-text on the MOM - Ultimate AI powered Marketplace.' },
  ]
},

{
  title: 'AI Writer - User - MOM - Ultimate AI powered Marketplace',
  description: 'Create content effortlessly with the help of an AI-powered writing assistant on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/writer`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/writer` },
    { property: 'og:title', content: 'AI Writer - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Create content effortlessly with the help of an AI-powered writing assistant on the MOM - Ultimate AI powered Marketplace.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/user/ai/writer` },
    { name: 'twitter:title', content: 'AI Writer - User - MOM - Ultimate AI powered Marketplace' },
    { name: 'twitter:description', content: 'Create content effortlessly with the help of an AI-powered writing assistant on the MOM - Ultimate AI powered Marketplace.' },
  ]
},

{
  title: 'MOM Provider - AI Powered Booking Management Software',
  description: 'Discover our all-in-one ERP for efficiency and growth. Manage your website, bookings, POS, HRMS, accounting, marketing, and agents easily.',
  canonical: `${appUrl}/en/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/en/provider` },
    { property: 'og:title', content: 'MOM Provider - AI Powered Booking Management Software' },
    { property: 'og:description', content: 'Discover our all-in-one ERP for efficiency and growth. Manage your website, bookings, POS, HRMS, accounting, marketing, and agents easily.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/en/provider` },
    { name: 'twitter:title', content: 'MOM Provider - AI Powered Booking Management Software' },
    { name: 'twitter:description', content: 'Discover our all-in-one ERP for efficiency and growth. Manage your website, bookings, POS, HRMS, accounting, marketing, and agents easily.' },
  ]
},

{
  title: 'MOM Provider - AI അനുയോജ്യമായ ബുക്കിംഗ് മാനേജ്മെന്റ് സോഫ്റ്റ്വെയർ',
  description: 'എഫിഷ്യൻസി ಮತ್ತು വളർച്ചയ്ക്കായി ഞങ്ങളുടെ മുഴുവനായ ERP പരിഹാരങ്ങൾ കണ്ടെത്തുക. വെബ്സൈറ്റ്, ബുക്കിംഗ്, POS, HRMS എന്നിവ എളുപ്പത്തിൽ കൈകാര്യം ചെയ്യുക.',
  canonical: `${appUrl}/ml/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ml/provider` },
    { property: 'og:title', content: 'MOM Provider - AI അനുയോജ്യമായ ബുക്കിംഗ് മാനേജ്മെന്റ് സോഫ്റ്റ്വെയർ' },
    { property: 'og:description', content: 'എഫിഷ്യൻസി ಮತ್ತು വളർച്ചയ്ക്കായി ഞങ്ങളുടെ മുഴുവനായ ERP പരിഹാരങ്ങൾ കണ്ടെത്തുക. വെബ്സൈറ്റ്, ബുക്കിംഗ്, POS, HRMS എന്നിവ എളുപ്പത്തിൽ കൈകാര്യം ചെയ്യുക.' }
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/ml/provider` },
    { name: 'twitter:title', content: 'MOM Provider - AI അനുയോജ്യമായ ബുക്കിംഗ് മാനേജ്മെന്റ് സോഫ്റ്റ്വെയർ' },
    { name: 'twitter:description', content: 'എഫിഷ്യൻസി ಮತ್ತು വളർച്ചയ്ക്കായി ഞങ്ങളുടെ മുഴുവനായ ERP പരിഹാരങ്ങൾ കണ്ടെത്തുക. വെബ്സൈറ്റ്, ബുക്കിംഗ്, POS, HRMS എന്നിവ എളുപ്പത്തിൽ കൈകാര്യം ചെയ്യുക.' }
  ]
},

// Thai (/th/provider)
{
  title: 'MOM Provider - ซอฟต์แวร์การจัดการการจองที่ขับเคลื่อนด้วย AI',
  description: 'ค้นพบ ERP แบบ all-in-one ของเราเพื่อประสิทธิภาพและการเติบโต จัดการเว็บไซต์, การจอง, POS, HRMS, บัญชี, การตลาด และตัวแทนอย่างง่ายดาย',
  canonical: `${appUrl}/th/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/th/provider` },
    { property: 'og:title', content: 'MOM Provider - ซอฟต์แวร์การจัดการการจองที่ขับเคลื่อนด้วย AI' },
    { property: 'og:description', content: 'ค้นพบ ERP แบบ all-in-one ของเราเพื่อประสิทธิภาพและการเติบโต จัดการเว็บไซต์, การจอง, POS, HRMS, บัญชี, การตลาด และตัวแทนอย่างง่ายดาย' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/th/provider` },
    { name: 'twitter:title', content: 'MOM Provider - ซอฟต์แวร์การจัดการการจองที่ขับเคลื่อนด้วย AI' },
    { name: 'twitter:description', content: 'ค้นพบ ERP แบบ all-in-one ของเราเพื่อประสิทธิภาพและการเติบโต จัดการเว็บไซต์, การจอง, POS, HRMS, บัญชี, การตลาด และตัวแทนอย่างง่ายดาย' },
  ]
},

// Turkish (/tr/provider)
{
  title: 'MOM Provider - AI Destekli Rezervasyon Yönetim Yazılımı',
  description: 'Verimlilik ve büyüme için tasarlanmış hepsi bir arada ERP çözümümüzü keşfedin. Web sitenizi, rezervasyonları, POS, HRMS, muhasebe ve pazarlamayı kolayca yönetin.',
  canonical: `${appUrl}/tr/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/tr/provider` },
    { property: 'og:title', content: 'MOM Provider - AI Destekli Rezervasyon Yönetim Yazılımı' },
    { property: 'og:description', content: 'Verimlilik ve büyüme için tasarlanmış hepsi bir arada ERP çözümümüzü keşfedin. Web sitenizi, rezervasyonları, POS, HRMS, muhasebe ve pazarlamayı kolayca yönetin.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/tr/provider` },
    { name: 'twitter:title', content: 'MOM Provider - AI Destekli Rezervasyon Yönetim Yazılımı' },
    { name: 'twitter:description', content: 'Verimlilik ve büyüme için tasarlanmış hepsi bir arada ERP çözümümüzü keşfedin. Web sitenizi, rezervasyonları, POS, HRMS, muhasebe ve pazarlamayı kolayca yönetin.' },
  ]
},

// Indonesian (/id/provider)
{
  title: 'MOM Provider - Perangkat Lunak Manajemen Pemesanan yang Didukung AI',
  description: 'Temukan solusi ERP all-in-one kami untuk efisiensi dan pertumbuhan. Kelola situs web, pemesanan, POS, HRMS, dan akuntansi dengan mudah.',
  canonical: `${appUrl}/id/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/id/provider` },
    { property: 'og:title', content: 'MOM Provider - Perangkat Lunak Manajemen Pemesanan yang Didukung AI' },
    { property: 'og:description', content: 'Temukan solusi ERP all-in-one kami untuk efisiensi dan pertumbuhan. Kelola situs web, pemesanan, POS, HRMS, dan akuntansi dengan mudah.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/id/provider` },
    { name: 'twitter:title', content: 'MOM Provider - Perangkat Lunak Manajemen Pemesanan yang Didukung AI' },
    { name: 'twitter:description', content: 'Temukan solusi ERP all-in-one kami untuk efisiensi dan pertumbuhan. Kelola situs web, pemesanan, POS, HRMS, dan akuntansi dengan mudah.' },
  ]
},

// Italian (/it/provider)
{
  title: 'MOM Provider - Software di Gestione Prenotazioni Alimentato da AI',
  description: 'Scopri la nostra soluzione ERP all-in-one per efficienza e crescita. Gestisci il tuo sito web, prenotazioni, POS, HRMS e contabilità facilmente.',
  canonical: `${appUrl}/it/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/it/provider` },
    { property: 'og:title', content: 'MOM Provider - Software di Gestione Prenotazioni Alimentato da AI' },
    { property: 'og:description', content: 'Scopri la nostra soluzione ERP all-in-one per efficienza e crescita. Gestisci il tuo sito web, prenotazioni, POS, HRMS e contabilità facilmente.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/it/provider` },
    { name: 'twitter:title', content: 'MOM Provider - Software di Gestione Prenotazioni Alimentato da AI' },
    { name: 'twitter:description', content: 'Scopri la nostra soluzione ERP all-in-one per efficienza e crescita. Gestisci il tuo sito web, prenotazioni, POS, HRMS e contabilità facilmente.' },
  ]
},

{
  title: 'MOM Provider - Logiciel de Gestion des Réservations Alimenté par l\'IA',
  description: 'Découvrez notre solution ERP tout-en-un conçue pour l\'efficacité et la croissance. Gérez votre site web, réservations, POS et comptabilité facilement.',
  canonical: `${appUrl}/fr/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/fr/provider` },
    { property: 'og:title', content: 'MOM Provider - Logiciel de Gestion des Réservations Alimenté par l\'IA' },
    { property: 'og:description', content: 'Découvrez notre solution ERP tout-en-un conçue pour l\'efficacité et la croissance. Gérez votre site web, réservations, POS et comptabilité facilement.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/fr/provider` },
    { name: 'twitter:title', content: 'MOM Provider - Logiciel de Gestion des Réservations Alimenté par l\'IA' },
    { name: 'twitter:description', content: 'Découvrez notre solution ERP tout-en-un conçue pour l\'efficacité et la croissance. Gérez votre site web, réservations, POS et comptabilité facilement.' },
  ]
},

{
  title: 'مركز MOM - برنامج إدارة الحجز المدعوم بالذكاء الاصطناعي',
  description: 'اكتشف حلول ERP الشاملة لدينا لزيادة الكفاءة والنمو. إدارة موقعك، الحجوزات، POS، HRMS، المحاسبة، التسويق، وإدارة الوكلاء بسهولة.',
  canonical: `${appUrl}/ar/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ar/provider` },
    { property: 'og:title', content: 'مركز MOM - برنامج إدارة الحجز المدعوم بالذكاء الاصطناعي' },
    { property: 'og:description', content: 'اكتشف حلول ERP الشاملة لدينا لزيادة الكفاءة والنمو. إدارة موقعك، الحجوزات، POS، HRMS، المحاسبة، التسويق، وإدارة الوكلاء بسهولة.' }
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/ar/provider` },
    { name: 'twitter:title', content: 'مركز MOM - برنامج إدارة الحجز المدعوم بالذكاء الاصطناعي' },
    { name: 'twitter:description', content: 'اكتشف حلول ERP الشاملة لدينا لزيادة الكفاءة والنمو. إدارة موقعك، الحجوزات، POS، HRMS، المحاسبة، التسويق، وإدارة الوكلاء بسهولة.' }
  ]
},

{
  title: 'MOM Provider - AI அடிப்படையிலான பதிவு மேலாண்மை மென்பொருள்',
  description: 'எங்கள் அனைத்துப் பொருள்கள் உள்ள ERP தீர்வைப் பற்றிக் கண்டறியவும். உங்கள் வலைத்தளம், புக்கிங், POS, HRMS மற்றும் கணக்கியல் எளிதாக நிர்வகிக்கவும்.',
  canonical: `${appUrl}/ta/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ta/provider` },
    { property: 'og:title', content: 'MOMProvider - AI அடிப்படையிலான பதிவு மேலாண்மை மென்பொருள்' },
    { property: 'og:description', content: 'எங்கள் அனைத்துப் பொருள்கள் உள்ள ERP தீர்வைப் பற்றிக் கண்டறியவும். உங்கள் வலைத்தளம், புக்கிங், POS, HRMS மற்றும் கணக்கியல் எளிதாக நிர்வகிக்கவும்.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/ta/provider` },
    { name: 'twitter:title', content: 'MOMProvider - AI அடிப்படையிலான பதிவு மேலாண்மை மென்பொருள்' },
    { name: 'twitter:description', content: 'எங்கள் அனைத்துப் பொருள்கள் உள்ள ERP தீர்வைப் பற்றிக் கண்டறியவும். உங்கள் வலைத்தளம், புக்கிங், POS, HRMS மற்றும் கணக்கியல் எளிதாக நிர்வகிக்கவும்.' },
  ]
},

{
  title: 'MOM Provider - AI سے طاقتور بکنگ انتظامی سافٹ ویئر',
  description: 'کارکردگی اور ترقی کے لیے ہمارے مکمل ERP حل کا پتہ لگائیں۔ اپنی ویب سائٹ، بُکنگ، POS، HRMS، اکاؤنٹنگ، مارکیٹنگ، اور ایجنٹوں کا انتظام آسانی سے کریں۔',
  canonical: `${appUrl}/ur/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ur/provider` },
    { property: 'og:title', content: 'MOM Provider - AI سے طاقتور بکنگ انتظامی سافٹ ویئر' },
    { property: 'og:description', content: 'کارکردگی اور ترقی کے لیے ہمارے مکمل ERP حل کا پتہ لگائیں۔ اپنی ویب سائٹ، بُکنگ، POS، HRMS، اکاؤنٹنگ، مارکیٹنگ، اور ایجنٹوں کا انتظام آسانی سے کریں۔' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/ur/provider` },
    { name: 'twitter:title', content: 'MOM Provider - AI سے طاقتور بکنگ انتظامی سافٹ ویئر' },
    { name: 'twitter:description', content: 'کارکردگی اور ترقی کے لیے ہمارے مکمل ERP حل کا پتہ لگائیں۔ اپنی ویب سائٹ، بُکنگ، POS، HRMS، اکاؤنٹنگ، مارکیٹنگ، اور ایجنٹوں کا انتظام آسانی سے کریں۔' },
  ]
},

{
  title: 'MOM 提供者 - AI 驱动的预订管理软件',
  description: '发现我们为效率和增长而设计的一体化ERP解决方案，轻松管理您的网站、预订、云POS、HRMS、会计、营销和代理管理。',
  canonical: `${appUrl}/zh/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/zh/provider` },
    { property: 'og:title', content: 'MOM提供者 - AI驱动的预订管理软件' },
    { property: 'og:description', content: '发现我们为效率和增长而设计的一体化ERP解决方案，轻松管理您的网站、预订、云POS、HRMS、会计、营销和代理管理。' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/zh/provider` },
    { name: 'twitter:title', content: 'MOM提供者 - AI驱动的预订管理软件' },
    { name: 'twitter:description', content: '发现我们为效率和增长而设计的一体化ERP解决方案，轻松管理您的网站、预订、云POS、HRMS、会计、营销和代理管理。' },
  ]
},

{
  title: 'MOM প্রোভাইডার - AI দ্বারা চালিত বুকিং ম্যানেজমেন্ট সফটওয়্যার',
  description: 'কার্যকারিতা এবং বৃদ্ধির জন্য আমাদের সব-এক ERP সমাধান আবিষ্কার করুন। আপনার ওয়েবসাইট, বুকিং, POS এবং অ্যাকাউন্টিং পরিচালনা করুন।',
  canonical: `${appUrl}/bn/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/bn/provider` },
    { property: 'og:title', content: 'MOM প্রোভাইডার - AI দ্বারা চালিত বুকিং ম্যানেজমেন্ট সফটওয়্যার' },
    { property: 'og:description', content: 'কার্যকারিতা এবং বৃদ্ধির জন্য আমাদের সব-এক ERP সমাধান আবিষ্কার করুন। আপনার ওয়েবসাইট, বুকিং, POS এবং অ্যাকাউন্টিং পরিচালনা করুন।' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/bn/provider` },
    { name: 'twitter:title', content: 'MOM প্রোভাইডার - AI দ্বারা চালিত বুকিং ম্যানেজমেন্ট সফটওয়্যার' },
    { name: 'twitter:description', content: 'কার্যকারিতা এবং বৃদ্ধির জন্য আমাদের সব-এক ERP সমাধান আবিষ্কার করুন। আপনার ওয়েবসাইট, বুকিং, POS এবং অ্যাকাউন্টিং পরিচালনা করুন।' },
  ]
},

{
  title: 'MOM प्रदाता - AI संचालित बुकिंग प्रबंधन सॉफ़्टवेयर',
  description: 'कार्यक्षमता और वृद्धि के लिए हमारे सभी-इन-वन ERP समाधान का पता लगाएं। अपनी वेबसाइट, बुकिंग, POS और लेखांकन को आसानी से प्रबंधित करें।',
  canonical: `${appUrl}/hi/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/hi/provider` },
    { property: 'og:title', content: 'MOM प्रदाता - AI संचालित बुकिंग प्रबंधन सॉफ़्टवेयर' },
    { property: 'og:description', content: 'कार्यक्षमता और वृद्धि के लिए हमारे सभी-इन-वन ERP समाधान का पता लगाएं। अपनी वेबसाइट, बुकिंग, POS और लेखांकन को आसानी से प्रबंधित करें।' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/hi/provider` },
    { name: 'twitter:title', content: 'MOM प्रदाता - AI संचालित बुकिंग प्रबंधन सॉफ़्टवेयर' },
    { name: 'twitter:description', content: 'कार्यक्षमता और वृद्धि के लिए हमारे सभी-इन-वन ERP समाधान का पता लगाएं। अपनी वेबसाइट, बुकिंग, POS और लेखांकन को आसानी से प्रबंधित करें।' },
  ]
},

{
  title: 'MOM Shop - AI-Powered All-in-One Business Management Software',
  description: 'Discover MOM Shop: a flexible ERP with order management, cloud POS, HRMS, accounting, marketing, and delivery for all businesses.',
  canonical: `${appUrl}/en/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/en/shop` },
    { property: 'og:title', content: 'MOM Shop - AI-Powered All-in-One Business Management Software' },
    { property: 'og:description', content: 'Discover MOM Shop: a flexible ERP solution with integrated order management, cloud POS, HRMS, accounting, marketing, and delivery management for all businesses.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/en/shop` },
    { name: 'twitter:title', content: 'MOM Shop - AI-Powered All-in-One Business Management Software' },
    { name: 'twitter:description', content: 'Discover MOM Shop: a flexible ERP solution with integrated order management, cloud POS, HRMS, accounting, marketing, and delivery management for all businesses.' },
  ]
},

{
  title: 'MOM Shop - AI संचालित ऑल-इन-वन व्यवसाय प्रबंधन सॉफ्टवेयर',
  description: 'MOM शॉप खोजें: सभी व्यवसायों के लिए एक लचीला ERP समाधान जिसमें एकीकृत ऑर्डर प्रबंधन, क्लाउड POS, HRMS, लेखांकन, विपणन और डिलीवरी प्रबंधन शामिल है',
  canonical: `${appUrl}/hi/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/hi/shop` },
    { property: 'og:title', content: 'MOM Shop - AI संचालित ऑल-इन-वन व्यवसाय प्रबंधन सॉफ्टवेयर' },
    { property: 'og:description', content: 'MOM शॉप खोजें: सभी व्यवसायों के लिए एक लचीला ERP समाधान जिसमें एकीकृत ऑर्डर प्रबंधन, क्लाउड POS, HRMS, लेखांकन, विपणन और डिलीवरी प्रबंधन शामिल है' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/hi/shop` },
    { name: 'twitter:title', content: 'MOM Shop - AI संचालित ऑल-इन-वन व्यवसाय प्रबंधन सॉफ्टवेयर' },
    { name: 'twitter:description', content: 'MOM शॉप खोजें: सभी व्यवसायों के लिए एक लचीला ERP समाधान जिसमें एकीकृत ऑर्डर प्रबंधन, क्लाउड POS, HRMS, लेखांकन, विपणन और डिलीवरी प्रबंधन शामिल है' },
  ]
},

// Malayalam (ml)
{
  title: 'MOM Shop - AI ശക്തിയുള്ള ഓൾ-ഇൻ-വൺ ബിസിനസ്സ് മാനേജ്മെന്റ് സോഫ്റ്റ്‌വെയർ',
  description: 'മോം ഷോപ്പ്: എല്ലാ ബിസിനസുകൾക്കും അനുയോജ്യമായ ഓർഡർ മാനേജ്മെന്റ്, ക്ലൗഡ് പി‌ഒഎസ്, എച്ച്ആർഎംഎസ്, അക്കൗണ്ടിംഗ്, മാർക്കറ്റിംഗ്, ഡെലിവറിയുള്ള ഫ്ലെക്സിബിള്‍ ഇആർപി.',
  canonical: `${appUrl}/ml/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ml/shop` },
    { property: 'og:title', content: 'MOM Shop - AI ശക്തിയുള്ള ഓൾ-ഇൻ-വൺ ബിസിനസ്സ് മാനേജ്മെന്റ് സോഫ്റ്റ്‌വെയർ' },
    { property: 'og:description', content: 'മോം ഷോപ്പ്: എല്ലാ ബിസിനസുകൾക്കും അനുയോജ്യമായ ഓർഡർ മാനേജ്മെന്റ്, ക്ലൗഡ് പി‌ഒഎസ്, എച്ച്ആർഎംഎസ്, അക്കൗണ്ടിംഗ്, മാർക്കറ്റിംഗ്, ഡെലിവറിയുള്ള ഫ്ലെക്സിബിള്‍ ഇആർപി.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/ml/shop` },
    { name: 'twitter:title', content: 'MOM Shop - AI ശക്തിയുള്ള ഓൾ-ഇൻ-വൺ ബിസിനസ്സ് മാനേജ്മെന്റ് സോഫ്റ്റ്‌വെയർ' },
    { name: 'twitter:description', content: 'മോം ഷോപ്പ്: എല്ലാ ബിസിനസുകൾക്കും അനുയോജ്യമായ ഓർഡർ മാനേജ്മെന്റ്, ക്ലൗഡ് പി‌ഒഎസ്, എച്ച്ആർഎംഎസ്, അക്കൗണ്ടിംഗ്, മാർക്കറ്റിംഗ്, ഡെലിവറിയുള്ള ഫ്ലെക്സിബിള്‍ ഇആർപി.' },
  ]
},

// Bengali (bn)
{
  title: 'MOM Shop - AI চালিত অল-ইন-ওয়ান বিজনেস ম্যানেজমেন্ট সফটওয়্যার',
  description: 'MOM Shop আবিষ্কার করুন: সমস্ত ব্যবসার জন্য একত্রীত আদেশ পরিচালনা, ক্লাউড POS, HRMS, হিসাবরক্ষণ, বিপণন এবং বিতরণ ব্যবস্থাপনার সাথে একটি নমনীয় ERP সমাধান।',
  canonical: `${appUrl}/bn/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/bn/shop` },
    { property: 'og:title', content: 'MOM Shop - AI চালিত অল-ইন-ওয়ান বিজনেস ম্যানেজমেন্ট সফটওয়্যার' },
    { property: 'og:description', content: 'MOM Shop আবিষ্কার করুন: সমস্ত ব্যবসার জন্য একত্রীত আদেশ পরিচালনা, ক্লাউড POS, HRMS, হিসাবরক্ষণ, বিপণন এবং বিতরণ ব্যবস্থাপনার সাথে একটি নমনীয় ERP সমাধান।' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/bn/shop` },
    { name: 'twitter:title', content: 'MOM Shop - AI চালিত অল-ইন-ওয়ান বিজনেস ম্যানেজমেন্ট সফটওয়্যার' },
    { name: 'twitter:description', content: 'MOM Shop আবিষ্কার করুন: সমস্ত ব্যবসার জন্য একত্রীত আদেশ পরিচালনা, ক্লাউড POS, HRMS, হিসাবরক্ষণ, বিপণন এবং বিতরণ ব্যবস্থাপনার সাথে একটি নমনীয় ERP সমাধান।' },
  ]
},

// German (de)
{
  title: 'MOM Shop - KI-gestützte All-in-One-Business-Management-Software',
  description: 'Entdecken Sie MOM Shop: eine flexible ERP-Lösung mit Auftragsmanagement, Cloud-POS, HRMS, Buchhaltung, Marketing und Lieferung für alle Unternehmen.',
  canonical: `${appUrl}/de/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/de/shop` },
    { property: 'og:title', content: 'MOM Shop - KI-gestützte All-in-One-Business-Management-Software' },
    { property: 'og:description', content: 'Entdecken Sie MOM Shop: eine flexible ERP-Lösung mit Auftragsmanagement, Cloud-POS, HRMS, Buchhaltung, Marketing und Lieferung für alle Unternehmen.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/de/shop` },
    { name: 'twitter:title', content: 'MOM Shop - KI-gestützte All-in-One-Business-Management-Software' },
    { name: 'twitter:description', content: 'Entdecken Sie MOM Shop: eine flexible ERP-Lösung mit Auftragsmanagement, Cloud-POS, HRMS, Buchhaltung, Marketing und Lieferung für alle Unternehmen.' },
  ]
},

{
  title: 'MOM Shop - Yapay Zekâ Destekli Tümleşik İş Yönetimi Yazılımı',
  description: 'MOM Shop\'ı keşfedin: tüm işletmeler için entegre sipariş yönetimi, bulut POS, HRMS, muhasebe, pazarlama ve teslimat yönetimi ile esnek bir ERP çözümü.',
  canonical: `${appUrl}/tr/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/tr/shop` },
    { property: 'og:title', content: 'MOM Shop - Yapay Zekâ Destekli Tümleşik İş Yönetimi Yazılımı' },
    { property: 'og:description', content: 'MOM Shop\'ı keşfedin: tüm işletmeler için entegre sipariş yönetimi, bulut POS, HRMS, muhasebe, pazarlama ve teslimat yönetimi ile esnek bir ERP çözümü.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/tr/shop` },
    { name: 'twitter:title', content: 'MOM Shop - Yapay Zekâ Destekli Tümleşik İş Yönetimi Yazılımı' },
    { name: 'twitter:description', content: 'MOM Shop\'ı keşfedin: tüm işletmeler için entegre sipariş yönetimi, bulut POS, HRMS, muhasebe, pazarlama ve teslimat yönetimi ile esnek bir ERP çözümü.' },
  ]
},

{
  title: 'MOM Shop - Software di gestione aziendale tutto-in-uno basato su AI',
  description: 'Scopri MOM Shop: un ERP flessibile con gestione ordini, cloud POS, HRMS, contabilità, marketing e consegna per tutte le aziende.',
  canonical: `${appUrl}/it/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/it/shop` },
    { property: 'og:title', content: 'MOM Shop - Software di gestione aziendale tutto-in-uno basato su AI' },
    { property: 'og:description', content: 'Scopri MOM Shop: un ERP flessibile con gestione ordini, cloud POS, HRMS, contabilità, marketing e consegna per tutte le aziende.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/it/shop` },
    { name: 'twitter:title', content: 'MOM Shop - Software di gestione aziendale tutto-in-uno basato su AI' },
    { name: 'twitter:description', content: 'Scopri MOM Shop: un ERP flessibile con gestione ordini, cloud POS, HRMS, contabilità, marketing e consegna per tutte le aziende.' },
  ]
},

{
  title: 'MOM Shop - Software de Gestão Empresarial Tudo-em-um Potenciado por IA',
  description: 'Descubra o MOM Shop: um ERP flexível com gestão de pedidos, POS em nuvem, HRMS, contabilidade, marketing e entrega para todos os negócios.',
  canonical: `${appUrl}/pt/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/pt/shop` },
    { property: 'og:title', content: 'MOM Shop - Software de Gestão Empresarial Tudo-em-um Potenciado por IA' },
    { property: 'og:description', content: 'Descubra o MOM Shop: um ERP flexível com gestão de pedidos, POS em nuvem, HRMS, contabilidade, marketing e entrega para todos os negócios.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/pt/shop` },
    { name: 'twitter:title', content: 'MOM Shop - Software de Gestão Empresarial Tudo-em-um Potenciado por IA' },
    { name: 'twitter:description', content: 'Descubra o MOM Shop: um ERP flexível com gestão de pedidos, POS em nuvem, HRMS, contabilidade, marketing e entrega para todos os negócios.' },
  ]
},

{
  title: 'MOM Shop - Logiciel de gestion d\'entreprise tout-en-un IA',
  description: 'Découvrez MOM Shop : un ERP flexible avec gestion des commandes, POS cloud, HRMS, comptabilité, marketing et livraison pour toutes les entreprises.',
  canonical: `${appUrl}/fr/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/fr/shop` },
    { property: 'og:title', content: 'MOM Shop - Logiciel de gestion d\'entreprise tout-en-un IA' },
    { property: 'og:description', content: 'Découvrez MOM Shop : un ERP flexible avec gestion des commandes, POS cloud, HRMS, comptabilité, marketing et livraison pour toutes les entreprises.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/fr/shop` },
    { name: 'twitter:title', content: 'MOM Shop - Logiciel de gestion d\'entreprise tout-en-un IA' },
    { name: 'twitter:description', content: 'Découvrez MOM Shop : un ERP flexible avec gestion des commandes, POS cloud, HRMS, comptabilité, marketing et livraison pour toutes les entreprises.' },
  ]
},

{
    title: 'MOM Shop - ซอฟต์แวร์จัดการธุรกิจครบวงจรที่ขับเคลื่อนด้วย AI',
    description: 'ค้นพบ MOM Shop: โซลูชัน ERP ที่ยืดหยุ่นพร้อมการจัดการคำสั่งซื้อแบบบูรณาการ, POS คลาวด์, HRMS, การบัญชี, การตลาด และการจัดการการจัดส่งสำหรับธุรกิจทุกประเภท。.',
    canonical: `${appUrl}/th/shop`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/th/shop` },
      { property: 'og:title', content: 'MOM Shop - ซอฟต์แวร์จัดการธุรกิจครบวงจรที่ขับเคลื่อนด้วย AI' },
      { property: 'og:description', content: 'ค้นพบ MOM Shop: โซลูชัน ERP ที่ยืดหยุ่นพร้อมการจัดการคำสั่งซื้อแบบบูรณาการ, POS คลาวด์, HRMS, การบัญชี, การตลาด และการจัดการการจัดส่งสำหรับธุรกิจทุกประเภท。.' }
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/th/shop` },
      { name: 'twitter:title', content: 'MOM Shop - ซอฟต์แวร์จัดการธุรกิจครบวงจรที่ขับเคลื่อนด้วย AI' },
      { name: 'twitter:description', content: 'ค้นพบ MOM Shop: โซลูชัน ERP ที่ยืดหยุ่นพร้อมการจัดการคำสั่งซื้อแบบบูรณาการ, POS คลาวด์, HRMS, การบัญชี, การตลาด และการจัดการการจัดส่งสำหรับธุรกิจทุกประเภท。.' }
    ]
  },

  {
    title: 'مركز MOM - برنامج إدارة الأعمال المتكامل المدعوم بالذكاء الاصطناعي',
    description: '"اكتشف MOM Shop: حل ERP مرن مع إدارة الطلبات المتكاملة ونقاط البيع السحابية ونظام إدارة الموارد البشرية والمحاسبة والتسويق وإدارة التوصيل لجميع الأعمال',
    canonical: `${appUrl}/ar/shop`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/ar/shop` },
      { property: 'og:title', content: 'مركز MOM - برنامج إدارة الأعمال المتكامل المدعوم بالذكاء الاصطناعي' },
      { property: 'og:description', content: '"اكتشف MOM Shop: حل ERP مرن مع إدارة الطلبات المتكاملة ونقاط البيع السحابية ونظام إدارة الموارد البشرية والمحاسبة والتسويق وإدارة التوصيل لجميع الأعمال' }
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/ar/shop` },
      { name: 'twitter:title', content: 'مركز MOM - برنامج إدارة الأعمال المتكامل المدعوم بالذكاء الاصطناعي' },
      { name: 'twitter:description', content: '"اكتشف MOM Shop: حل ERP مرن مع إدارة الطلبات المتكاملة ونقاط البيع السحابية ونظام إدارة الموارد البشرية والمحاسبة والتسويق وإدارة التوصيل لجميع الأعمال' }
    ]
  },

{
  title: 'MOM Shop - Программное обеспечение для управления бизнесом на основе ИИ',
  description: 'Откройте MOM Shop: гибкое ERP с управлением заказами, облачным POS, HRMS, бухгалтерией, маркетингом и доставкой для всех бизнесов.',
  canonical: `${appUrl}/ru/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ru/shop` },
    { property: 'og:title', content: 'MOM Shop - Программное обеспечение для управления бизнесом на основе ИИ' },
    { property: 'og:description', content: 'Откройте MOM Shop: гибкое ERP с управлением заказами, облачным POS, HRMS, бухгалтерией, маркетингом и доставкой для всех бизнесов.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/ru/shop` },
    { name: 'twitter:title', content: 'MOM Shop - Программное обеспечение для управления бизнесом на основе ИИ' },
    { name: 'twitter:description', content: 'Откройте MOM Shop: гибкое ERP с управлением заказами, облачным POS, HRMS, бухгалтерией, маркетингом и доставкой для всех бизнесов.' },
  ]
},

{
  title: 'MOM شاپ - AI پر مبنی مکمل کاروباری مینجمنٹ سافٹ ویئر',
  description: 'MOM شاپ دریافت کریں: ایک لچکدار ERP حل جس میں ایک مربوط آرڈر مینجمنٹ، کلاؤڈ POS، HRMS، اکاؤنٹنگ، مارکیٹنگ، اور تمام کاروباروں کے لیے ڈلیوری مینجمنٹ شامل ہے۔',
  canonical: `${appUrl}/ur/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ur/shop` },
    { property: 'og:title', content: 'MOM شاپ - AI پر مبنی مکمل کاروباری مینجمنٹ سافٹ ویئر' },
    { property: 'og:description', content: 'MOM شاپ دریافت کریں: ایک لچکدار ERP حل جس میں ایک مربوط آرڈر مینجمنٹ، کلاؤڈ POS، HRMS، اکاؤنٹنگ، مارکیٹنگ، اور تمام کاروباروں کے لیے ڈلیوری مینجمنٹ شامل ہے۔' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/ur/shop` },
    { name: 'twitter:title', content: 'MOM شاپ - AI پر مبنی مکمل کاروباری مینجمنٹ سافٹ ویئر' },
    { name: 'twitter:description', content: 'MOM شاپ دریافت کریں: ایک لچکدار ERP حل جس میں ایک مربوط آرڈر مینجمنٹ، کلاؤڈ POS، HRMS، اکاؤنٹنگ، مارکیٹنگ، اور تمام کاروباروں کے لیے ڈلیوری مینجمنٹ شامل ہے۔' },
  ]
},

{
  title: 'MOM கடை - AI சக்தி கொண்ட ஒருங்கிணைந்த வணிக மேலாண்மை மென்பொருள்',
  description: 'மோம் ஷாப்: அனைத்து தொழில்களுக்கு பொருத்தமான ஆர்டர் மேலாண்மை, கிளவுட் POS, HRMS, கணக்கீடு, சந்தை மற்றும் விநியோகம் கொண்ட ஒரு வாய்ப்பு ERP.',
  canonical: `${appUrl}/ta/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ta/shop` },
    { property: 'og:title', content: 'MOM கடை - AI சக்தி கொண்ட ஒருங்கிணைந்த வணிக மேலாண்மை மென்பொருள்' },
    { property: 'og:description', content: 'மோம் ஷாப்: அனைத்து தொழில்களுக்கு பொருத்தமான ஆர்டர் மேலாண்மை, கிளவுட் POS, HRMS, கணக்கீடு, சந்தை மற்றும் விநியோகம் கொண்ட ஒரு வாய்ப்பு ERP.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/ta/shop` },
    { name: 'twitter:title', content: 'MOM கடை - AI சக்தி கொண்ட ஒருங்கிணைந்த வணிக மேலாண்மை மென்பொருள்' },
    { name: 'twitter:description', content: 'மோம் ஷாப்: அனைத்து தொழில்களுக்கு பொருத்தமான ஆர்டர் மேலாண்மை, கிளவுட் POS, HRMS, கணக்கீடு, சந்தை மற்றும் விநியோகம் கொண்ட ஒரு வாய்ப்பு ERP.' },
  ]
},

{
  title: 'MOM 商店 - AI 驱动的一体化业务管理软件',
  description: '发现 MOM Shop：一种灵活的 ERP 解决方案，具有集成的订单管理、云 POS、HRMS、会计、营销和适用于所有企业的交付管理。。',
  canonical: `${appUrl}/zh/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/zh/shop` },
    { property: 'og:title', content: 'MOM 商店 - AI 驱动的一体化业务管理软件' },
    { property: 'og:description', content: '发现 MOM Shop：一种灵活的 ERP 解决方案，具有集成的订单管理、云 POS、HRMS、会计、营销和适用于所有企业的交付管理。。' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/zh/shop` },
    { name: 'twitter:title', content: 'MOM 商店 - AI 驱动的一体化业务管理软件' },
    { name: 'twitter:description', content: '发现 MOM Shop：一种灵活的 ERP 解决方案，具有集成的订单管理、云 POS、HRMS、会计、营销和适用于所有企业的交付管理。。' },
  ]
},

{
  title: 'MOM शॉप - AI-संचालित ऑल-इन-वन बिजनेस मैनेजमेंट सॉफ़्टवेयर',
  description: 'MOM - अल्टीमेट AI संचालित मार्केटप्लेस पर कुशल सेवा प्रदाताओं के नेटवर्क से जुड़ें और अपनी आवश्यकताओं के लिए सही पेशेवर ढूंढें।',
  canonical: `${appUrl}/hi/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/hi/provider` },
    { property: 'og:title', content: 'सेवा प्रदाता - MOM - अल्टीमेट AI संचालित मार्केटप्लेस' },
    { property: 'og:description', content: 'MOM - अल्टीमेट AI संचालित मार्केटप्लेस पर कुशल सेवा प्रदाताओं के नेटवर्क से जुड़ें और अपनी आवश्यकताओं के लिए सही पेशेवर ढूंढें।' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/hi/provider` },
    { name: 'twitter:title', content: 'सेवा प्रदाता - MOM - अल्टीमेट AI संचालित मार्केटप्लेस' },
    { name: 'twitter:description', content: 'MOM - अल्टीमेट AI संचालित मार्केटप्लेस पर कुशल सेवा प्रदाताओं के नेटवर्क से जुड़ें और अपनी आवश्यकताओं के लिए सही पेशेवर ढूंढें।' },
  ]
},

  {
  title: 'Terms and Conditions - MOM - Ultimate AI powered Marketplace',
  description: 'Read the terms and conditions to understand the legal agreements related to using the services on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/terms-and-condition`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/terms-and-condition` },
    { property: 'og:title', content: 'Terms and Conditions - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Read the terms and conditions to understand the legal agreements related to using the services on the MOM - Ultimate AI powered Marketplace.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/terms-and-condition` },
    { name: 'twitter:title', content: 'Terms and Conditions - MOM - Ultimate AI powered Marketplace' },
    { name: 'twitter:description', content: 'Read the terms and conditions to understand the legal agreements related to using the services on the MOM - Ultimate AI powered Marketplace.' },
  ]
},
{
  title: 'About Us - MOM - Ultimate AI powered Marketplace',
  description: 'Learn more about the MOM - Ultimate AI powered Marketplace, our mission, values, and the team dedicated to providing you with the best experience.',
  canonical: `${appUrl}/about-us`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/about-us` },
    { property: 'og:title', content: 'About Us - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Learn more about the MOM - Ultimate AI powered Marketplace, our mission, values, and the team dedicated to providing you with the best experience.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/about-us` },
    { name: 'twitter:title', content: 'About Us - MOM - Ultimate AI powered Marketplace' },
    { name: 'twitter:description', content: 'Learn more about the MOM - Ultimate AI powered Marketplace, our mission, values, and the team dedicated to providing you with the best experience.' },
  ]
},
{
  title: 'Contact Us - Reach MOM AI Marketplace Support',
  description: 'Have questions or need assistance? Reach out to the MOM - Ultimate AI powered Marketplace team via our contact page for support.',
  canonical: `${appUrl}/contact-us`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/contact-us` },
    { property: 'og:title', content: 'Contact Us - Reach MOM AI Marketplace Support' },
    { property: 'og:description', content: 'Have questions or need assistance? Reach out to the MOM - Ultimate AI powered Marketplace team via our contact page for support.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/contact-us` },
    { name: 'twitter:title', content: 'Contact Us - Reach MOM AI Marketplace Support' },
    { name: 'twitter:description', content: 'Have questions or need assistance? Reach out to the MOM - Ultimate AI powered Marketplace team via our contact page for support.' },
  ]
},
{
  title: 'FAQ - Common Questions on MOM AI Powered Marketplace',
  description: 'Find answers to frequently asked questions about using the MOM - Ultimate AI powered Marketplace, orders, services, and account management.',
  canonical: `${appUrl}/faq`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/faq` },
    { property: 'og:title', content: 'FAQ - Common Questions on MOM AI Powered Marketplace' },
    { property: 'og:description', content: 'Find answers to frequently asked questions about using the MOM - Ultimate AI powered Marketplace, orders, services, and account management.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/faq` },
    { name: 'twitter:title', content: 'FAQ - Common Questions on MOM AI Powered Marketplace' },
    { name: 'twitter:description', content: 'Find answers to frequently asked questions about using the MOM - Ultimate AI powered Marketplace, orders, services, and account management.' },
  ]
},
{
  title: 'Knowledge Base - Explore MOM AI Powered Marketplace Services',
  description: 'Access our comprehensive knowledge base for in-depth articles, guides, and helpful resources about the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/knowledge-base`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/knowledge-base` },
    { property: 'og:title', content: 'Knowledge Base - Explore MOM AI Powered Marketplace Services' },
    { property: 'og:description', content: 'Access our comprehensive knowledge base for in-depth articles, guides, and helpful resources about the MOM - Ultimate AI powered Marketplace.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/knowledge-base` },
    { name: 'twitter:title', content: 'Knowledge Base - Explore MOM AI Powered Marketplace Services' },
    { name: 'twitter:description', content: 'Access our comprehensive knowledge base for in-depth articles, guides, and helpful resources about the MOM - Ultimate AI powered Marketplace.' },
  ]
},
{
  title: 'Blog - News, Tips, and Stories from MOM AI Marketplace',
  description: 'Dive into the MOM - Ultimate AI powered Marketplace blog for the latest updates, insights, and stories to enhance your shopping and service experience.',
  canonical: `${appUrl}/blog`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog` },
    { property: 'og:title', content: 'Blog - News, Tips, and Stories from MOM AI Marketplace' },
    { property: 'og:description', content: 'Dive into the MOM - Ultimate AI powered Marketplace blog for the latest updates, insights, and stories to enhance your shopping and service experience.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/blog` },
    { name: 'twitter:title', content: 'Blog - News, Tips, and Stories from MOM AI Marketplace' },
    { name: 'twitter:description', content: 'Dive into the MOM - Ultimate AI powered Marketplace blog for the latest updates, insights, and stories to enhance your shopping and service experience.' },
  ]
},
{
  title: 'Error 404 - Page Not Found on MOM - Ultimate AI powered Marketplace',
  description: 'The page you are trying to access cannot be found. Use our navigation to get back on track on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/error-404`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/error-404` },
    { property: 'og:title', content: 'Error 404 - Page Not Found on MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'The page you are trying to access cannot be found. Use our navigation to get back on track on the MOM - Ultimate AI powered Marketplace.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/error-404` },
    { name: 'twitter:title', content: 'Error 404 - Page Not Found on MOM - Ultimate AI powered Marketplace' },
    { name: 'twitter:description', content: 'The page you are trying to access cannot be found. Use our navigation to get back on track on the MOM - Ultimate AI powered Marketplace.' },
  ]
},
{
  title: 'Boost Your Income Through the MOM Affiliate Program - MOM',
  description: 'Learn how to increase your earnings by joining the MOM Affiliate Program with this detailed guide.',
  canonical: `${appUrl}/blog/boost-your-income-through-the-mom-affiliate-program/54`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/boost-your-income-through-the-mom-affiliate-program/54` },
    { property: 'og:title', content: 'Boost Your Income Through the MOM Affiliate Program - MOM' },
    { property: 'og:description', content: 'Learn how to increase your earnings by joining the MOM Affiliate Program with this detailed guide.' },
  ],
  twitter: [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: `${appUrl}/blog/boost-your-income-through-the-mom-affiliate-program/54` },
    { name: 'twitter:title', content: 'Boost Your Income Through the MOM Affiliate Program - MOM' },
    { name: 'twitter:description', content: 'Learn how to increase your earnings by joining the MOM Affiliate Program with this detailed guide.' },
  ]
},

{
  title: 'How to Start an Online Clothing Store: Your Comprehensive Guide - MOM',
  description: 'Discover all the steps and tips needed to start your own online clothing store with own online clothing store with this comprehensive guide.',
  canonical: `${appUrl}/blog/how-to-start-an-online-clothing-store-your-comprehensive-guide/28`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-start-an-online-clothing-store-your-comprehensive-guide/28` },
      { property: 'og:title', content: 'How to Start an Online Clothing Store: Your Comprehensive Guide - MOM' },
      { property: 'og:description', content: 'Discover all the steps and tips needed to start your own online clothing store with this comprehensive guide.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-start-an-online-clothing-store-your-comprehensive-guide/28` },
      { name: 'twitter:title', content: 'How to Start an Online Clothing Store: Your Comprehensive Guide - MOM' },
      { name: 'twitter:description', content: 'Discover all the steps and tips needed to start your own online clothing store with this comprehensive guide.' },
    ]
  },
  {
    title: 'How to Create a Digital Store in Less Than a Minute - MOM',
    description: 'Learn the fastest way to set up a digital store using MOMs AI-powered tools.',
    canonical: `${appUrl}/blog/how-to-create-a-digital-store-in-less-than-a-minute/7`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-create-a-digital-store-in-less-than-a-minute/7` },
      { property: 'og:title', content: 'How to Create a Digital Store in Less Than a Minute - MOM' },
      { property: 'og:description', content: 'Learn the fastest way to set up a digital store using MOMs AI-powered tools.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-create-a-digital-store-in-less-than-a-minute/7` },
      { name: 'twitter:title', content: 'How to Create a Digital Store in Less Than a Minute - MOM' },
      { name: 'twitter:description', content: 'Learn the fastest way to set up a digital store using MOMs AI-powered tools.' },
    ]
  },
  {
    title: 'MOM AI Knowledge Base - MOM',
    description: 'Explore comprehensive articles and guides on how to utilize MOM AI.',
    canonical: `${appUrl}/knowledge-base/category/mom-ai/1`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/knowledge-base/category/mom-ai/1` },
      { property: 'og:title', content: 'MOM AI Knowledge Base - MOM' },
      { property: 'og:description', content: 'Explore comprehensive articles and guides on how to utilize MOM AI.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/knowledge-base/category/mom-ai/1` },
      { name: 'twitter:title', content: 'MOM AI Knowledge Base - MOM' },
      { name: 'twitter:description', content: 'Explore comprehensive articles and guides on how to utilize MOM AI.' },
    ]
  },
  {
    title: 'How to Create an Order in POS - MOM',
    description: 'Step-by-step guide on creating an order in MOM POS system.',
    canonical: `${appUrl}/knowledge-base/details/how-to-create-an-order-in-pos/2/2`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/knowledge-base/details/how-to-create-an-order-in-pos/2/2` },
      { property: 'og:title', content: 'How to Create an Order in POS - MOM' },
      { property: 'og:description', content: 'Step-by-step guide on creating an order in MOM POS system.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/knowledge-base/details/how-to-create-an-order-in-pos/2/2` },
      { name: 'twitter:title', content: 'How to Create an Order in POS - MOM' },
      { name: 'twitter:description', content: 'Step-by-step guide on creating an order in MOM POS system.' },
    ]
  },
  {
    title: 'MOM Shop Knowledge Base - MOM',
    description: 'Find all the guidance and articles related to MOM Shop in this category.',
    canonical: `${appUrl}/knowledge-base/category/mom-shop/2`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/knowledge-base/category/mom-shop/2` },
      { property: 'og:title', content: 'MOM Shop Knowledge Base - MOM' },
      { property: 'og:description', content: 'Find all the guidance and articles related to MOM Shop in this category.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/knowledge-base/category/mom-shop/2` },
      { name: 'twitter:title', content: 'MOM Shop Knowledge Base - MOM' },
      { name: 'twitter:description', content: 'Find all the guidance and articles related to MOM Shop in this category.' },
    ]
  },
  
  {
    title: 'MOM Provider Knowledge Base - MOM',
    description: 'Explore articles and guides designed specifically for MOM Providers.',
    canonical: `${appUrl}/knowledge-base/category/mom-provider/3`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/knowledge-base/category/mom-provider/3` },
      { property: 'og:title', content: 'MOM Provider Knowledge Base - MOM' },
      { property: 'og:description', content: 'Explore articles and guides designed specifically for MOM Providers.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/knowledge-base/category/mom-provider/3` },
      { name: 'twitter:title', content: 'MOM Provider Knowledge Base - MOM' },
      { name: 'twitter:description', content: 'Explore articles and guides designed specifically for MOM Providers.' },
    ]
  },
  {
    title: 'How to Create Your Own Podcast: A Step-by-Step Guide - MOM',
    description: 'Comprehensive guide to creating, launching, and marketing your own podcast.',
    canonical: `${appUrl}/blog/how-to-create-your-own-podcast-a-step-by-step-guide/55`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/blog/how-to-create-your-own-podcast-a-step-by-step-guide/55` },
      { property: 'og:title', content: 'How to Create Your Own Podcast: A Step-by-Step Guide - MOM' },
      { property: 'og:description', content: 'Comprehensive guide to creating, launching, and marketing your own podcast.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/blog/how-to-create-your-own-podcast-a-step-by-step-guide/55` },
      { name: 'twitter:title', content: 'How to Create Your Own Podcast: A Step-by-Step Guide - MOM' },
      { name: 'twitter:description', content: 'Comprehensive guide to creating, launching, and marketing your own podcast.' },
    ]
  },
  {
    title: 'How to Use MOM AI Chat - MOM',
    description: 'Detailed instructions on how to effectively use MOM AI Chat for better communication.',
    canonical: `${appUrl}/knowledge-base/details/how-to-use-mom-ai-chat/1/5`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/knowledge-base/details/how-to-use-mom-ai-chat/1/5` },
      { property: 'og:title', content: 'How to Use MOM AI Chat - MOM' },
      { property: 'og:description', content: 'Detailed instructions on how to effectively use MOM AI Chat for better communication.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/knowledge-base/details/how-to-use-mom-ai-chat/1/5` },
      { name: 'twitter:title', content: 'How to Use MOM AI Chat - MOM' },
      { name: 'twitter:description', content: 'Detailed instructions on how to effectively use MOM AI Chat for better communication.' },
    ]
  },
  {
    title: 'Terms and Conditions - The Emporium at Hawk Springs - MOM',
    description: 'Explore the terms and conditions for The Emporium at Hawk Springs on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/user/store/terms-conditions/the-emporium-at-hawk-springs/187`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/store/terms-conditions/the-emporium-at-hawk-springs/187` },
      { property: 'og:title', content: 'Terms and Conditions - The Emporium at Hawk Springs - MOM' },
      { property: 'og:description', content: 'Explore the terms and conditions for The Emporium at Hawk Springs on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/store/terms-conditions/the-emporium-at-hawk-springs/187` },
      { name: 'twitter:title', content: 'Terms and Conditions - The Emporium at Hawk Springs - MOM' },
      { name: 'twitter:description', content: 'Explore the terms and conditions for The Emporium at Hawk Springs on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'Terms and Conditions - Golden Dragon Chinese Restaurant - MOM',
    description: 'Discover the terms and conditions for Golden Dragon Chinese Restaurant on MOM - Ultimate AI powered Marketplace.',
    canonical: `${appUrl}/user/store/terms-conditions/golden-dragon-chinese-restaurant/115`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/store/terms-conditions/golden-dragon-chinese-restaurant/115` },
      { property: 'og:title', content: 'Terms and Conditions - Golden Dragon Chinese Restaurant - MOM' },
      { property: 'og:description', content: 'Discover the terms and conditions for Golden Dragon Chinese Restaurant on MOM - Ultimate AI powered Marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/store/terms-conditions/golden-dragon-chinese-restaurant/115` },
      { name: 'twitter:title', content: 'Terms and Conditions - Golden Dragon Chinese Restaurant - MOM' },
      { name: 'twitter:description', content: 'Discover the terms and conditions for Golden Dragon Chinese Restaurant on MOM - Ultimate AI powered Marketplace.' },
    ]
  },
  {
    title: 'MOM Shop - Ultimate AI powered Marketplace',
    description: 'Visit MOM Shop, the ultimate AI-powered marketplace that revolutionizes the way businesses sell online.',
    canonical: `${appUrl}/shop`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/shop` },
      { property: 'og:title', content: 'MOM Shop - Ultimate AI powered Marketplace' },
      { property: 'og:description', content: 'Visit MOM Shop, the ultimate AI-powered marketplace that revolutionizes the way businesses sell online.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/shop` },
      { name: 'twitter:title', content: 'MOM Shop - Ultimate AI powered Marketplace' },
      { name: 'twitter:description', content: 'Visit MOM Shop, the ultimate AI-powered marketplace that revolutionizes the way businesses sell online.' },
    ]
  },
  {
    title: 'MOM - The Ultimate AI Powered Marketplace',
    description: 'Explore MOM, the ultimate AI-powered marketplace where businesses grow online effortlessly with powerful tools and services.',
    canonical: `${appUrl}/`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/` },
      { property: 'og:title', content: 'MOM - The Ultimate AI Powered Marketplace' },
      { property: 'og:description', content: 'Explore MOM, the ultimate AI-powered marketplace where businesses grow online effortlessly with powerful tools and services.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/` },
      { name: 'twitter:title', content: 'MOM - The Ultimate AI Powered Marketplace' },
      { name: 'twitter:description', content: 'Explore MOM, the ultimate AI-powered marketplace where businesses grow online effortlessly with powerful tools and services.' },
    ]
  },
  {
    title: 'MOM Provider - Manage Services with AI Power',
    description: 'Discover MOM Provider, an AI-powered platform designed to help you manage and grow your service-based business efficiently.',
    canonical: `${appUrl}/provider`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/provider` },
      { property: 'og:title', content: 'MOM Provider - Manage Services with AI Power' },
      { property: 'og:description', content: 'Discover MOM Provider, an AI-powered platform designed to help you manage and grow your service-based business efficiently.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/provider` },
      { name: 'twitter:title', content: 'MOM Provider - Manage Services with AI Power' },
      { name: 'twitter:description', content: 'Discover MOM Provider, an AI-powered platform designed to help you manage and grow your service-based business efficiently.' },
    ]
  },
  {
    title: 'MOM User Portal - Login',
    description: 'Access the MOM user portal to manage your account, orders, and more on the ultimate AI-powered marketplace.',
    canonical: `${appUrl}/user`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user` },
      { property: 'og:title', content: 'MOM User Portal - Login' },
      { property: 'og:description', content: 'Access the MOM user portal to manage your account, orders, and more on the ultimate AI-powered marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user` },
      { name: 'twitter:title', content: 'MOM User Portal - Login' },
      { name: 'twitter:description', content: 'Access the MOM user portal to manage your account, orders, and more on the ultimate AI-powered marketplace.' },
    ]
  },
  {
    title: 'Login to MOM to Sell Shoes Online',
    description: 'Learn how to sell shoes online through MOMs comprehensive platform, the ultimate AI-powered marketplace.',
    canonical: `${appUrl}/user/login?returnUrl=%2Fblog%2Fhow-to-sell-shoes-online-in-%2F35`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fhow-to-sell-shoes-online-in-%2F35` },
      { property: 'og:title', content: 'Login to MOM to Sell Shoes Online' },
      { property: 'og:description', content: 'Learn how to sell shoes online through MOMs comprehensive platform, the ultimate AI-powered marketplace.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fhow-to-sell-shoes-online-in-%2F35` },
      { name: 'twitter:title', content: 'Login to MOM to Sell Shoes Online' },
      { name: 'twitter:description', content: 'Learn how to sell shoes online through MOMs comprehensive platform, the ultimate AI-powered marketplace.' },
    ]
  },
  {
    title: 'Login to MOM to Boost Your Income through the Affiliate Program',
    description: 'Discover how MOMs affiliate program can boost your income, providing a hassle-free way to earn more.',
    canonical: `${appUrl}/user/login?returnUrl=%2Fblog%2Fboost-your-income-through-the-mom-affiliate-program%2F54`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fboost-your-income-through-the-mom-affiliate-program%2F54` },
      { property: 'og:title', content: 'Login to MOM to Boost Your Income through the Affiliate Program' },
      { property: 'og:description', content: 'Discover how MOMs affiliate program can boost your income, providing a hassle-free way to earn more.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fboost-your-income-through-the-mom-affiliate-program%2F54` },
      { name: 'twitter:title', content: 'Login to MOM to Boost Your Income through the Affiliate Program' },
      { name: 'twitter:description', content: 'Discover how MOMs affiliate program can boost your income, providing a hassle-free way to earn more.' },
    ]
  },
  {
    title: 'Login to MOM to Sell Courses Online',
    description: 'Learn how to sell courses online with MOMs platform designed to support educators and creators with powerful AI tools.',
    canonical: `${appUrl}/user/login?returnUrl=%2Fblog%2Fhow-to-sell-courses-online-a-comprehensive-guide%2F21`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fhow-to-sell-courses-online-a-comprehensive-guide%2F21` },
      { property: 'og:title', content: 'Login to MOM to Sell Courses Online' },
      { property: 'og:description', content: 'Learn how to sell courses online with MOMs platform designed to support educators and creators with powerful AI tools.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fhow-to-sell-courses-online-a-comprehensive-guide%2F21` },
      { name: 'twitter:title', content: 'Login to MOM to Sell Courses Online' },
      { name: 'twitter:description', content: 'Learn how to sell courses online with MOMs platform designed to support educators and creators with powerful AI tools.' },
    ]
  },
  {
    title: 'Login to MOM to Create a Digital Store in Less than a Minute',
    description: 'MOM makes it possible to create a digital store in less than a minute with its AI-powered tools for business success.',
    canonical: `${appUrl}/user/login?returnUrl=%2Fblog%2Fhow-to-create-a-digital-store-in-less-than-a-minute%2F7`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fhow-to-create-a-digital-store-in-less-than-a-minute%2F7` },
      { property: 'og:title', content: 'Login to MOM to Create a Digital Store in Less than a Minute' },
      { property: 'og:description', content: 'MOM makes it possible to create a digital store in less than a minute with its AI-powered tools for business success.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fhow-to-create-a-digital-store-in-less-than-a-minute%2F7` },
      { name: 'twitter:title', content: 'Login to MOM to Create a Digital Store in Less than a Minute' },
      { name: 'twitter:description', content: 'MOM makes it possible to create a digital store in less than a minute with its AI-powered tools for business success.' },
    ]
  },
  {
    title: 'Login to MOM to Explore AIs Capabilities in Curing Cancer, Predicting Stocks, and More',
    description: 'Investigate whether AI can cure cancer, predict stocks, and other significant tasks on MOMs AI-powered platform.',
    canonical: `${appUrl}/user/login?returnUrl=%2Fblog%2Fcan-ai-cure-cancer-predict-stocks-or-write-books-exploring-the-limits-of-ai%2F53`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fcan-ai-cure-cancer-predict-stocks-or-write-books-exploring-the-limits-of-ai%2F53` },
      { property: 'og:title', content: 'Login to MOM to Explore AIs Capabilities in Curing Cancer, Predicting Stocks, and More' },
      { property: 'og:description', content: 'Investigate whether AI can cure cancer, predict stocks, and other significant tasks on MOMs AI-powered platform.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fcan-ai-cure-cancer-predict-stocks-or-write-books-exploring-the-limits-of-ai%2F53` },
      { name: 'twitter:title', content: 'Login to MOM to Explore AIs Capabilities in Curing Cancer, Predicting Stocks, and More' },
      { name: 'twitter:description', content: 'Investigate whether AI can cure cancer, predict stocks, and other significant tasks on MOMs AI-powered platform.' },
    ]
  },
  {
    title: 'Login to MOM for AI Trading Bots Review - Legality and Functionality',
    description: 'Explore the world of AI trading bots: are they legal, and do they work? Discover insights on MOMs blog.',
    canonical: `${appUrl}/user/login?returnUrl=%2Fblog%2Fai-trading-bots-are-they-legal-and-do-they-work%2F12`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fai-trading-bots-are-they-legal-and-do-they-work%2F12` },
      { property: 'og:title', content: 'Login to MOM for AI Trading Bots Review - Legality and Functionality' },
      { property: 'og:description', content: 'Explore the world of AI trading bots: are they legal, and do they work? Discover insights on MOMs blog.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/login?returnUrl=%2Fblog%2Fai-trading-bots-are-they-legal-and-do-they-work%2F12` },
      { name: 'twitter:title', content: 'Login to MOM for AI Trading Bots Review - Legality and Functionality' },
      { name: 'twitter:description', content: 'Explore the world of AI trading bots: are they legal, and do they work? Discover insights on MOMs blog.' },
    ]
  },
  
  {
    title: 'Explore Terms and Conditions for My Digital Store on MOM',
    description: 'View the terms and conditions for your digital store on MOM, the ultimate AI-powered platform for managing your business online.',
    canonical: `${appUrl}/user/store/terms-conditions/my-digital-store/156`,
    og: [
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `${appUrl}/user/store/terms-conditions/my-digital-store/156` },
      { property: 'og:title', content: 'Explore Terms and Conditions for My Digital Store on MOM' },
      { property: 'og:description', content: 'View the terms and conditions for your digital store on MOM, the ultimate AI-powered platform for managing your business online.' },
    ],
    twitter: [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:url', content: `${appUrl}/user/store/terms-conditions/my-digital-store/156` },
      { name: 'twitter:title', content: 'Explore Terms and Conditions for My Digital Store on MOM' },
      { name: 'twitter:description', content: 'View the terms and conditions for your digital store on MOM, the ultimate AI-powered platform for managing your business online.' },
    ]
  },
  {
      title: 'How to Create a Digital Store in Less Than a Minute - MOM AI Blog',
      description: 'Learn how to create your own digital store in under a minute using MOM AI. Simple steps to grow your business online!',
      canonical: `${appUrl}/blog/how-to-create-a-digital-store-in-less-than-an-minute/7`,
      og: [
        { property: 'og:type', content: 'article' },
        { property: 'og:url', content: `${appUrl}/blog/how-to-create-a-digital-store-in-less-than-an-minute/7` },
        { property: 'og:title', content: 'How to Create a Digital Store in Less Than a Minute - MOM AI Blog' },
        { property: 'og:description', content: 'Learn how to create your own digital store in under a minute using MOM AI. Simple steps to grow your business online!' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/blog/how-to-create-a-digital-store-in-less-than-an-minute/7` },
        { name: 'twitter:title', content: 'How to Create a Digital Store in Less Than a Minute - MOM AI Blog' },
        { name: 'twitter:description', content: 'Learn how to create your own digital store in under a minute using MOM AI. Simple steps to grow your business online!' },
      ]
    },
    
    {
      title: 'MOM AI Affiliate Program - Earn with MOM',
      description: 'Join the MOM AI Affiliate Program! Promote innovative AI solutions, share cutting-edge tech, and earn great commissions while empowering others.',
      canonical: `${appUrl}/en/affiliate`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/en/affiliate` },
        { property: 'og:title', content: 'MOM AI Affiliate Program - Earn with MOM' },
        { property: 'og:description', content: 'Join the MOM AI Affiliate Program! Promote innovative AI solutions, share cutting-edge tech, and earn great commissions while empowering others.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/en/affiliate` },
        { name: 'twitter:title', content: 'MOM AI Affiliate Program - Earn with MOM' },
        { name: 'twitter:description', content: 'Join the MOM AI Affiliate Program! Promote innovative AI solutions, share cutting-edge tech, and earn great commissions while empowering others.' },
      ]
    },
  
      {
      title: 'MOM AI एफिलिएट प्रोग्राम - MOM के साथ कमाएं',
      description: 'MOM AI एफिलिएट प्रोग्राम में शामिल हों! नवीन एआई समाधानों को बढ़ावा दें, प्रौद्योगिकी साझा करें और शानदार कमीशन कमाएं।',
      canonical: `${appUrl}/hi/affiliate`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/hi/affiliate` },
        { property: 'og:title', content: 'MOM AI एफिलिएट प्रोग्राम - MOM के साथ कमाएं' },
        { property: 'og:description', content: 'MOM AI एफिलिएट प्रोग्राम में शामिल हों! नवीन एआई समाधानों को बढ़ावा दें, प्रौद्योगिकी साझा करें और शानदार कमीशन कमाएं।' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/hi/affiliate` },
        { name: 'twitter:title', content: 'MOM AI एफिलिएट प्रोग्राम - MOM के साथ कमाएं' },
        { name: 'twitter:description', content: 'MOM AI एफिलिएट प्रोग्राम में शामिल हों! नवीन एआई समाधानों को बढ़ावा दें, प्रौद्योगिकी साझा करें और शानदार कमीशन कमाएं।' },
      ]
    },
    
    {
      title: 'برنامج الشركاء MOM AI - اربح مع MOM',
      description: 'انضم إلى برنامج الشركاء MOM AI! قم بالترويج للحلول المبتكرة في الذكاء الاصطناعي وشارك التكنولوجيا واربح عمولات رائعة.',
      canonical: `${appUrl}/ar/affiliate`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/ar/affiliate` },
        { property: 'og:title', content: 'برنامج الشركاء MOM AI - اربح مع MOM' },
        { property: 'og:description', content: 'انضم إلى برنامج الشركاء MOM AI! قم بالترويج للحلول المبتكرة في الذكاء الاصطناعي وشارك التكنولوجيا واربح عمولات رائعة.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/ar/affiliate` },
        { name: 'twitter:title', content: 'برنامج الشركاء MOM AI - اربح مع MOM' },
        { name: 'twitter:description', content: 'انضم إلى برنامج الشركاء MOM AI! قم بالترويج للحلول المبتكرة في الذكاء الاصطناعي وشارك التكنولوجيا واربح عمولات رائعة.' },
      ]
    },

    {
      title: 'MOM AI Affiliate-Programm - Verdienen Sie mit MOM',
      description: 'Treten Sie dem MOM AI-Partnerprogramm bei! Bewerben Sie innovative KI-Lösungen, teilen Sie Technologie und verdienen Sie tolle Provisionen.',
      canonical: `${appUrl}/de/affiliate`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/de/affiliate` },
        { property: 'og:title', content: 'MOM AI Affiliate-Programm - Verdienen Sie mit MOM' },
        { property: 'og:description', content: 'Treten Sie dem MOM AI-Partnerprogramm bei! Bewerben Sie innovative KI-Lösungen, teilen Sie Technologie und verdienen Sie tolle Provisionen.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/de/affiliate` },
        { name: 'twitter:title', content: 'MOM AI Affiliate-Programm - Verdienen Sie mit MOM' },
        { name: 'twitter:description', content: 'Treten Sie dem MOM AI-Partnerprogramm bei! Bewerben Sie innovative KI-Lösungen, teilen Sie Technologie und verdienen Sie tolle Provisionen.' },
      ]
    },
    
    {
      title: 'Programa de Afiliados MOM AI - Gana con MOM',
      description: '¡Únete al Programa de Afiliados MOM AI! Promociona soluciones de IA innovadoras, comparte tecnología y gana grandes comisiones.',
      canonical: `${appUrl}/es/affiliate`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/es/affiliate` },
        { property: 'og:title', content: 'Programa de Afiliados MOM AI - Gana con MOM' },
        { property: 'og:description', content: '¡Únete al Programa de Afiliados MOM AI! Promociona soluciones de IA innovadoras, comparte tecnología y gana grandes comisiones.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/es/affiliate` },
        { name: 'twitter:title', content: 'Programa de Afiliados MOM AI - Gana con MOM' },
        { name: 'twitter:description', content: '¡Únete al Programa de Afiliados MOM AI! Promociona soluciones de IA innovadoras, comparte tecnología y gana grandes comisiones.' },
      ]
    },

    {
      title: "Programme d'Affiliation MOM AI - Gagnez avec MOM",
      description: "Rejoignez le programme d'affiliation MOM AI ! Promouvez des solutions IA innovantes, partagez la technologie et gagnez de belles commissions.",
      canonical: `${appUrl}/fr/affiliate`,
      og: [
        { property: "og:type", content: "website" },
        { property: "og:url", content: `${appUrl}/fr/affiliate` },
        { property: "og:title", content: "Programme d'Affiliation MOM AI - Gagnez avec MOM" },
        { property: "og:description", content: "Rejoignez le programme d'affiliation MOM AI ! Promouvez des solutions IA innovantes, partagez la technologie et gagnez de belles commissions." },
      ],
      twitter: [
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:url", content: `${appUrl}/fr/affiliate` },
        { name: "twitter:title", content: "Programme d'Affiliation MOM AI - Gagnez avec MOM" },
        { name: "twitter:description", content: "Rejoignez le programme d'affiliation MOM AI ! Promouvez des solutions IA innovantes, partagez la technologie et gagnez de belles commissions." },
      ]
    },
        
    {
      title: 'Партнёрская программа MOM AI - Зарабатывайте с MOM',
      description: 'Присоединяйтесь к партнерской программе MOM AI! Продвигайте инновационные решения ИИ и получайте отличные комиссии, делясь технологиями.',
      canonical: `${appUrl}/ru/affiliate`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/ru/affiliate` },
        { property: 'og:title', content: 'Партнёрская программа MOM AI - Зарабатывайте с MOM' },
        { property: 'og:description', content: 'Присоединяйтесь к партнерской программе MOM AI! Продвигайте инновационные решения ИИ и получайте отличные комиссии, делясь технологиями.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/ru/affiliate` },
        { name: 'twitter:title', content: 'Партнёрская программа MOM AI - Зарабатывайте с MOM' },
        { name: 'twitter:description', content: 'Присоединяйтесь к партнерской программе MOM AI! Продвигайте инновационные решения ИИ и получайте отличные комиссии, делясь технологиями.' },
      ]
    },
    
    {
      title: 'MOM AI Hamkorlik Dasturi - MOM bilan Ishlaymiz',
      description: 'MOM AI hamkorlik dasturiga qo\'shiling! Innovatsion AI yechimlarini targ\'ib qiling va yaxshi komissiyalar oling.',
      canonical: `${appUrl}/uz/affiliate`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/uz/affiliate` },
        { property: 'og:title', content: 'MOM AI Hamkorlik Dasturi - MOM bilan Ishlaymiz' },
        { property: 'og:description', content: 'MOM AI hamkorlik dasturiga qo\'shiling! Innovatsion AI yechimlarini targ\'ib qiling va yaxshi komissiyalar oling.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/uz/affiliate` },
        { name: 'twitter:title', content: 'MOM AI Hamkorlik Dasturi - MOM bilan Ishlaymiz' },
        { name: 'twitter:description', content: 'MOM AI hamkorlik dasturiga qo\'shiling! Innovatsion AI yechimlarini targ\'ib qiling va yaxshi komissiyalar oling.' },
      ]
    },
        
    {
      title: 'Program Afiliasi MOM AI - Dapatkan Penghasilan dengan MOM',
      description: 'Bergabunglah dengan Program Afiliasi MOM AI! Promosikan solusi AI inovatif, bagikan teknologi, dan dapatkan komisi menarik.',
      canonical: `${appUrl}/id/affiliate`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/id/affiliate` },
        { property: 'og:title', content: 'Program Afiliasi MOM AI - Dapatkan Penghasilan dengan MOM' },
        { property: 'og:description', content: 'Bergabunglah dengan Program Afiliasi MOM AI! Promosikan solusi AI inovatif, bagikan teknologi, dan dapatkan komisi menarik.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/id/affiliate` },
        { name: 'twitter:title', content: 'Program Afiliasi MOM AI - Dapatkan Penghasilan dengan MOM' },
        { name: 'twitter:description', content: 'Bergabunglah dengan Program Afiliasi MOM AI! Promosikan solusi AI inovatif, bagikan teknologi, dan dapatkan komisi menarik.' },
      ]
    },
    
    {
      title: 'MOM AI افیلیٹ پروگرام - MOM کے ساتھ کماؤ',
      description: 'موم اے آئی افیلیٹ پروگرام میں شامل ہوں! جدید اے آئی حل کو فروغ دیں، ٹیکنالوجی شیئر کریں اور عمدہ کمیشن حاصل کریں۔',
      canonical: `${appUrl}/ur/affiliate`,
      og: [
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: `${appUrl}/ur/affiliate` },
          { property: 'og:title', content: 'MOM AI افیلیٹ پروگرام - MOM کے ساتھ کماؤ' },
          { property: 'og:description', content: 'موم اے آئی افیلیٹ پروگرام میں شامل ہوں! جدید اے آئی حل کو فروغ دیں، ٹیکنالوجی شیئر کریں اور عمدہ کمیشن حاصل کریں۔' },
      ],
      twitter: [
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:url', content: `${appUrl}/ur/affiliate` },
          { name: 'twitter:title', content: 'MOM AI افیلیٹ پروگرام - MOM کے ساتھ کماؤ' },
          { name: 'twitter:description', content: 'موم اے آئی افیلیٹ پروگرام میں شامل ہوں! جدید اے آئی حل کو فروغ دیں، ٹیکنالوجی شیئر کریں اور عمدہ کمیشن حاصل کریں۔' },
      ]
  },

  {
      title: 'Programa de Afiliados MOM AI - Ganhe com MOM',
      description: 'Participe do Programa de Afiliados MOM AI! Promova soluções de IA inovadoras, compartilhe tecnologia e ganhe ótimas comissões.',
      canonical: `${appUrl}/pt/affiliate`,
      og: [
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: `${appUrl}/pt/affiliate` },
          { property: 'og:title', content: 'Programa de Afiliados MOM AI - Ganhe com MOM' },
          { property: 'og:description', content: 'Participe do Programa de Afiliados MOM AI! Promova soluções de IA inovadoras, compartilhe tecnologia e ganhe ótimas comissões.' },
      ],
      twitter: [
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:url', content: `${appUrl}/pt/affiliate` },
          { name: 'twitter:title', content: 'Programa de Afiliados MOM AI - Ganhe com MOM' },
          { name: 'twitter:description', content: 'Participe do Programa de Afiliados MOM AI! Promova soluções de IA inovadoras, compartilhe tecnologia e ganhe ótimas comissões.' },
      ]
  },

  {
      title: 'MOM AI এফিলিয়েট প্রোগ্রাম - MOM এর সাথে উপার্জন করুন',
      description: 'মম এ.আই. সহযোগী প্রোগ্রামে যোগ দিন! উদ্ভাবনী এ.আই. সমাধান প্রচার করুন এবং দুর্দান্ত কমিশন উপার্জন করুন।',
      canonical: `${appUrl}/bn/affiliate`,
      og: [
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: `${appUrl}/bn/affiliate` },
          { property: 'og:title', content: 'MOM AI এফিলিয়েট প্রোগ্রাম - MOM এর সাথে উপার্জন করুন' },
          { property: 'og:description', content: 'মম এ.আই. সহযোগী প্রোগ্রামে যোগ দিন! উদ্ভাবনী এ.আই. সমাধান প্রচার করুন এবং দুর্দান্ত কমিশন উপার্জন করুন।' },
      ],
      twitter: [
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:url', content: `${appUrl}/bn/affiliate` },
          { name: 'twitter:title', content: 'MOM AI এফিলিয়েট প্রোগ্রাম - MOM এর সাথে উপার্জন করুন' },
          { name: 'twitter:description', content: 'মম এ.আই. সহযোগী প্রোগ্রামে যোগ দিন! উদ্ভাবনী এ.আই. সমাধান প্রচার করুন এবং দুর্দান্ত কমিশন উপার্জন করুন।' },
      ]
  },

  {
      title: 'MOM AI 联盟计划 - 与 MOM 一起赚钱',
      description: '加入 MOM AI 联盟计划，通过推广创新的 AI 解决方案赚取收入！与您的网络分享最前沿的技术，并在赋能他人的同时享受丰厚的佣金。',
      canonical: `${appUrl}/zh/affiliate`,
      og: [
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: `${appUrl}/zh/affiliate` },
          { property: 'og:title', content: 'MOM AI 联盟计划 - 与 MOM 一起赚钱' },
          { property: 'og:description', content: '加入 MOM AI 联盟计划，通过推广创新的 AI 解决方案赚取收入！与您的网络分享最前沿的技术，并在赋能他人的同时享受丰厚的佣金。' },
      ],
      twitter: [
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:url', content: `${appUrl}/zh/affiliate` },
          { name: 'twitter:title', content: 'MOM AI 联盟计划 - 与 MOM 一起赚钱' },
          { name: 'twitter:description', content: '加入 MOM AI 联盟计划，通过推广创新的 AI 解决方案赚取收入！与您的网络分享最前沿的技术，并在赋能他人的同时享受丰厚的佣金。' },
      ]
  },
  {
        title: 'MOM AI இணைப்பு திட்டம் - MOM உடன் சம்பாதிக்கவும்',
        description: 'மோம் எஐ கூட்டாண்மை திட்டத்தில் சேருங்கள்! புதிய எஐ தீர்வுகளை பரப்புங்கள், தொழில்நுட்பத்தை பகிருங்கள், நன்மைகள் சம்பாதிக்கவும்.',
        canonical: `${appUrl}/ta/affiliate`,
        og: [
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: `${appUrl}/ta/affiliate` },
            { property: 'og:title', content: 'MOM AI இணைப்பு திட்டம் - MOM உடன் சம்பாதிக்கவும்' },
            { property: 'og:description', content: 'மோம் எஐ கூட்டாண்மை திட்டத்தில் சேருங்கள்! புதிய எஐ தீர்வுகளை பரப்புங்கள், தொழில்நுட்பத்தை பகிருங்கள், நன்மைகள் சம்பாதிக்கவும்.' },
        ],
        twitter: [
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:url', content: `${appUrl}/ta/affiliate` },
            { name: 'twitter:title', content: 'MOM AI இணைப்பு திட்டம் - MOM உடன் சம்பாதிக்கவும்' },
            { name: 'twitter:description', content: 'மோம் எஐ கூட்டாண்மை திட்டத்தில் சேருங்கள்! புதிய எஐ தீர்வுகளை பரப்புங்கள், தொழில்நுட்பத்தை பகிருங்கள், நன்மைகள் சம்பாதிக்கவும்.' },
        ]
    },
    
    {
      title: 'Programma di Affiliazione MOM AI - Guadagna con MOM',
      description: 'Unisciti al Programma di Affiliazione MOM AI! Promuovi soluzioni IA innovative e guadagna ottime commissioni condividendo tecnologia.',
      canonical: `${appUrl}/it/affiliate`,
      og: [
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: `${appUrl}/it/affiliate` },
          { property: 'og:title', content: 'Programma di Affiliazione MOM AI - Guadagna con MOM' },
          { property: 'og:description', content: 'Unisciti al Programma di Affiliazione MOM AI! Promuovi soluzioni IA innovative e guadagna ottime commissioni condividendo tecnologia.' },
      ],
      twitter: [
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:url', content: `${appUrl}/it/affiliate` },
          { name: 'twitter:title', content: 'Programma di Affiliazione MOM AI - Guadagna con MOM' },
          { name: 'twitter:description', content: 'Unisciti al Programma di Affiliazione MOM AI! Promuovi soluzioni IA innovative e guadagna ottime commissioni condividendo tecnologia.' },
      ]
  },
  
  {
        title: 'โปรแกรมพันธมิตร MOM AI - หาเงินกับ MOM',
        description: 'เข้าร่วมโปรแกรมพันธมิตร MOM AI! ส่งเสริมโซลูชัน AI นวัตกรรม แบ่งปันเทคโนโลยี และรับค่าคอมมิชชั่นดีๆ',
        canonical: `${appUrl}/th/affiliate`,
        og: [
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: `${appUrl}/th/affiliate` },
            { property: 'og:title', content: 'โปรแกรมพันธมิตร MOM AI - หาเงินกับ MOM' },
            { property: 'og:description', content: 'เข้าร่วมโปรแกรมพันธมิตร MOM AI! ส่งเสริมโซลูชัน AI นวัตกรรม แบ่งปันเทคโนโลยี และรับค่าคอมมิชชั่นดีๆ' },
        ],
        twitter: [
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:url', content: `${appUrl}/th/affiliate` },
            { name: 'twitter:title', content: 'โปรแกรมพันธมิตร MOM AI - หาเงินกับ MOM' },
            { name: 'twitter:description', content: 'เข้าร่วมโปรแกรมพันธมิตร MOM AI! ส่งเสริมโซลูชัน AI นวัตกรรม แบ่งปันเทคโนโลยี และรับค่าคอมมิชชั่นดีๆ' },
        ]
    },

    {
      title: 'MOM AI അനുബന്ധ പ്രോഗ്രാം - MOM ഉപയോഗിച്ച് വരുമാനം നേടുക',
      description: 'മോം എ.ഐ. സഹകരണ പരിപാടിയിൽ ചേരൂ! നവീന എ.ഐ. പരിഹാരങ്ങൾ പ്രചാരണം നടത്തുക, സാങ്കേതികവിദ്യ പങ്കിടുക, വലിയ കമ്മീഷൻ നേടുക.',
      canonical: `${appUrl}/ml/affiliate`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/ml/affiliate` },
        { property: 'og:title', content: 'MOM AI അനുബന്ധ പ്രോഗ്രാം - MOM ഉപയോഗിച്ച് വരുമാനം നേടുക' },
        { property: 'og:description', content: 'മോം എ.ഐ. സഹകരണ പരിപാടിയിൽ ചേരൂ! നവീന എ.ഐ. പരിഹാരങ്ങൾ പ്രചാരണം നടത്തുക, സാങ്കേതികവിദ്യ പങ്കിടുക, വലിയ കമ്മീഷൻ നേടുക.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/ml/affiliate` },
        { name: 'twitter:title', content: 'MOM AI അനുബന്ധ പ്രോഗ്രാം - MOM ഉപയോഗിച്ച് വരുമാനം നേടുക' },
        { name: 'twitter:description', content: 'മോം എ.ഐ. സഹകരണ പരിപാടിയിൽ ചേരൂ! നവീന എ.ഐ. പരിഹാരങ്ങൾ പ്രചാരണം നടത്തുക, സാങ്കേതികവിദ്യ പങ്കിടുക, വലിയ കമ്മീഷൻ നേടുക.' },
      ]
    },

    {
      title: 'MOM AI Ortaklık Programı - MOM ile Kazanın',
      description: 'MOM AI Affiliate Programına katılın! Yenilikçi AI çözümlerini tanıtın, teknoloji paylaşın ve harika komisyonlar kazanın.',
      canonical: `${appUrl}/tr/affiliate`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/tr/affiliate` },
        { property: 'og:title', content: 'MOM AI Ortaklık Programı - MOM ile Kazanın' },
        { property: 'og:description', content: 'MOM AI Affiliate Programına katılın! Yenilikçi AI çözümlerini tanıtın, teknoloji paylaşın ve harika komisyonlar kazanın.' }
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/tr/affiliate` },
        { name: 'twitter:title', content: 'MOM AI Ortaklık Programı - MOM ile Kazanın' },
        { name: 'twitter:description', content: 'MOM AI Affiliate Programına katılın! Yenilikçi AI çözümlerini tanıtın, teknoloji paylaşın ve harika komisyonlar kazanın.' }
      ]
    },

    {
      title: 'MOM AI Provider Support | Get Help Anytime',
      description: 'Get 24/7 support from MOM AI Provider. Contact us for assistance!',
      canonical: `${appUrl}/provider/support`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/support` },
        { property: 'og:title', content: 'MOM AI Provider Support | Get Help Anytime' },
        { property: 'og:description', content: 'Get 24/7 support from MOM AI Provider. Contact us for assistance!' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/support` },
        { name: 'twitter:title', content: 'MOM AI Provider Support | Get Help Anytime' },
        { name: 'twitter:description', content: 'Get 24/7 support from MOM AI Provider. Contact us for assistance!' },
      ]
    },
        
    {
      title: 'MOM AI Provider Policies - Terms and Conditions',
      description: 'Learn more about MOM AI Provider policies, including terms and conditions, and privacy practices.',
      canonical: `${appUrl}/provider/policies`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/policies` },
        { property: 'og:title', content: 'MOM AI Provider Policies - Terms and Conditions' },
        { property: 'og:description', content: 'Learn more about MOM AI Providers policies, including terms and conditions, and privacy practices.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/policies` },
        { name: 'twitter:title', content: 'MOM AI Provider Policies - Terms and Conditions' },
        { name: 'twitter:description', content: 'Learn more about MOM AI Providers policies, including terms and conditions, and privacy practices.' },
      ]
    },
    
    {
      title: 'MOM AI Provider - Effortless Service Booking',
      description: 'Book services with MOM AI Provider. Our seamless booking system streamlines operations for service providers.',
      canonical: `${appUrl}/provider/booking`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/booking` },
        { property: 'og:title', content: 'MOM AI Provider - Effortless Service Booking' },
        { property: 'og:description', content: 'Book services with MOM AI Provider. Our seamless booking system streamlines operations for service providers.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/booking` },
        { name: 'twitter:title', content: 'MOM AI Provider - Effortless Service Booking' },
        { name: 'twitter:description', content: 'Book services with MOM AI Provider. Our seamless booking system streamlines operations for service providers.' },
      ]
    },
    {
      title: 'MOM AI O?zbek do?koni - AI orqali biznesingizni rivojlantiring',
      description: 'MOM AI O\'zbek do\'konini kashf eting va zamonaviy sun\'iy intellekt texnologiyalari bilan biznesingizni yaxshilang.',
      canonical: `${appUrl}/uz/shop`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/uz/shop` },
        { property: 'og:title', content: 'MOM AI O?zbek do?koni - AI orqali biznesingizni rivojlantiring' },
        { property: 'og:description', content: 'MOM AI O\'zbek do\'konini kashf eting va zamonaviy sun\'iy intellekt texnologiyalari bilan biznesingizni yaxshilang.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/uz/shop` },
        { name: 'twitter:title', content: 'MOM AI O?zbek do?koni - AI orqali biznesingizni rivojlantiring' },
        { name: 'twitter:description', content: 'MOM AI O\'zbek do\'konini kashf eting va zamonaviy sun\'iy intellekt texnologiyalari bilan biznesingizni yaxshilang.' },
      ]
    },
    {
      title: 'MOM AI O?zbek Xizmat ko\'rsatuvchi - AI yechimlari bilan xizmatlarni yaxshilang',
      description: 'MOM AI O\'zbek xizmat ko\'rsatuvchi uchun yechimlarni o\'rganing va sun\'iy intellekt texnologiyalari bilan xizmatlarni yaxshilang.',
      canonical: `${appUrl}/uz/provider`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/uz/provider` },
        { property: 'og:title', content: 'MOM AI O?zbek Xizmat ko\'rsatuvchi - AI yechimlari bilan xizmatlarni yaxshilang' },
        { property: 'og:description', content: 'MOM AI O\'zbek xizmat ko\'rsatuvchi uchun yechimlarni o\'rganing va sun\'iy intellekt texnologiyalari bilan xizmatlarni yaxshilang.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/uz/provider` },
        { name: 'twitter:title', content: 'MOM AI O?zbek Xizmat ko\'rsatuvchi - AI yechimlari bilan xizmatlarni yaxshilang' },
        { name: 'twitter:description', content: 'MOM AI O\'zbek xizmat ko\'rsatuvchi uchun yechimlarni o\'rganing va sun\'iy intellekt texnologiyalari bilan xizmatlarni yaxshilang.' },
      ]
    },
      {
      title: 'MOM AI Provedor Portugus - Solues de IA para Empresas',
      description: 'Descubra as solues avanadas de IA da MOM AI para provedores em portugus. Melhore os seus servios com tecnologia de IA de ponta.',
      canonical: `${appUrl}/pt/provider`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/pt/provider` },
        { property: 'og:title', content: 'MOM AI Provedor Portugus - Solues de IA para Empresas' },
        { property: 'og:description', content: 'Descubra as solues avanadas de IA da MOM AI para provedores em portugus. Melhore os seus servios com tecnologia de IA de ponta.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/pt/provider` },
        { name: 'twitter:title', content: 'MOM AI Provedor Portugus - Solues de IA para Empresas' },
        { name: 'twitter:description', content: 'Descubra as solues avanadas de IA da MOM AI para provedores em portugus. Melhore os seus servios com tecnologia de IA de ponta.' },
      ]
    },
    
    {
      title: 'MOM AI Поставщик Русский - Решения на базе ИИ для бизнеса',
      description: 'Откройте для себя передовые решения на базе ИИ от MOM AI для поставщиков на русском языке. Улучшите свои услуги с помощью передовых технологий ИИ.',
      canonical: `${appUrl}/ru/provider`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/ru/provider` },
        { property: 'og:title', content: 'MOM AI Поставщик Русский - Решения на базе ИИ для бизнеса' },
        { property: 'og:description', content: 'Откройте для себя передовые решения на базе ИИ от MOM AI для поставщиков на русском языке. Улучшите свои услуги с помощью передовых технологий ИИ.' }
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/ru/provider` },
        { name: 'twitter:title', content: 'MOM AI Поставщик Русский - Решения на базе ИИ для бизнеса' },
        { name: 'twitter:description', content: 'Откройте для себя передовые решения на базе ИИ от MOM AI для поставщиков на русском языке. Улучшите свои услуги с помощью передовых технологий ИИ.' }
      ]
    },
    
    {
      title: 'MOM AI Agent Dashboard - Manage Service Operations',
      description: 'Access MOM AI Agent Dashboard to oversee and manage service operations effectively with AI-enhanced tools.',
      canonical: `${appUrl}/provider/agent/dashboard`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/agent/dashboard` },
        { property: 'og:title', content: 'MOM AI Agent Dashboard - Manage Service Operations' },
        { property: 'og:description', content: 'Access MOM AI Agent Dashboard to oversee and manage service operations effectively with AI-enhanced tools.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/agent/dashboard` },
        { name: 'twitter:title', content: 'MOM AI Agent Dashboard - Manage Service Operations' },
        { name: 'twitter:description', content: 'Access MOM AI Agent Dashboard to oversee and manage service operations effectively with AI-enhanced tools.' },
      ]
    },
    
    {
      title: 'MOM AI Proveedor en Espaol - Mejora tu negocio con IA',
      description: 'Descubre las soluciones de MOM AI para proveedores en espaol. Mejora tu negocio con herramientas de inteligencia artificial de ltima generacin.',
      canonical: `${appUrl}/es/provider`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/es/provider` },
        { property: 'og:title', content: 'MOM AI Proveedor en Espaol - Mejora tu negocio con IA' },
        { property: 'og:description', content: 'Descubre las soluciones de MOM AI para proveedores en espaol. Mejora tu negocio con herramientas de inteligencia artificial de ltima generacin.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/es/provider` },
        { name: 'twitter:title', content: 'MOM AI Proveedor en Espaol - Mejora tu negocio con IA' },
        { name: 'twitter:description', content: 'Descubre las soluciones de MOM AI para proveedores en espaol. Mejora tu negocio con herramientas de inteligencia artificial de ltima generacin.' },
      ]
    },
    
    {
      title: 'MOM AI Payment Configuration - Streamline Your Payments',
      description: 'Configure payments effortlessly using MOM AIs payment system for service providers.',
      canonical: `${appUrl}/provider/payment-config`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/payment-config` },
        { property: 'og:title', content: 'MOM AI Payment Configuration - Streamline Your Payments' },
        { property: 'og:description', content: 'Configure payments effortlessly using MOM AIs payment system for service providers.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/payment-config` },
        { name: 'twitter:title', content: 'MOM AI Payment Configuration - Streamline Your Payments' },
        { name: 'twitter:description', content: 'Configure payments effortlessly using MOM AIs payment system for service providers.' },
      ]
    },
    
    {
      title: 'MOM AI Trkiye Kullanici Hizmetleri - Hesabinizi Ynetin',
      description: 'MOM AI Trkiye kullanici panelini kesfedin ve hesabinizi yneterek yapay zeka destekli hizmetlerden faydalanin.',
      canonical: `${appUrl}/tr/user`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/tr/user` },
        { property: 'og:title', content: 'MOM AI Trkiye Kullanici Hizmetleri - Hesabinizi Ynetin' },
        { property: 'og:description', content: 'MOM AI Trkiye kullanici panelini kesfedin ve hesabinizi yneterek yapay zeka destekli hizmetlerden faydalanin.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/tr/user` },
        { name: 'twitter:title', content: 'MOM AI Trkiye Kullanici Hizmetleri - Hesabinizi Ynetin' },
        { name: 'twitter:description', content: 'MOM AI Trkiye kullanici panelini kesfedin ve hesabinizi yneterek yapay zeka destekli hizmetlerden faydalanin.' },
      ]
    },
    
    {
      title: 'MOM AI Agent Requests - Manage Incoming Requests Efficiently',
      description: 'View and manage all service agent requests through MOM AIs AI-powered agent management platform.',
      canonical: `${appUrl}/provider/agent/requests`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/agent/requests` },
        { property: 'og:title', content: 'MOM AI Agent Requests - Manage Incoming Requests Efficiently' },
        { property: 'og:description', content: 'View and manage all service agent requests through MOM AIs AI-powered agent management platform.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/agent/requests` },
        { name: 'twitter:title', content: 'MOM AI Agent Requests - Manage Incoming Requests Efficiently' },
        { name: 'twitter:description', content: 'View and manage all service agent requests through MOM AIs AI-powered agent management platform.' },
      ]
    },
    
    {
      title: 'MOM AI Services Utilisateur Franais - Grer votre Compte',
      description: 'Accdez aux services utilisateur MOM AI en franais et grez votre compte efficacement avec des outils aliments par lIA.',
      canonical: `${appUrl}/fr/user`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/fr/user` },
        { property: 'og:title', content: 'MOM AI Services Utilisateur Franais - Grer votre Compte' },
        { property: 'og:description', content: 'Accdez aux services utilisateur MOM AI en franais et grez votre compte efficacement avec des outils aliments par lIA.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/fr/user` },
        { name: 'twitter:title', content: 'MOM AI Services Utilisateur Franais - Grer votre Compte' },
        { name: 'twitter:description', content: 'Accdez aux services utilisateur MOM AI en franais et grez votre compte efficacement avec des outils aliments par lIA.' },
      ]
    },
    
    {
      title: 'MOM AI Servizi Utente Italiano - Gestisci il tuo Account',
      description: 'Gestisci il tuo account utente italiano su MOM AI con strumenti avanzati basati sullintelligenza artificiale.',
      canonical: `${appUrl}/it/user`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/it/user` },
        { property: 'og:title', content: 'MOM AI Servizi Utente Italiano - Gestisci il tuo Account' },
        { property: 'og:description', content: 'Gestisci il tuo account utente italiano su MOM AI con strumenti avanzati basati sullintelligenza artificiale.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/it/user` },
        { name: 'twitter:title', content: 'MOM AI Servizi Utente Italiano - Gestisci il tuo Account' },
        { name: 'twitter:description', content: 'Gestisci il tuo account utente italiano su MOM AI con strumenti avanzati basati sullintelligenza artificiale.' },
      ]
    },
    
    {
      title: 'MOM AI Tienda en Espaol - Mejorar su Negocio con IA',
      description: 'Explore MOM AI Tienda en Espaol y descubra cmo puede mejorar sus operaciones comerciales con tecnologa avanzada de inteligencia artificial.',
      canonical: `${appUrl}/es/shop`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/es/shop` },
        { property: 'og:title', content: 'MOM AI Tienda en Espaol - Mejorar su Negocio con IA' },
        { property: 'og:description', content: 'Explore MOM AI Tienda en Espaol y descubra cmo puede mejorar sus operaciones comerciales con tecnologa avanzada de inteligencia artificial.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/es/shop` },
        { name: 'twitter:title', content: 'MOM AI Tienda en Espaol - Mejorar su Negocio con IA' },
        { name: 'twitter:description', content: 'Explore MOM AI Tienda en Espaol y descubra cmo puede mejorar sus operaciones comerciales con tecnologa avanzada de inteligencia artificial.' },
      ]
    },
    
    {
      title: 'MOM AI Toko Indonesia - Solusi AI untuk Bisnis Anda',
      description: 'Temukan toko MOM AI di Indonesia dan mulai optimalkan bisnis Anda dengan solusi AI canggih untuk berbagai industri.',
      canonical: `${appUrl}/id/shop`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/id/shop` },
        { property: 'og:title', content: 'MOM AI Toko Indonesia - Solusi AI untuk Bisnis Anda' },
        { property: 'og:description', content: 'Temukan toko MOM AI di Indonesia dan mulai optimalkan bisnis Anda dengan solusi AI canggih untuk berbagai industri.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/id/shop` },
        { name: 'twitter:title', content: 'MOM AI Toko Indonesia - Solusi AI untuk Bisnis Anda' },
        { name: 'twitter:description', content: 'Temukan toko MOM AI di Indonesia dan mulai optimalkan bisnis Anda dengan solusi AI canggih untuk berbagai industri.' },
      ]
    },
    
    {
      title: 'MOM AI Pengguna Indonesia - Kelola Akun Anda',
      description: 'Kelola akun MOM AI Anda di Indonesia dan manfaatkan layanan berbasis AI untuk kebutuhan bisnis Anda.',
      canonical: `${appUrl}/id/user`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/id/user` },
        { property: 'og:title', content: 'MOM AI Pengguna Indonesia - Kelola Akun Anda' },
        { property: 'og:description', content: 'Kelola akun MOM AI Anda di Indonesia dan manfaatkan layanan berbasis AI untuk kebutuhan bisnis Anda.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/id/user` },
        { name: 'twitter:title', content: 'MOM AI Pengguna Indonesia - Kelola Akun Anda' },
        { name: 'twitter:description', content: 'Kelola akun MOM AI Anda di Indonesia dan manfaatkan layanan berbasis AI untuk kebutuhan bisnis Anda.' },
      ]
    },
    
    {
      title: 'MOM AI Service Requests - Streamline Your Business',
      description: 'Manage all incoming service requests efficiently through MOM AIs advanced provider platform.',
      canonical: `${appUrl}/provider/requests`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/requests` },
        { property: 'og:title', content: 'MOM AI Service Requests - Streamline Your Business' },
        { property: 'og:description', content: 'Manage all incoming service requests efficiently through MOM AIs advanced provider platform.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/requests` },
        { name: 'twitter:title', content: 'MOM AI Service Requests - Streamline Your Business' },
        { name: 'twitter:description', content: 'Manage all incoming service requests efficiently through MOM AIs advanced provider platform.' },
      ]
    },
    {
      title: 'MOM AI Servizi Principali in Italiano - Scopri Soluzioni AI',
      description: 'Esplora le soluzioni principali di MOM AI in italiano e scopri come lintelligenza artificiale pu migliorare il tuo business.',
      canonical: `${appUrl}/it/main`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/it/main` },
        { property: 'og:title', content: 'MOM AI Servizi Principali in Italiano - Scopri Soluzioni AI' },
        { property: 'og:description', content: 'Esplora le soluzioni principali di MOM AI in italiano e scopri come lintelligenza artificiale pu migliorare il tuo business.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/it/main` },
        { name: 'twitter:title', content: 'MOM AI Servizi Principali in Italiano - Scopri Soluzioni AI' },
        { name: 'twitter:description', content: 'Esplora le soluzioni principali di MOM AI in italiano e scopri come lintelligenza artificiale pu migliorare il tuo business.' },
      ]
    },
    
    {
      title: 'MOM AI kullanicilari Malayalam - Hesabinizi Ynetin',
      description: 'MOM AI Malayalam kullanici panelini kullanarak hesabinizi ynetin ve AI destekli hizmetlerden faydalanin.',
      canonical: `${appUrl}/ml/user`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/ml/user` },
        { property: 'og:title', content: 'MOM AI kullanicilari Malayalam - Hesabinizi Ynetin' },
        { property: 'og:description', content: 'MOM AI Malayalam kullanici panelini kullanarak hesabinizi ynetin ve AI destekli hizmetlerden faydalanin.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/ml/user` },
        { name: 'twitter:title', content: 'MOM AI kullanicilari Malayalam - Hesabinizi Ynetin' },
        { name: 'twitter:description', content: 'MOM AI Malayalam kullanici panelini kullanarak hesabinizi ynetin ve AI destekli hizmetlerden faydalanin.' },
      ]
    },
    
    {
      title: 'MOM AI Provider Photos - Showcase Your Services',
      description: 'Upload and manage photos to enhance your service offerings on the MOM AI platform.',
      canonical: `${appUrl}/provider/photos`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/photos` },
        { property: 'og:title', content: 'MOM AI Provider Photos - Showcase Your Services' },
        { property: 'og:description', content: 'Upload and manage photos to enhance your service offerings on the MOM AI platform.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/photos` },
        { name: 'twitter:title', content: 'MOM AI Provider Photos - Showcase Your Services' },
        { name: 'twitter:description', content: 'Upload and manage photos to enhance your service offerings on the MOM AI platform.' },
      ]
    },
          
    {
      title: 'MOM AI My Documents - Organize Your Files',
      description: 'Easily upload and manage your documents on the MOM AI provider platform.',
      canonical: `${appUrl}/provider/my-documents`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/my-documents` },
        { property: 'og:title', content: 'MOM AI My Documents - Organize Your Files' },
        { property: 'og:description', content: 'Easily upload and manage your documents on the MOM AI provider platform.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/my-documents` },
        { name: 'twitter:title', content: 'MOM AI My Documents - Organize Your Files' },
        { name: 'twitter:description', content: 'Easily upload and manage your documents on the MOM AI provider platform.' },
      ]
    },
    {
      title: 'MOM AI Anbieter Deutsch - Hauptservices entdecken',
      description: 'Entdecken Sie die Hauptservices von MOM AI fr Anbieter und erfahren Sie, wie Sie Ihr Geschft verbessern knnen.',
      canonical: `${appUrl}/de/provider`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/de/provider` },
        { property: 'og:title', content: 'MOM AI Anbieter Deutsch - Hauptservices entdecken' },
        { property: 'og:description', content: 'Entdecken Sie die Hauptservices von MOM AI fr Anbieter und erfahren Sie, wie Sie Ihr Geschft verbessern knnen.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/de/provider` },
        { name: 'twitter:title', content: 'MOM AI Anbieter Deutsch - Hauptservices entdecken' },
        { name: 'twitter:description', content: 'Entdecken Sie die Hauptservices von MOM AI fr Anbieter und erfahren Sie, wie Sie Ihr Geschft verbessern knnen.' },
      ]
    },
    
    {
      title: 'MOM AI Principal Services en Franais - Amliorez votre entreprise',
      description: 'Dcouvrez les principaux services de MOM AI en franais et comment nous pouvons amliorer votre entreprise.',
      canonical: `${appUrl}/fr/main`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/fr/main` },
        { property: 'og:title', content: 'MOM AI Principal Services en Franais - Amliorez votre entreprise' },
        { property: 'og:description', content: 'Dcouvrez les principaux services de MOM AI en franais et comment nous pouvons amliorer votre entreprise.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/fr/main` },
        { name: 'twitter:title', content: 'MOM AI Principal Services en Franais - Amliorez votre entreprise' },
        { name: 'twitter:description', content: 'Dcouvrez les principaux services de MOM AI en franais et comment nous pouvons amliorer votre entreprise.' },
      ]
    },
    
    {
      title: 'MOM AI HRM Employees - Manage Your Team Efficiently',
      description: 'Manage your HR tasks effectively through the MOM AI platform and keep track of employee records.',
      canonical: `${appUrl}/provider/hrm-employees`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/hrm-employees` },
        { property: 'og:title', content: 'MOM AI HRM Employees - Manage Your Team Efficiently' },
        { property: 'og:description', content: 'Manage your HR tasks effectively through the MOM AI platform and keep track of employee records.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/hrm-employees` },
        { name: 'twitter:title', content: 'MOM AI HRM Employees - Manage Your Team Efficiently' },
        { name: 'twitter:description', content: 'Manage your HR tasks effectively through the MOM AI platform and keep track of employee records.' },
      ]
    },
          
    {
      title: 'MOM AI HRM Holidays - Manage Time Off',
      description: 'Manage employee holidays and time off requests effectively through the MOM AI platform.',
      canonical: `${appUrl}/provider/hrm-holidays`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/hrm-holidays` },
        { property: 'og:title', content: 'MOM AI HRM Holidays - Manage Time Off' },
        { property: 'og:description', content: 'Manage employee holidays and time off requests effectively through the MOM AI platform.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/hrm-holidays` },
        { name: 'twitter:title', content: 'MOM AI HRM Holidays - Manage Time Off' },
        { name: 'twitter:description', content: 'Manage employee holidays and time off requests effectively through the MOM AI platform.' },
      ]
    },
    
    {
      title: 'MOM AI Provider Overviews - Insight into Services',
      description: 'Discover a detailed overview of the diverse services available on the MOM AI provider platform.',
      canonical: `${appUrl}/provider/overviews`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/overviews` },
        { property: 'og:title', content: 'MOM AI Provider Overviews - Insight into Services' },
        { property: 'og:description', content: 'Discover a detailed overview of the diverse services available on the MOM AI provider platform.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/overviews` },
        { name: 'twitter:title', content: 'MOM AI Provider Overviews - Insight into Services' },
        { name: 'twitter:description', content: 'Discover a detailed overview of the diverse services available on the MOM AI provider platform.' },
      ]
    },
    
    {
      title: 'MOM AI Notifications - Stay Updated',
      description: 'Easily manage your notifications and receive timely updates on the latest alerts from MOM AI.',
      canonical: `${appUrl}/provider/notifications`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/notifications` },
        { property: 'og:title', content: 'MOM AI Notifications - Stay Updated' },
        { property: 'og:description', content: 'Easily manage your notifications and receive timely updates on the latest alerts from MOM AI.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/notifications` },
        { name: 'twitter:title', content: 'MOM AI Notifications - Stay Updated' },
        { name: 'twitter:description', content: 'Easily manage your notifications and receive timely updates on the latest alerts from MOM AI.' },
      ]
    },
    
    {
      title: 'MOM AI Provider Profile - Manage Your Profile',
      description: 'Effortlessly access and manage your provider profile through the intuitive MOM AI platform interface.',
      canonical: `${appUrl}/provider/profile`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/profile` },
        { property: 'og:title', content: 'MOM AI Provider Profile - Manage Your Profile' },
        { property: 'og:description', content: 'Effortlessly access and manage your provider profile through the intuitive MOM AI platform interface.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/profile` },
        { name: 'twitter:title', content: 'MOM AI Provider Profile - Manage Your Profile' },
        { name: 'twitter:description', content: 'Effortlessly access and manage your provider profile through the intuitive MOM AI platform interface.' },
      ]
    },
    
    {
      title: 'MOM AI Provider Promocodes - Manage Discounts',
      description: 'Create and manage promotional codes to attract customers to your services on the MOM AI platform.',
      canonical: `${appUrl}/provider/promocodes`,
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${appUrl}/provider/promocodes` },
        { property: 'og:title', content: 'MOM AI Provider Promocodes - Manage Discounts' },
        { property: 'og:description', content: 'Create and manage promotional codes to attract customers to your services on the MOM AI platform.' },
      ],
      twitter: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: `${appUrl}/provider/promocodes` },
        { name: 'twitter:title', content: 'MOM AI Provider Promocodes - Manage Discounts' },
        { name: 'twitter:description', content: 'Create and manage promotional codes to attract customers to your services on the MOM AI platform.' },
      ]
    },
    
];

// Map pages data to meta information
const allPages = Array.isArray(pagesData) ? pagesData.map(page => mappedMetaData(page)) : [];

// Function to perform basic SEO checks only in development mode
function performSEOChecks(meta, link, title) {
  // Ensure h1 tag exists
  let h1Tag = document.querySelector('h1');
  if (!h1Tag) {
    h1Tag = document.createElement('h1');
    h1Tag.innerText = title || document.title;
    document.body.insertBefore(h1Tag, document.body.firstChild);
  }

  // Validate meta description length
  const metaDescription = meta.find(m => m.name === 'description');
  if (metaDescription) {
    const descriptionContent = metaDescription.content;
    if (descriptionContent.length < 50 || descriptionContent.length > 160) {
      console.warn('Meta description length is not optimal:', descriptionContent.length);
    }
  }

  // Check for missing Open Graph and Twitter card tags
  const ogTags = ['og:title', 'og:description', 'og:image', 'og:url'];
  const twitterTags = ['twitter:card', 'twitter:title', 'twitter:description', 'twitter:image'];

  ogTags.forEach(tag => {
    if (!meta.some(m => m.property === tag)) {
      console.warn(`Missing Open Graph tag: ${tag}`);
    }
  });

  twitterTags.forEach(tag => {
    if (!meta.some(m => m.name === tag)) {
      console.warn(`Missing Twitter card tag: ${tag}`);
    }
  });

  // Check for missing canonical link
  if (!link.some(l => l.rel === 'canonical')) {
    console.warn('Canonical link is missing');
  }

  // Check alt text for images
  const images = document.querySelectorAll('img');
  images.forEach(img => {
    if (!img.alt) {
      console.warn('Image missing alt text:', img.src);
    }
  });
}

// Function to update page meta info
const updateMetaInfo = debounce(() => {
  const currentPage = allPages.find(page =>
    page.link && page.link.some(l => l.rel === 'canonical' && l.href === curUrl)
  );

  if (currentPage) {
    const { title, meta, link } = currentPage;

    // Update page title
    if (title) {
      document.title = title;
    }

    // Update canonical link
    if (link && Array.isArray(link) && link.length > 0) {
      const canonicalObj = link.find(l => l.rel === 'canonical');
      if (canonicalObj && canonicalObj.href) {
        let canonicalLink = document.querySelector('link[rel="canonical"]');
        if (!canonicalLink) {
          canonicalLink = document.createElement('link');
          canonicalLink.rel = 'canonical';
          document.head.appendChild(canonicalLink);
        }
        canonicalLink.href = canonicalObj.href;
      }
    }

    // Remove existing meta tags to avoid duplicates
    meta.forEach(m => {
      const metaSelector = m.name ? `meta[name="${m.name}"]` : `meta[property="${m.property}"]`;
      const existingMeta = document.querySelector(metaSelector);
      if (existingMeta) existingMeta.remove(); // Only remove existing ones
    });

    // Update meta tags
    meta.forEach(m => {
      const metaSelector = m.name ? `meta[name="${m.name}"]` : `meta[property="${m.property}"]`;
      let metaEle = document.querySelector(metaSelector) || document.createElement('meta');
      document.head.appendChild(metaEle);
      metaEle.setAttribute(m.name ? 'name' : 'property', m.name || m.property);
      metaEle.setAttribute('content', m.content);
    });

    // Perform SEO checks only in development mode
    if (process.env.NODE_ENV === 'development') {
      performSEOChecks(meta, link, title);
    }
  }
}, 300); // Adjust the debounce delay as per requirement

function updateSchema() {
  const schemaMarkup = generateSchemaMarkup();
  const script = document.createElement('script');
  script.type = 'application/ld+json';
  script.text = JSON.stringify(schemaMarkup);
  document.head.appendChild(script);
}

// Generate hreflang tags
function generateHreflangTags() {
  const languages = [
    { href: "https://momitnow.com/en/main", hreflang: "x-default" },
    { href: "https://momitnow.com/en/main", hreflang: "en" },
    { href: "https://momitnow.com/hi/main", hreflang: "hi" },
    { href: "https://momitnow.com/ar/main", hreflang: "ar" },
    { href: "https://momitnow.com/de/main", hreflang: "de" },
    { href: "https://momitnow.com/es/main", hreflang: "es" },
    { href: "https://momitnow.com/fr/main", hreflang: "fr" },
    { href: "https://momitnow.com/ru/main", hreflang: "ru" },
    { href: "https://momitnow.com/uz/main", hreflang: "uz" },
    { href: "https://momitnow.com/id/main", hreflang: "id" },
    { href: "https://momitnow.com/ur/main", hreflang: "ur" },
    { href: "https://momitnow.com/pt/main", hreflang: "pt" },
    { href: "https://momitnow.com/bn/main", hreflang: "bn" },
    { href: "https://momitnow.com/zh/main", hreflang: "zh" },
    { href: "https://momitnow.com/ta/main", hreflang: "ta" },
    { href: "https://momitnow.com/it/main", hreflang: "it" },
    { href: "https://momitnow.com/th/main", hreflang: "th" },
    { href: "https://momitnow.com/ml/main", hreflang: "ml" },
    { href: "https://momitnow.com/tr/main", hreflang: "tr" },
    // User page
    { href: "https://momitnow.com/en/user", hreflang: "en" },
    { href: "https://momitnow.com/hi/user", hreflang: "hi" },
    { href: "https://momitnow.com/ar/user", hreflang: "ar" },
    { href: "https://momitnow.com/de/user", hreflang: "de" },
    { href: "https://momitnow.com/es/user", hreflang: "es" },
    { href: "https://momitnow.com/fr/user", hreflang: "fr" },
    { href: "https://momitnow.com/ru/user", hreflang: "ru" },
    { href: "https://momitnow.com/uz/user", hreflang: "uz" },
    { href: "https://momitnow.com/id/user", hreflang: "id" },
    { href: "https://momitnow.com/ur/user", hreflang: "ur" },
    { href: "https://momitnow.com/pt/user", hreflang: "pt" },
    { href: "https://momitnow.com/bn/user", hreflang: "bn" },
    { href: "https://momitnow.com/zh/user", hreflang: "zh" },
    { href: "https://momitnow.com/ta/user", hreflang: "ta" },
    { href: "https://momitnow.com/it/user", hreflang: "it" },
    { href: "https://momitnow.com/th/user", hreflang: "th" },
    { href: "https://momitnow.com/ml/user", hreflang: "ml" },
    { href: "https://momitnow.com/tr/user", hreflang: "tr" },
    // Shop page
    { href: "https://momitnow.com/en/shop", hreflang: "en" },
    { href: "https://momitnow.com/hi/shop", hreflang: "hi" },
    { href: "https://momitnow.com/ar/shop", hreflang: "ar" },
    { href: "https://momitnow.com/de/shop", hreflang: "de" },
    { href: "https://momitnow.com/es/shop", hreflang: "es" },
    { href: "https://momitnow.com/fr/shop", hreflang: "fr" },
    { href: "https://momitnow.com/ru/shop", hreflang: "ru" },
    { href: "https://momitnow.com/uz/shop", hreflang: "uz" },
    { href: "https://momitnow.com/id/shop", hreflang: "id" },
    { href: "https://momitnow.com/ur/shop", hreflang: "ur" },
    { href: "https://momitnow.com/pt/shop", hreflang: "pt" },
    { href: "https://momitnow.com/bn/shop", hreflang: "bn" },
    { href: "https://momitnow.com/zh/shop", hreflang: "zh" },
    { href: "https://momitnow.com/ta/shop", hreflang: "ta" },
    { href: "https://momitnow.com/it/shop", hreflang: "it" },
    { href: "https://momitnow.com/th/shop", hreflang: "th" },
    { href: "https://momitnow.com/ml/shop", hreflang: "ml" },
    { href: "https://momitnow.com/tr/shop", hreflang: "tr" },
    // Provider page
    { href: "https://momitnow.com/en/provider", hreflang: "en" },
    { href: "https://momitnow.com/hi/provider", hreflang: "hi" },
    { href: "https://momitnow.com/ar/provider", hreflang: "ar" },
    { href: "https://momitnow.com/de/provider", hreflang: "de" },
    { href: "https://momitnow.com/es/provider", hreflang: "es" },
    { href: "https://momitnow.com/fr/provider", hreflang: "fr" },
    { href: "https://momitnow.com/ru/provider", hreflang: "ru" },
    { href: "https://momitnow.com/uz/provider", hreflang: "uz" },
    { href: "https://momitnow.com/id/provider", hreflang: "id" },
    { href: "https://momitnow.com/ur/provider", hreflang: "ur" },
    { href: "https://momitnow.com/pt/provider", hreflang: "pt" },
    { href: "https://momitnow.com/bn/provider", hreflang: "bn" },
    { href: "https://momitnow.com/zh/provider", hreflang: "zh" },
    { href: "https://momitnow.com/ta/provider", hreflang: "ta" },
    { href: "https://momitnow.com/it/provider", hreflang: "it" },
    { href: "https://momitnow.com/th/provider", hreflang: "th" },
    { href: "https://momitnow.com/ml/provider", hreflang: "ml" },
    { href: "https://momitnow.com/tr/provider", hreflang: "tr" },
    // Affiliate page
    { href: "https://momitnow.com/en/affiliate", hreflang: "en" },
    { href: "https://momitnow.com/hi/affiliate", hreflang: "hi" },
    { href: "https://momitnow.com/ar/affiliate", hreflang: "ar" },
    { href: "https://momitnow.com/de/affiliate", hreflang: "de" },
    { href: "https://momitnow.com/es/affiliate", hreflang: "es" },
    { href: "https://momitnow.com/fr/affiliate", hreflang: "fr" },
    { href: "https://momitnow.com/ru/affiliate", hreflang: "ru" },
    { href: "https://momitnow.com/uz/affiliate", hreflang: "uz" },
    { href: "https://momitnow.com/id/affiliate", hreflang: "id" },
    { href: "https://momitnow.com/ur/affiliate", hreflang: "ur" },
    { href: "https://momitnow.com/pt/affiliate", hreflang: "pt" },
    { href: "https://momitnow.com/bn/affiliate", hreflang: "bn" },
    { href: "https://momitnow.com/zh/affiliate", hreflang: "zh" },
    { href: "https://momitnow.com/ta/affiliate", hreflang: "ta" },
    { href: "https://momitnow.com/it/affiliate", hreflang: "it" },
    { href: "https://momitnow.com/th/affiliate", hreflang: "th" },
    { href: "https://momitnow.com/ml/affiliate", hreflang: "ml" },
    { href: "https://momitnow.com/tr/affiliate", hreflang: "tr" },
  ];

  languages.forEach(lang => {
    const link = document.createElement('link');
    link.rel = 'alternate';
    link.href = lang.href;
    link.hreflang = lang.hreflang;
    document.head.appendChild(link);
  });

  // Self-hreflang: Ensure the current page's hreflang is also included
  const currentLang = document.documentElement.lang; // Get the current language from the HTML lang attribute
  const currentUrl = window.location.href; // Get the current URL
  const selfLink = document.createElement('link');
  selfLink.rel = 'alternate';
  selfLink.href = currentUrl;
  selfLink.hreflang = currentLang;
  document.head.appendChild(selfLink);
}

// Call the function to generate hreflang tags
generateHreflangTags();

// Call this function during initialization or when appropriate
updateSchema();
updateMetaInfo();

// Export allPages if needed elsewhere
export default allPages;
